import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useEditClientMutation } from '../../../redux/apiSlice'
import { Button, TextField, MenuItem, DialogTitle, Dialog, DialogContent, IconButton, DialogActions, Grid, Divider, Checkbox } from '@mui/material';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useAuth } from '../../../utilities/useAuth';
import { PhoneFormat } from '../../../utilities/PhoneFormat';
import { useSelector } from 'react-redux';


const options = [
  { label: 'Lives Independently', value: 'Lives Independently' },
  { label: 'Lives with Supports', value: 'Lives with Supports' },
]

const EditPersInfo = ({ client }) => {
  const { id } = useAuth()
  const isUser = id;

  const clientId = client._id;

  const defaultRC = useSelector(state => state.settings.regionalCenter)

  const [editPersInfo] = useEditClientMutation()

  const [open, setOpen] = useState(false);
  const [phone, setPhone] = useState();
  const [scPhone, setSCPhone] = useState();
  const [hasMoved, setHasMoved] = useState(false);
  const [moveOut, setMoveOut] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setMoveOut('');
    setHasMoved(false);
    reset();
    setOpen(false);
  };


  const { register, control, handleSubmit, watch, reset, formState: { errors } } = useForm({
    defaultValues: {
      personalInfo: 'personalInfo',
      phone: client?.personalInfo?.phone,
      email: client?.personalInfo?.email,
      address: {
        movedIn: client?.personalInfo?.address?.movedIn,
        lineOne: client?.personalInfo?.address?.lineOne,
        lineTwo: client?.personalInfo?.address?.lineTwo,
        city: client?.personalInfo?.address?.city,
        state: client?.personalInfo?.address?.state,
        zip: client?.personalInfo?.address?.zip,
      },
      dateOfBirth: client?.personalInfo?.dateOfBirth,
      regionalCenter: defaultRC,
      serviceCoordinator: client?.personalInfo?.serviceCoordinator,
      serviceCoordinatorPhone: client?.personalInfo?.serviceCoordinatorPhone,
      serviceCoordinatorEmail: client?.personalInfo?.serviceCoordinatorEmail,
      rcId: client?.personalInfo?.rcId,
      emergencyContact: client?.personalInfo?.emergencyContact,
      livingSituation: client?.personalInfo?.livingSituation,
    }
  })

  const watchPhone = watch('phone')
  const watchSCPhone = watch('serviceCoordinatorPhone')

  useEffect(() => {
    if (watchPhone) {
      let format = PhoneFormat(watchPhone)
      setPhone(format)
    }
  }, [watchPhone])

  useEffect(() => {
    if (watchSCPhone) {
      let format = PhoneFormat(watchSCPhone)
      setSCPhone(format)
    }
  }, [watchSCPhone])

  const onSubmit = async (data) => {

    console.log(data);

    try {
      // const payload =  
      await editPersInfo({ moveOut, clientId, data, isUser }).unwrap();
      // console.log('fulfilled', payload)
    } catch (err) {
      console.error('Failed to update personal info: ', err)
      toast.error("Error Updating Personal Info", {
        position: toast.POSITION.TOP_CENTER
      });
    }

    toast.success("Personal Info Updated", {
      position: toast.POSITION.TOP_RIGHT
    });
    handleClose();
  }

  const onError = (errors) => {
    toast.error("Error Updating Personal Info", {
      position: toast.POSITION.TOP_CENTER
    });
    console.log('error in personal info form: ', errors);


  }

  const generateSingleOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  const handleMoveChange = (event) => {
    setHasMoved(event.target.checked)
  }

  const handleMoveOutChange = (date) => {
    setMoveOut(date)
  }

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <EditIcon color='secondary' />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
        <DialogTitle>Edit Personal Info</DialogTitle>
        <DialogContent>
          <br />


          <form id="editPersInfo"
            onSubmit={(e) =>
              handleSubmit(onSubmit, onError)(e).catch((e) => {
                console.log("e", e);
              })}>
            <Grid container columns={4} spacing={2}>
              <Grid item xs={12} sm={4}>
                <Grid container wrap='nowrap' spacing={2}>
                  <Grid item xs={4}>
                    <Controller
                      name='dateOfBirth'
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker

                            label="Date of Birth"
                            error={!!errors.dateOfBirth}
                            value={value}
                            onChange={(e) => onChange(e)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="phone"
                      name="phone"
                      label="Phone"
                      type='tel'
                      value={phone}
                      placeholder='(555) 555-5555'
                      {...register('phone', {
                        required: true
                      })}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="email"
                      name="email"
                      label="Email"
                      placeholder='person@octochart.com'
                      {...register('email')}
                      error={!!errors.email}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id='emergencyContact'
                      name='emergencyContact'
                      label='Emergency Contact'
                      {...register('emergencyContact')}
                    />
                  </Grid>
                </Grid>





              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id='lineOne'
                  name='lineOne'
                  label='Address Line One'
                  {...register('address.lineOne')}
                />
                <TextField
                  id='lineTwo'
                  name='lineTwo'
                  label='Address Line Two'
                  {...register('address.lineTwo')}
                />
                <TextField
                  id='city'
                  name='city'
                  label='City'
                  {...register('address.city')}
                />
                <TextField
                  id='state'
                  name='state'
                  label='State'
                  {...register('address.state')}
                />
                <TextField
                  id='zip'
                  name='zip'
                  label='Zip'
                  {...register('address.zip')}
                />

              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <label>New Address?</label>
                  <Checkbox
                    checked={hasMoved}
                    onChange={handleMoveChange}
                    label='New Address?'
                  />
                  {hasMoved &&
                    <>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Date moved out from old"
                          value={moveOut}
                          onChange={handleMoveOutChange}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>


                      <Controller
                        control={control}
                        name='address.movedIn'
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label="Date moved into new/ current"
                              value={field.value}
                              onChange={(e) => field.onChange(e)}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </>

                  }
                  <Grid item xs={12}>
                    <Controller
                      name="livingSituation"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          select
                          error={!!errors.livingSituation}
                          label="Living Situation"
                          sx={{ minWidth: 500 }}
                          onChange={field.onChange}
                          value={field.value}
                          variant='outlined'
                        >
                          {generateSingleOptions()}
                        </TextField>
                      )}

                      rules={{ required: true }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container wrap='nowrap' spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      id='regionalCenter'
                      name='regionalCenter'
                      label='Regional Center'
                      {...register('regionalCenter')}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id='serviceCoordinator'
                      name='serviceCoordinator'
                      label='Service Coordinator'
                      {...register('serviceCoordinator')}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id='serviceCoordinatorPhone'
                      name='serviceCoordinatorPhone'
                      label='SC Phone'
                      type='tel'
                      value={scPhone}
                      placeholder='(555) 555-5555'
                      {...register('serviceCoordinatorPhone')}

                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id='serviceCoordinatorEmail'
                      name='serviceCoordinatorEmail'
                      label='SC Email'
                      {...register('serviceCoordinatorEmail')}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <br />
                  <TextField
                    id='rcId'
                    name='rcId'
                    label='UCI #'
                    {...register('rcId')}
                  />
                </Grid>



              </Grid>
            </Grid>
            <DialogActions>
              <Button color='warning' onClick={handleClose}>Cancel</Button>
              <Button color="primary" variant="contained" form='editPersInfo' type="submit">
                Submit
              </Button>
            </DialogActions>
          </form>

        </DialogContent>
      </Dialog>
    </div>
  );
};

EditPersInfo.propTypes = {
  client: PropTypes.any
}

export default EditPersInfo;
