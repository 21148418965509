import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useGetTeamMembersQuery } from '../../redux/apiSlice';
import PropTypes from 'prop-types';


const SupUserLookup = ({userId, setUserId, superId}) => {

const { data: users } = useGetTeamMembersQuery(superId)

  const userListOptions = () => {
    if (users) {
        console.log(users)
        let userList = users.map(i => {
            if (i.status?.active) {
                return  {
                    userId: i._id,
                    fullName: i.fullName,
                    isActive: ""
    
                }
            } else  {
            return {
                userId: i._id,
                fullName: i.fullName,
                isActive: "(Inactive)"
            }
        }
        });

        userList.sort(a => {
            if (a.isActive === "(Inactive)") {
                return -1
            } else {
                return 1
            }
        });

        return userList.map(u => {
            return (
                <MenuItem key={u.userId} value={u.userId}>
                    {u.isActive} {u.fullName}
                </MenuItem>
            )
        });
    }
  }


  return (
    <div>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="user-select">Select User:</InputLabel>
            <Select
                labelId='user-select'
                label="Users"
                value={userId}
                onChange={(e) => {setUserId(e.target.value)}}
            >
                {userListOptions()}
            </Select>
        </FormControl>
    </div>
  )
}

SupUserLookup.propTypes = {
    userId: PropTypes.any,
    setUserId: PropTypes.any,
    superId: PropTypes.any
}

export default SupUserLookup