import React from 'react';
import { Button } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import PropTypes from 'prop-types';

const PrintSchedule = ({ calendarRef, events, userName }) => {
  const handlePrint = () => {
    const printContent = calendarRef.current; // Access the calendar DOM
    const printWindow = window.open('', '_blank');
    if (printWindow) {
      printWindow.document.write(`<html><head><title>${userName}'s Schedule</title>`);
      printWindow.document.write(`
        <style>
          body { font-family: Arial, sans-serif; }
          @media print {
            button { display: none; } /* Hide buttons during printing */
          }
          .event-list { margin-top: 20px; }
        </style>
      </head><body>`);
      printWindow.document.write(printContent.innerHTML); // Add calendar content
      
      // Optionally, include event details below the calendar
      if (events && events.length > 0) {
        printWindow.document.write('<div class="event-list"><h3>Event Details</h3><ul>');
        events.forEach(event => {
          printWindow.document.write(
            `<li>${event.client.fullName}: ${new Date(event.visitStart).toLocaleString()} - ${new Date(event.visitEnd).toLocaleString()}</li>`
          );
        });
        printWindow.document.write('</ul></div>');
      }

      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.print();
    }
  };

  return (
    <Button startIcon={<PrintIcon />} variant="contained" color="primary" onClick={handlePrint}>
      Print Calendar
    </Button>
  );
};

PrintSchedule.propTypes = {
    calendarRef: PropTypes.any,
    events: PropTypes.any,
    userName: PropTypes.any
}

export default PrintSchedule;
