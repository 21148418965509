import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Divider, Stack } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SelectAsyncUser from '../../utilities/SelectAsyncUser';
import { setTimecardSearch, setGetAllTimecards } from '../../redux/slices/adminSlice';
import { useDispatch } from 'react-redux';

const TimecardSearch = () => {
    const dispatch = useDispatch();

    const [rangeSelect, setRangeSelect] = useState('outlined');
    const [submitSelect, setSubmitSelect] = useState('outlined');
    const [dates, setDates] = useState([new Date(new Date().setDate(new Date().getDate() - 7)), new Date()]);
    const [clearSearch, setClearSearch] = useState(false);

    const { setValue, control, watch, reset } = useForm({
        defaultValues: {
            dateRange: dates,
            byRange: false,
            bySubmit: false,
            userId: []
        }
    });

    const handleRangeVariant = () => {
        if (rangeSelect === 'outlined') {
            setRangeSelect('contained');
            setSubmitSelect('outlined');
            setValue('byRange', true);
            setValue('bySubmit', false);
        } else {
            setRangeSelect('outlined');
            setValue('byRange', false);
        }
    };

    const handleSubmitVariant = () => {
        if (submitSelect === 'outlined') {
            setSubmitSelect('contained');
            setRangeSelect('outlined');
            setValue('bySubmit', true);
            setValue('byRange', false);
        } else {
            setSubmitSelect('outlined');
            setValue('bySubmit', false);
        }
    };

    const handleRunClick = async () => {
        const userId = watch('userId'); // Get the value of userId from the react-hook-form state
        const formData = {
            dateRange: dates,
            byRange: rangeSelect === 'contained',
            bySubmit: submitSelect === 'contained',
            userId: userId || [] // Ensure userId is included
        };

        console.log('timecard search data:', formData);

        dispatch(setTimecardSearch(formData));
        setClearSearch(true);
        reset();
        setClearSearch(false);
    };

    return (
        <Box sx={{  height: '200px', display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
            {/* <Typography>The options below </Typography> */}
            {/* Pull Unapproved Section */}
            <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => dispatch(setGetAllTimecards())}
                    sx={{ height: '100%' }}
                >
                    Pull Unapproved
                </Button>
            </Box>

            {/* Divider */}
            <Divider orientation="vertical" flexItem />

            {/* Search Section */}
            <Box sx={{ maxWidth: '900px', display: 'flex', flexDirection: 'column', gap: 2, flex: 1, border: '1px solid #ddd', borderRadius: 2, p: 2 }}>
                <Typography>To Search By Dates</Typography>
                <Stack direction={'row'}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <fieldset style={{ border: 'none' }}>
                        <legend style={{ fontWeight: 'bold' }}>Select Date Range</legend>
                        <DateRangePicker
                            value={dates}
                            onChange={(newDates) => {
                                setValue('dateRange', newDates);
                                setDates(newDates);
                            }}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <TextField {...startProps} />
                                    <Box sx={{ mx: 2 }}> to </Box>
                                    <TextField {...endProps} />
                                </React.Fragment>
                            )}
                        />
                    </fieldset>
                </LocalizationProvider>

                {/* Search Buttons */}
                <Box sx={{ display: 'flex', gap: 2 }}>
                <fieldset style={{ border: 'none' }}>
                <legend style={{ fontWeight: 'bold' }}>Select Date Type to Search By</legend>
                    <Button color="primary" variant={submitSelect} onClick={handleSubmitVariant}>
                        Submission Date
                    </Button>
                    <Button color="primary" variant={rangeSelect} onClick={handleRangeVariant}>
                        Date Range
                    </Button>
                    </fieldset>
                </Box>
                </Stack>

                {/* User Selection */}
                <Box sx={{ height: 'auto', minHeight: '50px', position: 'relative'}}>
                    <Typography>Optionally Select User(s)</Typography>
                    <Controller
                        control={control}
                        name="userId"
                        render={({ field: {onChange, value} }) => (
                            <SelectAsyncUser
                                // ref={field.ref}
                                placeholder="Enter User Name"
                                handleChange={(id) => onChange(id)}
                                clearSearch={clearSearch}
                                value={value}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: 'blue',
                                        primary: 'black'
                                    }
                                })}
                            />
                        )}
                    />
                </Box>

                {/* Run Button */}
                <Box>
                    <Button color="primary" variant="contained" onClick={handleRunClick}>
                        Run
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default TimecardSearch;
