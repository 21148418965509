import React, { useEffect, useState } from 'react';
import { useGetUsersQuery } from '../redux/apiSlice';
import { SupervisorTab } from './userviews/SupervisorTab';
import { Typography } from '@mui/material';



export default function Teams() {

	const [teams, setTeams] = useState()
	
	const { data, isSuccess } = useGetUsersQuery()

	useEffect(()=>{
		

		if(data) {
		const teamInfo = data.filter(e => e.role === "Supervisor")
		// console.log("teaminfo: ", teamInfo)
		setTeams(teamInfo)
		}
	}, [isSuccess])
	

	
	
	return(
	<div>
	<h1>Teams</h1>
	{/* {content} */}
	{teams && teams.map(t => <SupervisorTab supervisor={t} key={t._id} /> )}
	{!teams && <Typography variant='h5'>No users with Role of supervisor- Admin and Super Admin already have access to all users and clients.</Typography>}
	</div>
	)
}