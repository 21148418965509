import React, {useState, useEffect} from 'react';
import { useForm } from 'react-hook-form';
import { useYupValidationResolver } from '../../utilities/useYupValidationResolver';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useAddNewMilesMutation } from '../../redux/apiSlice'
import { DialogTitle, Dialog, DialogContent, DialogActions, Button, IconButton, TextField, Checkbox, Input, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InfoIcon from '@mui/icons-material/Info';


const validationSchema = yup.object({
  source: yup
    .string("Left From")
    .required('Required'),
  destination: yup
    .string("Went to")
    .required('Required'),
  distance: yup
    .string('How Far')
    .required('Required'),
  
});

const AddMiles = ({ bracket, stdDdct }) => {
  const bracketId = bracket._id

  const hasStdDdct = useSelector(state => state.settings?.useStdDdct)
  
  const [addNewMiles] = useAddNewMilesMutation()

  const [open, setOpen] = useState(false);
  const [ useStd, setUseStd ] = useState(false);
  // console.log("useStd: ", useStd)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setUseStd(false);
    reset();
    setOpen(false);
  };

  const {register, formState: { errors }, handleSubmit, watch, reset, setValue} = useForm({
    resolver: useYupValidationResolver(validationSchema),
    defaultValues: {
      source: '',
      destination: '',
      distance: '',
      useStdDdct: false,
      adjusted: ''
    }
  })

  const watchSource = watch('source')
  const watchDest = watch('destination')

  useEffect(() => {
    if (watchSource === 'home' || watchSource === 'Home' || watchSource === 'HOME' 
      || watchDest === 'home' || watchDest === 'Home' || watchDest === 'HOME'
    ){
      
      setUseStd(true)
      setValue('useStdDdct', true)
    }
  }, [watchSource, watchDest])
  

 const onSubmit = async (data) => {
    if (useStd) {
      let value = data.distance - stdDdct
      data.adjusted = Math.max(0, value)
    } else {
      data.adjusted = data.distance
    }

    // console.log(data);
    
    try {
    // const payload =  
    await addNewMiles({ bracketId, data })
    // .unwrap();
    //   console.log('fulfilled', payload)
    } catch (err) {
      console.error('Failed to save miles: ', err)
      toast.error("Error Adding Miles", {
        position: toast.POSITION.TOP_RIGHT
      });
    }

    toast.success("Miles Added", {
      position: toast.POSITION.TOP_RIGHT
    });
    handleClose();
    
  }

  const onError = (errors) => {
    toast.error("Please check the requirements for the form", {
     position: toast.POSITION.TOP_CENTER
    });
    console.log(`error: ${errors}`);
  };
   
  return (
  <div>
    <IconButton onClick={handleClickOpen}>
      <AddCircleIcon color="primary" />
      
    </IconButton>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Miles</DialogTitle>
      <DialogContent>
  
  
      <form
        id="addMiles"
        onSubmit= {(e) =>
          handleSubmit(
            onSubmit,
            onError
          )(e).catch((e) => {
            console.log("e", e);
          })
        }
        >
        <TextField
          fullWidth
          name="source"
          label="Leaving From"
          placeholder='Leaving From'
          {...register('source')}
          error={!!errors.source}
        /> <br />
        {errors.source && 'Starting Point'}
        <TextField
          fullWidth
          name="destination"
          label="Going To"
          placeholder='Going to'
          {...register('destination')}
          error={!!errors.destination}
        /> <br />
        {errors.destination && 'Ending Point'}
        <Input
          type='number'
          fullWidth
          name="distance"
          label="Distance"
          placeholder="0"
          {...register('distance')}
          error={!!errors.distance}
          inputProps={{
            step: 0.1, 
          }}
        /> <br />
        {errors.distance && 'Provide a distance'}
        {hasStdDdct &&
        <>
          <Checkbox
            value={useStd}
            checked={useStd}
            onClick={() => setUseStd(!useStd)}
            id="useStdDdct"
            name='useStdDdct'
            {...register('useStdDdct')}  
          />
          <label>To/From Home?</label>
          <Tooltip title="Checking this box applies the standard deduction">
            <InfoIcon />
          </Tooltip>
        </>
        }
        </form>
         <DialogActions>
          <Button onClick={handleClose} color='warning'>Cancel</Button>
          <Button color="primary" variant="contained" fullWidth form='addMiles' type="submit">
          Submit
        </Button>
       </DialogActions> 
      </DialogContent>
     
    </Dialog>
    </div>
  );
};

AddMiles.propTypes = {
    bracket: PropTypes.any,
    stdDdct: PropTypes.any
}


export default AddMiles;
