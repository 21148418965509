import React from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import HealthLogsTable from './HealthLogsTable';
import { useAddHealthEntryMutation, useDeleteHealthTypeMutation } from '../../../redux/apiSlice';
import AddHealthEntry from './AddHealthEntry';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import AddBldPrsrEntry from './AddBldPrsrEntry';
import DeleteAlert from '../../../DeleteAlert';
import { useAuth } from '../../../utilities/useAuth';


const HealthLogView = ({log, clientId, isUser}) => {
    const {admin, supAdmin} = useAuth();
    const logType = 'health'
    const logId = log._id
    const [softDelete] = useDeleteHealthTypeMutation();

    // const [logId, setLogId] = useState();
    // const [logType, setLogType] = useState('health');

    // useEffect(() => {
    //     if (log) {
    //         setLogId(log._id);
    //     }
    // }, [log])

    const [addEntry] = useAddHealthEntryMutation();
    console.log("log", log)

    const handleAddEntry = async (newLog) => {
        // const logType = 'health'
        // const logId = log._id
        // console.log("logId: ", logId)

        function convertDateToISO(data) {
            if (data && typeof data === 'object') {
              Object.keys(data).forEach(key => {
                if (key.toLowerCase() === 'date') {
                  // Convert the date field to an ISO string
                  data[key] = new Date(data[key]).toISOString();
                }
              });
            }
            return data;
          }
        
        const data = convertDateToISO(newLog)

        try {
            await addEntry({clientId, isUser, logType, logId, data })
        } catch (err) {
            console.log('Failed to add log', err)
            toast.error("Error adding log", {
                position: toast.POSITION.TOP_CENTER
            });
        }

        toast.success("Log Entry Added", {
            position: toast.POSITION.TOP_RIGHT
        });
        
    }

    return (
        <Box>
            <Paper elevation={2} style={{ padding: '16px' }}>
                
                <Stack direction={'row'} sx={{justifyContent: 'space-between'}}>
                    <Typography variant='h5' component={'h5'}>{log.type}</Typography>
                    {log.type === 'Blood Pressure' ?
                        <AddBldPrsrEntry addEntry={handleAddEntry} /> :
                    <AddHealthEntry log={log} addEntry={handleAddEntry} />
                    }
                    {admin || supAdmin && <DeleteAlert logType={logType} clientId={clientId} name={`Client Health Log- ${log.type}`} id={logId} hook={softDelete} softDelete={true} />}
                </Stack> <br />
                <Stack direction={'row'} sx={{justifyContent: 'space-between'}}>
                    <Typography>Target Goal: {log.goal}</Typography>
                    {log?.target && <Typography>Alert Range: &lt; {log.lowerAlert} - &gt; {log.upperAlert}</Typography>}
                </Stack>
                <br />
                <HealthLogsTable log={log} />
            </Paper>
        </Box>
    )
}

HealthLogView.propTypes = {
    log: PropTypes.any,
    clientId: PropTypes.any,
    isUser: PropTypes.any
}

export default HealthLogView;