import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, Typography, MenuItem, Divider, Select } from '@mui/material';
import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import { useDispatch, useSelector } from 'react-redux';
import { startClock, stopClock } from '../../../redux/slices/userSlice';
import { useAddNewTimeBracketMutation, useGetOpenBracketQuery, useStartUserClockMutation } from '../../../redux/apiSlice';
import { useAuth } from '../../../utilities/useAuth';
import { toast } from 'react-toastify';
import TimeIn from './TimeIn';
import TimeOut from './TimeOut';
import PropTypes from 'prop-types';

const options = [
  // { label: 'Entry Type', value: ' ' },
  { label: 'Client', value: 'Client' },
  { label: 'Office', value: 'Office' },
]

const Clock = ({ handleMenuClose }) => {

  const [open, setOpen] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [isType, setIsType] = useState('Client')
  // const [today, setToday] = useState()

  const clockStatus = useSelector((state) => state?.user?.clock?.status)

  useEffect(() => {
    if (clockStatus === 'clocked in') {
      setIsActive(true)
    }
  }, [clockStatus])


  const { id } = useAuth()
  const userId = id

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (userId) {
  //     setToday(new Date().toISOString())
  //   }
  // }, [userId])


  // const bracketData = {
  //   user: userId,
  //   date: today
  // }

  const [addBracket] = useAddNewTimeBracketMutation()
  const [saveStart] = useStartUserClockMutation()
  const {data: hasBracket} = useGetOpenBracketQuery(userId)


  const submitBracket = async () => {
    let bracketId

    const today = new Date().toISOString()

    const bracketData = {
      user: userId,
      date: today
    }

    if (hasBracket) {
      bracketId = hasBracket._id
    } else {
      const payload = await addBracket({ bracketData }).unwrap();
      // console.log('fulfilled', payload)  
      bracketId = await payload._id
    }

    const entryData = {
      type: isType,
      timeIn: today
    }

    // console.log("entry data: ", data)

    const payload2 = await saveStart({ bracketId, entryData }).unwrap();
    // console.log('fulfilled2: ', payload2)

    const entries = payload2.timeEntries
    const entry = entries[entries.length - 1]
    const entryId = entry._id
    console.log("entries: ", entries)
    console.log("entryId: ", entryId)

    const obj = {
      bracketId: bracketId,
      entryId: entryId,
      type: isType,
      timeIn: today
    }

    dispatch(startClock(obj));


  };

  const handleClockIn = () => {

    submitBracket();

    setTimeout(() => {
      setIsActive(true);
    }, [1000]);

    toast.success("Clocked In", {
      position: toast.POSITION.TOP_RIGHT
    });
    handleClose();
  };


  const handleClockOut = () => {
    dispatch(stopClock());
    setIsActive(false);

  };


  const generateSingleOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleMenuClose();
  };

  const handleClockChange = (event) => {
    setIsType(event.target.value)
  }

  let content

  if (clockStatus === 'no bracket' || clockStatus === 'empty bracket' || clockStatus === 'entries full') {
    content =
      // <>
      // {options ? (
      <>
        <Typography>You are clocked out</Typography> <br />
        <Divider />
        <Select
          //  select
          label="Type"
          sx={{ minWidth: 300 }}
          onChange={handleClockChange}
          value={isType ? isType : ' '}
          variant='outlined'
          renderValue={(isType) => isType ? isType : 'Client'}
        >
          {generateSingleOptions()}
        </Select>
        <Button color='success' variant='contained' onClick={handleClockIn}>Clock In</Button>
      </>
  }

  else if (clockStatus === 'clocked in') {
    content =
      <>
        <Typography>You are clocked in</Typography>
        <TimeIn isActive={isActive} />
        <br />
        <Button color='success' variant='contained' onClick={handleClockOut}>Clock out</Button>
      </>

  } else if (clockStatus === 'clocking out') {
    content =
      <>
        <TimeOut isActive={isActive} handleClose={handleClose} />
      </>
  }

  return (
    <div>
      {isActive ? (
        <Button color='success' variant='contained' onClick={handleClickOpen}><CheckBox />Clocked In</Button>
      ) : (
        <Button color='warning' variant='contained' onClick={handleClickOpen}><CheckBoxOutlineBlank />Clocked Out</Button>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Clock Status</DialogTitle>
        <DialogContent>
          {content}
          <br /> <br />
          <Divider /> <br />
          <Button color='warning' variant='contained' onClick={handleClose}>Close/ Cancel</Button>
        </DialogContent>
      </Dialog>
    </div>
  )
}

Clock.propTypes = {
  handleMenuClose: PropTypes.any
}

export default Clock;