import React, { useState } from 'react';
import { Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, Divider, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useAddSettingsMutation } from '../redux/apiSlice';
import { toast } from 'react-toastify';

const AddSettings = () => {
    const [open, setOpen] = useState(false)
    const [ddctToggle, setDdctToggle] = useState(false);
    const [fedRateToggle, setFedRateToggle] = useState(false);
    const [calendarYear, setCalendarYear] = useState('outlined');
    const [fiscalYear, setFiscalYear] = useState('outlined');

    const [addSettings] = useAddSettingsMutation();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { register, handleSubmit, setValue } = useForm({
        defaultValues: {
            name: '',
            useStdDdct: false,
            useFedRate: false,
            fedRate: '',
            regionalCelter: '',
            yearType: ''
        }
    })

    const onSubmit = async (data) => {
        // console.log('data: ', data)

        try {
            // const payload = 
            await addSettings(data).unwrap();
            // console.log('fulfilled', payload)
        } catch (err) {
            console.error('Failed to add settings: ', err)
        }
        toast.success("Settings Added", {
            position: toast.POSITION.TOP_RIGHT
        });
        handleClose();
    }

    const onError = () => {
        alert("Please check the requirements for the form");
        console.log('error on add')
    }

    const handleYearType = (type) => {
        setCalendarYear(type === 'calendarYear' ? 'contained' : 'outlined');
        setFiscalYear(type === 'fiscalYear' ? 'contained' : 'outlined');
        setValue('yearType', type);
      };

    // console.log("stdDdct: ", ddctToggle)

    return (
        <div>
            <Button color='primary' variant='contained' onClick={handleClickOpen}>Add Settings</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Agency Settings</DialogTitle>
                <DialogContent>
                    <form id="addSettings"
                        onSubmit={(e) =>
                            handleSubmit(onSubmit, onError)(e).catch((e) => {
                                console.log("e", e);
                            })}>
                        <Typography variant='subtitle1'><strong>Set Agency Name:</strong></Typography>
                        <br />
                        <TextField
                            {...register('name', {
                                required: true
                              })}
                            fullWidth
                            margin="normal"
                        />
                        <Divider />
                        {/* Regional Center Section */}
                        <Typography variant="subtitle1" sx={{ mt: 2 }}>
                            <strong>Regional Center:</strong>
                        </Typography>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Default Regional Center"
                            {...register('regionalCenter')}
                        />

                        <Divider />
                        {/* Mileage Section */}
                        <Typography variant="subtitle1" sx={{ mt: 2 }}>
                            <strong>Mileage:</strong>
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1 }}>
                            <Checkbox
                                checked={ddctToggle}
                                value={ddctToggle}
                                onClick={() => setDdctToggle(!ddctToggle)}
                                {...register('useStdDdct')}
                            />
                            <Typography>Use Standard Deduction on Mileage?</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1 }}>
                            <Checkbox
                                checked={fedRateToggle}
                                value={fedRateToggle}
                                onClick={() => setFedRateToggle(!fedRateToggle)}
                                {...register('useFedRate')}
                            />
                            <Typography>Use Federal Rate for Mileage?</Typography>
                        </Stack>
                        {fedRateToggle && (
                            <TextField
                                fullWidth
                                margin="normal"
                                type="number"
                                label="Federal Rate"
                                InputProps={{ inputProps: { step: 'any' } }}
                                {...register('fedRate')}
                            />
                        )}
                        <Divider />
                        {/* Contracts Section */}
                        <Typography variant="subtitle1" sx={{ mt: 2 }}>
                            <strong>Contracts:</strong>
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 1 }}>
                            Year Type for Contracts Tracking:
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                            Calendar Year = Jan 1 - Dec 31, Fiscal Year = Jul 1 - Jun 30
                        </Typography>
                        <Stack direction="row" spacing={2}>
                            <Button
                                color="secondary"
                                variant={calendarYear}
                                onClick={() => handleYearType('calendarYear')}
                            >
                                Calendar Year
                            </Button>
                            <Button
                                color="secondary"
                                variant={fiscalYear}
                                onClick={() => handleYearType('fiscalYear')}
                            >
                                Fiscal Year
                            </Button>
                        </Stack>

                    </form>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' variant='contained' type='submit' form='addSettings'>Submit</Button>
                    <Button color='warning' variant='contained' onClick={handleClose}>Cancel/ Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AddSettings