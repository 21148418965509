import React, {useState, useEffect} from 'react';
import {Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography, TextField} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useForm } from 'react-hook-form';
import { useEditNotesMutation } from '../../../redux/apiSlice';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useAuth } from '../../../utilities/useAuth';

const EditISPNotes = ({planId, notes}) => {
    const {id} = useAuth();
    const isUser = id;
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        reset();
        setOpen(false);
      };

      const [editNotes] = useEditNotesMutation()
      // console.log("notes: ", notes)
    
      const {
        register,
        handleSubmit,
        reset
    } = useForm({
      defaultValues: {
        payee: notes?.payee,
        vocation: notes?.vocation,
        family: notes?.family,
        medical: notes?.medical,
        longGoal: notes?.longGoal
      }
    });

    useEffect(() => {
      if (notes) {
        reset({
          payee: notes.payee || '',
          vocation: notes.vocation || '',
          family: notes.family || '',
          medical: notes.medical || '',
          longGoal: notes.longGoal || '',
        });
      }
    }, [notes, reset]);

    const onSubmit = async (data) => {
        // console.log("payload", data)

        try {
            // const payload =  
            await editNotes({ planId, data, isUser })
            // .unwrap();
            //   console.log('fulfilled', payload)
            } catch (err) {
              console.error('Failed to save note: ', err)
              toast.error("Error Adding Note", {
                position: toast.POSITION.TOP_CENTER
              });
            }
      
            toast.success("Note Added", {
              position: toast.POSITION.TOP_RIGHT
            });
            handleClose();
    };



  return (
    <div>
         <Button color='success'  variant='contained' onClick={handleClickOpen} startIcon={<EditIcon />}>
            Edit Notes
        </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit Plan Notes</DialogTitle>
            <DialogContent>
        <form
            id="editNotes"
            onSubmit={(e) =>
              handleSubmit(
                onSubmit,
              )(e).catch((e) => {
                console.log("e", e);
              })
            }
          >
            <Typography>Payee Information</Typography>
            <TextField name='payee' id='payee' {...register('payee')} />
            <Typography>Vocational/Education Information</Typography>
            <TextField {...register('vocation')} />
            <Typography>Family Input</Typography>
            <TextField {...register('family')} />
            <Typography>Current Medical Status</Typography>
            <TextField {...register('medical')} />
            <Typography>Long Term Goal</Typography>
            <TextField {...register('longGoal')} />
            </form>
            </DialogContent>
            <DialogActions>
            <Button color="primary" variant="contained" form="editNotes" fullWidth type="submit">
          Update Plan Notes
        </Button>
        </DialogActions>
        </Dialog>

        



          
    </div>
  )
}

EditISPNotes.propTypes = {
    planId: PropTypes.any,
    notes: PropTypes.any
}

export default EditISPNotes