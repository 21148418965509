import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Link } from 'react-router-dom';
import { useGetVisitsOfUserByDateQuery, useDeleteVisitMutation } from '../../redux/apiSlice';
import { CircularProgress, Box, Stack, useMediaQuery } from '@mui/material';
import ScheduleVisit from '../visitviews/ScheduleVisit';
import DeleteVisit from '../visitviews/DeleteVisit';
import DirectEditVisit from '../visitviews/DirectEditVisit';


const TodaysVisits = ({ userId, hasAuth }) => {
  const isUser = userId

  const [isStart, setIsStart] = useState()
  const [isEnd, setIsEnd] = useState()
  const [skip, setSkip] = useState()

  useEffect(() => {
    let today = new Date().toISOString();
    setIsStart(new Date(today).setHours(0, 0, 0))
    setIsEnd(new Date(today).setHours(23, 59, 59))
  }, [])

  useEffect(() => {
    if (isStart != undefined && isEnd != undefined && hasAuth) {
        setSkip(false)
    }
  }, [isStart, isEnd, hasAuth])

const {
  data,
  isLoading,
  isSuccess
} = useGetVisitsOfUserByDateQuery({userId, isStart, isEnd}, {skip});

const [deleteVisit] = useDeleteVisitMutation();

//const visitId = params.id

const isMobile = useMediaQuery('(max-width:600px)'); 


const allColumns = 

   [
  {
    field: 'client', 
    headerName: 'Client', 
    width: 100,
    renderCell: (params) => {
     return (
     <Link to={`/ClientChart/${params?.value?._id}`}>{params?.value?.fullName}</Link>
     )}
  },
  {
    field: 'visitStart',
    headerName: 'Start Time',
    type: 'date',
    width: 200,
    valueFormatter: (params) => {
      const date = new Date(params.value);
      let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      };
      return date.toLocaleTimeString("en-US", options);
    }
    
  },
  {
    field: 'visitEnd',
    headerName: 'End Time',
    type: 'date',
    width: 200,
    valueFormatter: (params) => {
      const date = new Date(params.value);
      let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      };
      return date.toLocaleTimeString("en-US", options);
    }
    
  },
  {field: 'location', headerName: 'Location', width: 150},
  {field: 'totalHours', headerName: 'Hours', width: 150},
  {
    field: 'actions',
    headerName: "",
    width: 80,
    renderCell: (params) => {
      let visitId = params.id
      // console.log("todays vis Id del: ", visitId)
      let date =new Date(params.row.visitStart).toLocaleTimeString()
      return (
        <>
          <DirectEditVisit visitId={visitId} visit={params.row} clientName={params.row.client.fullName} />
          <DeleteVisit name={`Visit- ${date}`} visitId={visitId} hook={deleteVisit} softDelete={true} isUser={isUser} />
        </>
      )
    }
  },
   ];

   const columns = isMobile
    ? allColumns.filter((col) => !['visitEnd', 'location', 'totalHours'].includes(col.field))
    : allColumns;

   let content

if (isLoading) {
  content = <CircularProgress />
} else if (isSuccess) {
  content = 
  <Box sx={{ width: '100%', overflowX: 'auto', m: 1, p: 1 }}>
  { data &&
    <DataGridPro 
      autoHeight
      autoWidth
      getRowId={(row) => row._id}
      rows={data}
      columns={columns}
      rowsPerPageOptions={[20, 50, 100]}
      slots={{
        NoRowsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No Visits for Today
          </Stack>
        )
      }}
    
 />}
 </Box>


}



  return (
    <div>
        <Stack direction='row' justifyContent={'space-between'}>
            <h1>Today&apos;s Visits</h1>
            <ScheduleVisit /> 
        </Stack> <br /> <br />
       {content}
    </div>
  )
}

TodaysVisits.propTypes = {
  userId: PropTypes.any,
  hasAuth: PropTypes.any
}


export default TodaysVisits;