import React from 'react';
import { Paper, Box, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import EditTimeEntry from './EditTimeEntry';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDeleteTimeEntryMutation } from '../../redux/apiSlice';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import './Timecard.css'



const TimeEntry = ({ entry, bracket }) => {

    // console.log("entry: ", entry)

    const entryStart = entry?.timeIn 
    ? format(new Date(entry.timeIn), 'hh:mm a') // Format as time in the current timezone
    : 'N/A';
  
  const entryEnd = entry?.timeOut 
    ? format(new Date(entry.timeOut), 'hh:mm a') // Format as time in the current timezone
    : 'N/A';

    // const bracketId = bracket._id
    const entryId = entry._id
    // console.log("bracket and entryId: ", bracketId, entryId)
    const [deleteEntry] = useDeleteTimeEntryMutation();
    const handleDelete = () => {
        deleteEntry(entryId)
        toast.success("Entry Deleted", {
            position: toast.POSITION.TOP_RIGHT
        })
    }

    return (
        <>
            <Paper>
                <Box>
                        <div >
                            <h3>Time In: {entryStart}</h3>
                            <h3>Time Out: {entryEnd}</h3>
                            <h3>Type: {entry?.type}</h3>
                            <h3>Hours: {entry?.hours}</h3>
                        </div>
                        <span className='icons'>
                            <EditTimeEntry entry={entry} bracket={bracket}/>
                            <IconButton onClick={() => handleDelete()}>
                                <DeleteIcon color='error' />
                            </IconButton>
                        </span>
                </Box>
            </Paper>
        </>
    )


}

TimeEntry.propTypes = {
    entry: PropTypes.any,
    bracket: PropTypes.any
}

export default TimeEntry;