import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TextField, Box, Button, Typography, CircularProgress } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useClientVisitsSearchMutation, useGetVisitsByClientIdQuery } from '../../redux/apiSlice';
import ClientVisits from './ClientVisits';
import SelectAsyncUser from '../../utilities/SelectAsyncUser';

const ClientVisitSearch = () => {
    const { clientId } = useParams();

    const [userId, setUserId] = useState()
	const [isStart, setIsStart] = useState();
	const [isEnd, setIsEnd] = useState();
    const [skip, setSkip] = useState(true);

    const [value, setValue] = useState([new Date().toJSON(), new Date().toJSON()]);

    const [pullSearch, {data: searchData, isSuccess: searchSuccess, isLoading: searchLoading}] = useClientVisitsSearchMutation({clientId, isStart, isEnd, userId})
    const {data: allData, isSuccess: allSuccess, isLoading: allLoading} = useGetVisitsByClientIdQuery(clientId, {skip})

    useEffect(() => {
		if (value[0]) {
			// console.log("value: ", value)
			let startDate = value[0];
			
			let start = new Date(new Date(startDate).setHours(0, 0, 0))
			
			// console.log("start: ", start)

			setIsStart(start)
		} else if (value[0] === null) {
			// console.log("value: ", value)
			setIsStart(null)
		}
	}, [value])

	useEffect(() => {
		if (value[1]) {
			// console.log("value: ", value)
			let endDate = value[1];

			let end = new Date(new Date(endDate).setHours(23, 59, 59))
			// console.log("end: ", end)

			setIsEnd(end)
		} else if (value[1] === null) {
			// console.log("value: ", value)
			setIsEnd(null)
		}
	}, [value])

    const handleUserChange = (_id) => {
		setUserId(_id)
	}

    let table = <div><Typography variant='h5'>Select Filter Options and Select Run</Typography><br />
	<Typography variant='h5'>To get all visits for this client, select Pull All- this may take some time.</Typography><br />
    <Typography variant='h5'>You can search with only a start date- to get all visits after, or an end date- to get all visits before.</Typography><br />
    <Typography variant='h5'>You can also search with or without a user.</Typography></div>

    if (searchLoading || allLoading) {
        table= <CircularProgress />
    }

    if (searchSuccess && searchData) {
        table= <ClientVisits data={searchData} />
    }

    if (allSuccess && allData) {
        table= <ClientVisits data={allData} />
    }
	

    return (
        <div>
            <fieldset width='300px'>
            
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            
                <legend>Filter By Date Range</legend>
            <DateRangePicker
                value={value}
                onChange={(newValue) => {
                    new Date().toISOString(newValue);
                    setValue(newValue);				
                }}
                renderInput={(startProps, endProps) => (
                    <React.Fragment>
                        <TextField {...startProps} />
                        <Box sx={{ mx: 2}}> to </Box>
                        <TextField {...endProps} />
                    </React.Fragment>
                )}
            />
            </LocalizationProvider>
                <legend>Filter By User</legend>
                <SelectAsyncUser 
                     
                    placeholder='Enter User Name' 
                    //   handleChange = {(_id  => field.onChange(_id))}
                    handleChange = {(_id => handleUserChange(_id))}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                         primary25: 'blue',
                         primary: 'black'
                    }
                     })}
                 />  
            <Button onClick={() => pullSearch({clientId, isStart, isEnd, userId})} color='primary' variant='contained'>
                Run 
            </Button>
            <Button onClick={() => setSkip(false)} color='secondary' variant='contained'>
                PullAll
            </Button>
            </fieldset>
            <br /><br /><br />
            {table}
        </div>
      )
}

export default ClientVisitSearch;