import React, { useState, useEffect } from 'react';
import { useGetUserQuery } from '../redux/apiSlice';
import { useAuth } from '../utilities/useAuth';
import TodaysVisits from './dashboardviews/TodaysVisits';
import SubmittedPlans from './dashboardviews/SubmittedPlans';
import { Divider, Card, Paper, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import MissingSigns from './visitviews/MissingSigns';


const Dashboard = () => {

	const { id, admin, supAdmin } = useAuth()
	const userId = id

	const [hasAuth, setHasAuth] = useState(false)
	const [skip, setSkip] = useState(true)

	useEffect(() => {
		if (id?.length) {
			setHasAuth(true)
			setSkip(false)
		}
	}, [id])

	const { data: user, isSuccess: userSuccess, isLoading: userLoading, isError: userError } = useGetUserQuery(userId, { skip })


	let userDetail
	let adminDetail

	if (userSuccess) {
		userDetail =
			<div display='flex' >
				<h1 style={{ fontSize: '2em' }}>{user.fullName}</h1> <br />
				<br />
				<TodaysVisits hasAuth={hasAuth} userId={userId} />
				<Divider sx={{ m: 1, p: 1 }} />
				<MissingSigns hasAuth={hasAuth} userId={userId} />
			</div>
	} else if (userLoading) {
		userDetail = <h2>Loading...</h2>
	} else if (userError) {
		userDetail = <h2>Error</h2>
	}

	if (admin || supAdmin) {
		adminDetail = <SubmittedPlans />
	}

	// const hasWidth = window.innerWidth
	// console.log("hasWidth: ", hasWidth)
	// const isMobile = useMediaQuery('(max-width:600px)');

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				minHeight: '100vh',
				backgroundColor: '#ECF1F7',
			}}
		>
			<Paper
				elevation={2}
				sx={{
					width: '100%',
					maxWidth: '1200px',
					margin: '0 auto',
					padding: { xs: 1, sm: 2 },
				}}
			>
				<Grid
					container
					direction="column"
					spacing={2}
					sx={{
						width: '100%',
						margin: 0,
					}}
				>
					{/* User Details Section */}
					<Grid item xs={12}>
						<Card sx={{ m: { xs: 1, sm: 2 }, p: { xs: 1, sm: 2 } }}>
							{userDetail}
						</Card>
					</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					{/* Admin Details Section */}
					<Grid item xs={12}>
						<Card sx={{ m: { xs: 1, sm: 2 }, p: { xs: 1, sm: 2 } }}>
							{adminDetail}
						</Card>
					</Grid>
				</Grid>
			</Paper>
		</Box>
	);

}


export default Dashboard;