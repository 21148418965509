import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    goal: {
        title: '',
        dataCollection: '',
        staffRole: '',
        frequency: '',
        location: '',
        projectedHours: '',
        materials: '',
        currentStatus: '',
        quote: '',
        measurement: '',
        plan: ''
    }
};

export const formSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    updateGoalData: (state, action) => {
        // const { formId, field, value } = action.payload;
        // if (!state[formId]) {
        //   state[formId] = {};
        // }
      
        // if (field === 'all') {
        //   state[formId] = { ...state[formId], ...value };
        // } else {
        //   state[formId][field] = value;
        // }
        Object.keys(action.payload).forEach(key => {
            if (action.payload[key] !== undefined) {
              state.goal[key] = action.payload[key];
            }
          });
      },
    resetGoalData: (state) => {
    //   const { formId } = action.payload;
    //   state[formId] = {};
        state.goal = {
            title: '',
            dataCollection: '',
            staffRole: '',
            frequency: '',
            location: '',
            projectedHours: '',
            materials: '',
            currentStatus: '',
            quote: '',
            measurement: '',
            plan: ''
        }
    },
    setGoalData: (state, action) => {
        // const { formId, initialValues } = action.payload;
        // state[formId] = { }; 
        state.goal = action.payload
    },
  },
});

export const { updateGoalData, resetGoalData, setGoalData } = formSlice.actions;
export default formSlice.reducer;
