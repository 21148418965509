import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import '../ViewPlan.css'


const ReviewPlanGoal = ({ client, goal, agency, planType, reportDate, startDate, endDate }) => {

  function addLineBreaks(input) {
    // Regular expression to find numbered list items (e.g., "1. ", "2. ", etc.)
    const regex = /(\d+\.\s)/g;

    // Replace matched patterns with a line break before each match
    const output = input.replace(regex, (match, p1, offset) => {
      // Add a line break before each match unless it's the very first match
      return offset === 0 ? match : '\n' + match;
    });

    return output;
  }

  const newPlan = goal.plan ? addLineBreaks(goal.plan) : '';
  const newPlanLines = newPlan.split('\n');

  return (
    <>
      <div className='page'>
        <section className="agencyTitle">
          <Typography>{agency}</Typography>
        </section>
        <section className="topHead">
          <Typography>{planType} - Individual Service Plan</Typography>
        </section>
        <span className="planDetails">
          <Typography>{client.fullName}</Typography>
          <Typography>Report Date: {reportDate} </Typography>
          <Typography>Period: {startDate} - {endDate} </Typography>
        </span>
        <section className="sectionHead">
          <Typography>Service Plan Goals</Typography>
        </section>
        <section className='subHead'>
          <Typography>{goal.title}</Typography>
        </section><br />
        <section className='inlineSection'>
          <Typography className='inlineHead'>Client Statement</Typography>
          <Typography>{goal.quote}</Typography>
        </section>
        <section className='subHead'>
          <Typography>Measurement</Typography>
        </section>
        <span>{goal.measurement}</span><br />
        <section className='inlineSection'>
          <Typography className='inlineHead'>Hours/ Frequency</Typography>
          <Typography>Projected hours: {goal.projectedHours}, Frequency: {goal.frequency} times/month</Typography>
        </section>
        <section className='subHead'>
          <Typography>Current Status</Typography>
        </section>
        <span>{goal.currentStatus}</span><br />
        <section className='inlineSection'>
          <Typography className='inlineHead'>Location</Typography>
          <Typography>{goal.location}</Typography>
        </section>
        
        <section className='inlineSection'>
          <Typography className='inlineHead'>Materials Used</Typography>
          <Typography>{goal.materials}</Typography>
        </section>

        <section className='inlineSection'>
          <Typography className='inlineHead'>Data Collection</Typography>
          <Typography>{goal.dataCollection}</Typography>
        </section>
        
        <section className='inlineSection'>
          <Typography className='inlineHead'>Staff Role</Typography>
          <Typography>{goal.staffRole}</Typography><br />
        </section>
       
        <section className='subHead'>
          <Typography>Plan</Typography>
        </section>
        <span>{newPlanLines.map((line, index) => (
                    <Typography key={index}>{line}</Typography>
                ))}</span><br />
      </div>
    </>
  )
}

ReviewPlanGoal.propTypes = {
  goal: PropTypes.any,
  client: PropTypes.any,
  agency: PropTypes.any,
  planType: PropTypes.any,
  reportDate: PropTypes.any,
  startDate: PropTypes.any,
  endDate: PropTypes.any
}

export default ReviewPlanGoal