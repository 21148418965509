/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Paper } from '@mui/material';
import { useGetUserQuery, useDeleteUserMutation } from "../../redux/apiSlice";
import EditUser from './EditUser';
import UserClients from './UserClients'
import AssignClients from "./AssignClients";
import ManageStatus from "./ManageStatus";
import { useSelector } from "react-redux";
import { useAuth } from "../../utilities/useAuth";
import DeleteUser from "./DeleteUser";

const UserProfile = () => {
    const {id, admin, supAdmin, supervisor} = useAuth()
    const isUser = id

    
    const hasStdDdct = useSelector(state => state.settings?.useStdDdct)

    const { userId } = useParams()
    console.log("profile id: ", userId)
    // const [skip, setSkip] = useState(true)
    
    const [hasView, setHasView] = useState(false)
    const [statusData, setStatusData] = useState()

    useEffect(() => {
        if (admin || supAdmin || supervisor) {
            setHasView(true)
        }
    }, [admin, supAdmin, supervisor])

    const {
        data: user,
        isLoading,
        isSuccess
    } = useGetUserQuery(userId)

    const [deleteUser] = useDeleteUserMutation();

    useEffect(() => {
        if(user) {
            let info = {
                fullName: user.fullName,
                status: {
                  active: user.status.active,
                  startDate: user.status.startDate,
                  suspension: user.status.suspension
                }
              }
              setStatusData(info)
        }
    }, [user])

    let content

    if (isLoading) {
        content = <CircularProgress />
    } else if (isSuccess) {
        content =
        <div style={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', height: '100%', width: '100%'}}>
                { user &&
                    <div>
                        <h3>Phone: {user.phone}</h3>
                        <h3>Email: {user.email}</h3>
                        <h3>Username: {user.username}</h3>
                        <h3>Role: {user.role}</h3>
                        <br />
                        {hasStdDdct && <h3>Standard Deduction: {user.stdDdct}</h3>}
                    </div>
                }
            </Box>
        </div>
    }

    console.log("user._id: ", user?._id)

    return (
        <div>
            <Paper>
                <Box sx={{ display:'flex', justifyContent: 'space-between'}}>
                    <span display="flex" style={{justifyContent:'start'}}>
                    <h1>{user?.fullName}'s Profile</h1>
                    </span>
                    <span display="flex" style={{justifyContent:'end'}}>
                        <ManageStatus info={statusData} userId={userId} />
                    </span>
                </Box>
            <br></br>
                <section>{content}</section>
                <br></br>
                {hasView ? (
                    <>
                    <label>Manage Assigned Clients</label><br />
                    <AssignClients userId={userId} /><br />
                    </>
                ): (<></>)}
                
                <UserClients userId={userId} />
                <br />
                <span>
                    <EditUser user={user} />
                    {admin || supAdmin ?
                        <DeleteUser name={user?.fullName} userId={user?._id} hook={deleteUser} softDelete={true} isUser={isUser} /> 
                        : ''
                }
                    
                </span>
            </Paper>
        </div>
    )
}

UserProfile.propTypes = {
    match: PropTypes.any
}

export default UserProfile;