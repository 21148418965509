import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    // time: 0,
    // baseTime: undefined,
    clock: {
        status: '',
        bracketId: '',
        entryId: '',
        info: {
            timeIn: null,
            timeOut: null,
            type: ''
        }
    },
    timecard: {
        submitted: false,
        submittedSig: {
            data: '',
            contentType: 'image/png'
        },
        submittedDate: '',
        submittedBy: ''
    },
    view: {
        viewOn: false,
        viewAs: null
    },
    schedule: {
        user: null
    }
}

export const userSlice = createSlice({
    initialState,
    name: 'user',
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload.user;            
        },
        startClock: (state, action) => {
            state.clock.info.timeIn = action.payload.timeIn //new Date().toISOString()
            state.clock.bracketId = action.payload.bracketId
            state.clock.entryId = action.payload.entryId
            state.clock.info.type = action.payload.type
            state.clock.status = "clocked in"
        },
        stopClock: (state) => {
            state.clock.info.timeOut = new Date().toISOString()
            state.clock.status = "clocking out"
        },
        resetClock: (state) => {
            state.clock.info.timeIn = null;
            state.clock.info.timeOut = null;
            state.clock.info.type = '';
            state.clock.bracketId = null;
            state.clock.status = 'entries full'
        },
        setClockStatus: (state, action) => {
            state.clock.status = action.payload
        },
        setClockInStatus: (state, action) => {
            state.clock.status = action.payload.response;
            if (action.payload.data) {
            state.clock.bracketId = action.payload.data.bracketId;
            state.clock.entryId = action.payload.data.entryId;
            state.clock.info.timeIn = action.payload.data.timeIn;
            state.clock.info.type = action.payload.data.type;
            }
        },
        setTimecardSubmit: (state, action) => {
            state.timecard.submittedBy = action.payload.userId,
            state.timecard.submitted = true,
            state.timecard.submittedDate = new Date().toISOString(),
            state.timecard.submittedSig.data = action.payload.sig
        },
        clearTimecardState: (state) => {
            state.timecard.submitted = false,
            state.timecard.submittedBy = '',
            state.timecard.submittedDate = '',
            state.timecard.submittedSig = {
                data: '',
                contentType: 'image/png'
            }
        },
        changeSchedule: (state, action) => {
            state.schedule.user = action.payload
        },
        setUserView: (state, action) => {
            state.view.viewOn = action.payload.viewOn
            state.view.viewAs = action.payload.viewAs
        },
        closeUserView: (state) => {
            state.view.viewOn = false,
            state.view.viewAs = null
        }
        
    }
});

export default userSlice.reducer;

export const { setUser, startClock, stopClock, resetClock, setClockInStatus, setClockStatus, setTimecardSubmit, clearTimecardState, changeSchedule, setUserView, closeUserView } = userSlice.actions;

export const selectClockInfo = (state) => state?.user?.clock

export const selectTimeIn = (state) => state.user?.clock?.timeIn

export const selectUser = (state) => state.user

export const selectUserId = (state) => state.user._id

export const selectUsersOnTeam = (state) => state.user.team
