import React from 'react';
import { Paper, Divider, Box, Typography, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import TimeEntry from './TimeEntry';
import VisitsBlock from './VisitsBlock';
import { format, parseISO } from 'date-fns';
import AddTimeEntry from './AddTimeEntry';
import AddMiles from './AddMiles';
import { 
         useDeleteTimeBracketMutation
        } from '../../redux/apiSlice';
import VisitHours from './VisitHours';
import './Timecard.css'
import MilesBlockStdDdct from './MilesBlockStdDdct';
import DeleteAlert from '../../DeleteAlert';



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '##F0F8FF',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));



const TimeBracketStdDdct = ({ bracket, user }) => {
    
    const bracketDate = format(parseISO(bracket.date), 'MM/dd/yyyy')

    const bracketId = bracket._id
    const stdDdct = user.stdDdct
    // console.log('stdDdct: ', stdDdct)
    // console.log(user)

    const [deleteBracket] = useDeleteTimeBracketMutation();
    

    const totalMiles = Math.round(
        (bracket?.miles ?? [])
            .map(i => i.adjusted)
            .reduce((a, b) => a + b, 0) * 100
    ) / 100;
    
    // console.log(totalMiles); 
    

    const totalHours =  Math.round(
        (bracket?.timeEntries ?? [])
        .map(i => i.hours)
        .reduce((a, b)=>a+b, 0) * 100
    ) / 100   

    
    //props to pass
    const entry = bracket?.timeEntries ?? [];
    const mile = bracket?.miles ?? [];
    

    return (
        <Box sx={{ flexGrow: 1 }}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              overflow: 'auto',
              bgcolor: '#ECF1F7',
              p: 1,
              m: 1,
              gap: 1,
            }}
          >
            {/* Bracket Info */}
            <Box sx={{ minWidth: 80, p: 1, m: 1, textAlign: 'center' }}>
              {bracket && (
                <>
                  <Typography variant="subtitle2" className='headings'>{bracketDate}</Typography>
                  <DeleteAlert
                    name={`Time Bracket on ${bracketDate}`}
                    id={bracketId}
                    hook={deleteBracket}
                    softDelete={false}
                  />
                </>
              )}
            </Box>
      
            <Divider orientation="vertical" flexItem />
      
            {/* Time Entries */}
            <Box sx={{ flex: '1 1 auto', minWidth: 300, width: 'fit-content', p: 1, m: 1 }}>
                <Item>
              <Typography variant="subtitle2" className="headings">
                Time Entries:
              </Typography>
              <AddTimeEntry bracket={bracket} />
              {entry &&
                entry.map((entry) => (
                  <TimeEntry key={entry._id} entry={entry} bracket={bracket} />
                ))}
              {bracket && (
                <Paper sx={{ p: 0.5, mt: 0.5 }}>
                  <Typography variant="subtitle2">Total Hours: {totalHours}</Typography>
                </Paper>
              )}
              </Item>
            </Box>
      
            <Divider orientation="vertical" flexItem />
      
            {/* Client Visits */}
            <Box sx={{ flex: '1 1 auto', minWidth: 300, width: 'fit-content', p: 1, m: 1 }}>
                <Item>
              <Typography variant="subtitle2" className="headings">
                Client Visits:
              </Typography>
              {bracket.visits &&
                bracket.visits.map((visit) => (
                  <VisitsBlock key={visit._id} visit={visit} />
                ))}
              {bracket.visits && <VisitHours visits={bracket.visits} />}
              </Item>
            </Box>
      
            <Divider orientation="vertical" flexItem />
      
            {/* Miles */}
            <Box sx={{ flex: '1 1 auto', minWidth: 300, width: 'fit-content', p: 1, m: 1 }}>
            <Item>
              <Typography variant="subtitle2" className="headings">
                Miles:
              </Typography>
              <AddMiles bracket={bracket} stdDdct={stdDdct} />
              {mile &&
                mile.map((mile) => (
                  <MilesBlockStdDdct
                    key={mile._id}
                    mile={mile}
                    bracket={bracket}
                    stdDdct={stdDdct}
                  />
                ))}
              {bracket && (
                <Paper sx={{ p: 0.5, mt: 0.5 }}>
                  <Typography variant="subtitle2">Total Miles: {totalMiles}</Typography>
                </Paper>
              )}
              </Item>
            </Box>
          </Card>
        </Box>
      );
}

TimeBracketStdDdct.propTypes = {
    bracket: PropTypes.any,
    user: PropTypes.any
}

export default TimeBracketStdDdct;


