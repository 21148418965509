import React from "react";
import { Typography } from "@mui/material";
import ReviewPersInfo from "./reviewplanviews/ReviewPersInfo";
import ReviewContacts from "./reviewplanviews/ReviewContacts";
import ReviewMedicalInfo from "./reviewplanviews/ReviewMedicalInfo";
import ReviewPlanGoal from "./reviewplanviews/ReviewPlanGoal";
import PropTypes from "prop-types";
import { useGetUserName } from "../../../utilities/useGetUserName";
import { useSelector } from "react-redux";

// import PlanTemplate from "./PlanTemplate";

import "./ViewPlan.css";

const ViewPlan = ({ client, plan }) => {

  const formatDate = (i) => {
    const date = new Date(i);
    let options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };


  const agencyName = useSelector(state => state.settings?.name)

  const goals = plan.goals

  let subSig = plan?.submittedSig
  // console.log("subSig: ", subSig)
  let appSig = plan?.approvedSig

  let sendSig = plan?.sentSig

  let submitter = useGetUserName(plan?.submittedBy)
  let subDate = formatDate(plan?.submittedDate)

  let approver = useGetUserName(plan?.approvedBy)
  let appDate = formatDate(plan?.approvedDate)

  let sender = useGetUserName(plan?.sentBy)
  let sendDate = formatDate(plan?.sentDate)

  const hasIHSS = client?.supports?.ihss?.hasIhss ? { bool: true, val: "Yes" } : { bool: false, val: "No" }
  const hasConserve = client?.supports?.conserve?.conserved ? { bool: true, val: "Yes" } : { bool: false, val: "No" }




  return (
    <>
      <div className="page">
        <section className="agencyTitle">
          <Typography>{agencyName}</Typography>
        </section>
        <section className="topHead">
          <Typography>{plan.type} Report- Individual Service Plan</Typography>
        </section>
        <span className="planDetails">
          <Typography>{client.fullName}</Typography>
          <Typography>Report Date: {formatDate(plan.reportDate)} </Typography>
          <Typography>Period: {formatDate(plan.startDate)} - {formatDate(plan.endDate)} </Typography>
        </span>
        <section className="sectionHead">
          <Typography>Personal Info</Typography>
        </section>
        <section>
          <ReviewPersInfo client={client} />
          <Typography>Consents Forms Reviewed: {formatDate(plan?.details?.formsSigned)}</Typography>
        </section> <br />
        <section>
          <label className="category">Long Term Goal:</label><br />
          {plan.notes.longGoal}
        </section> <br />
        <section>
          <label className="category">Vocation/ Education/ Volunteer Information:</label><br />
          {plan.notes.vocation}
        </section> <br />
        <section>
          <label className="category">Client Supports</label><br />
          <Typography>Receive IHSS?  {hasIHSS.val}</Typography>
          {hasIHSS.bool &&
            <>
              <Typography>Provider: {client?.supports?.ihss?.provider} Hours: {client?.supports?.ihss?.hours}</Typography><br />
              <Typography> Services: {client?.supports?.ihss?.services}</Typography> <br />
              <Typography>County Worker: {client?.supports?.ihss?.countyRep}</Typography> <br />
              <Typography>Phone: {client?.supports?.ihss?.countyRepPhone}  Email: {client?.supports?.ihss?.countyRepEmail}</Typography>
            </>
          }
          <Typography>Conserved?  {hasConserve.val}</Typography>
          {hasConserve.bool &&
            <>
              <Typography>Conservator: {client?.supports?.conserve?.conservator}</Typography>
              <br />
              <Typography>Conserved Areas: {client?.supports?.conserve?.details}</Typography>
            </>
          }
        </section> <br />
        <section>
          <label className="category">Family Input:</label><br />
          {plan.notes.family}
        </section> <br />
        <section className="subHead">
          <Typography>Income</Typography>
        </section>
        <section>
          {client.income.length ?
            <>
              <table>
                <thead>
                  <tr>
                    <td>Source</td>
                    <td>  </td>
                    <td>Amount</td>
                    <td>  </td>
                    <td>Recurs?</td>
                  </tr>
                </thead>
                <tbody>
                  {client?.income.map((inc, idx) => (
                    <tr key={idx}>
                      <td>{inc.source}</td>
                      <td>  </td>
                      <td>{inc.amount}</td>
                      <td>  </td>
                      <td>{inc.recurRate}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
            : <label>No Income</label>
          }
        </section>
        <section>
          <label className="category">Payee Information:</label><br />
          {plan.notes.payee}
        </section>
        <footer />
      </div>
      {/* <div className="page-break" /> */}
      <div className="page">
        <section className="agencyTitle">
          <Typography>{agencyName}</Typography>
        </section>
        <section className="topHead">
          <Typography>{plan.type} Report- Individual Service Plan</Typography>
        </section>
        <span className="planDetails">
          <Typography>{client.fullName}</Typography>
          <Typography>Report Date: {formatDate(plan.reportDate)} </Typography>
          <Typography>Period: {formatDate(plan.startDate)} - {formatDate(plan.endDate)} </Typography>
        </span>
        <section className="sectionHead">
          <Typography>Medical Info</Typography>
        </section>
        <section className="subHead">
          <Typography>Insurance</Typography>
        </section>
        <section>
          <ReviewMedicalInfo client={client} />
        </section>
        <section>
          <label className="category">Current Medical Status:</label><br />
          {plan.notes.medical}
        </section>
        <section className="subHead">
          <Typography>Allergies</Typography>
        </section>
        <section>
          {client.allergies.length ?
            <>
              <table>
                <thead>
                  <tr>
                    <td>Source</td>
                    <td>  </td>
                    <td>Severity</td>
                    <td>  </td>
                    <td>Reaction</td>
                  </tr>
                </thead>
                <tbody>
                  {client?.allergies.map((alg, idx) => (
                    <tr key={idx}>
                      <td>{alg.source}</td>
                      <td>  </td>
                      <td>{alg.severity}</td>
                      <td>  </td>
                      <td>{alg.reaction}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
            : <label>No Known Allergies</label>
          }
        </section>
        <section className="subHead">
          <Typography>Medical Providers</Typography>
        </section>
        <section>
          {client &&
            client?.contacts.map((contact, idx) => {
              if (contact.typeOfContact === "doctor") {
                return <><ReviewContacts key={idx} contact={contact} /><br /></>;
              }
            })}
        </section>
        <footer />
      </div>
      <br /> <div className="page-break" />
      <div className="page">
        <section className="agencyTitle">
          <Typography>{agencyName}</Typography>
        </section>
        <section className="topHead">
          <Typography>{plan.type} Report- Individual Service Plan</Typography>
        </section>
        <span className="planDetails">
          <Typography>{client.fullName}</Typography>
          <Typography>Report Date: {formatDate(plan.reportDate)} </Typography>
          <Typography>Period: {formatDate(plan.startDate)} - {formatDate(plan.endDate)} </Typography>
        </span>
        <section className="subHead">
          <Typography>Medication</Typography>
        </section>
        <br />
        <section className="subHead">
          <Typography>Pharmacy</Typography>
        </section>
        <section>
          {client?.contacts.map((cont, idx) => {
            if (cont.typeOfContact === 'pharmacy') {
              return (
                <React.Fragment key={idx}>
                  <label>{cont.locationName}  {cont.phone}</label><br />
                  <label>{cont.address.addressLineOne}</label><br />
                  {cont.address.addressLineTwo ? <><label>{cont.address.addressLineTwo}</label><br /></> : <></>}
                  <label>{cont.address.city}, {cont.address.state} {cont.address.zip}</label>
                </React.Fragment>
              )
            }
          })}
        </section>
        <br />
        <section className="subHead">
          <Typography>Current Medication:</Typography>
        </section>
        <section>
          {client && client.medication.length ?
            <>
              <table>
                <thead>
                  <tr>
                    <td>Medication</td>
                    <td>Dosage</td>
                    <td>Frequency</td>
                    <td>Start Date</td>
                    <td>Taken For</td>
                  </tr>
                </thead>
                <tbody>
                  {client?.medication.map((med, idx) => {
                    if (med.active === true) {
                      return (
                        <tr key={idx}>
                          <td>{med.name}</td>
                          <td>{med.dosage}</td>
                          <td>{med.frequency}</td>
                          <td>{new Date(med.startDate).toLocaleDateString()}</td>
                          <td>{med.reason}</td>
                        </tr>
                      )
                    }
                  })}
                </tbody>
              </table>
            </>
            : <></>}
        </section>
        <br />
        {/* <section className="subHead">
        <Typography>Inactive Medication:</Typography>
      </section>
      <section>
      {client && client.medication.length ?
          <>
          <table>
                <thead>
                    <tr>
                        <td>Medication</td>
                        <td>Dosage</td>
                        <td>Frequency</td>
                        <td>Start Date</td>
                        <td>Taken For</td>
                    </tr>
                </thead>
                <tbody>
         { client?.medication.map((med, idx) => {
            if (med.active === false) {
              return(
              <tr key={idx}>
                <td>{med.name}</td>
                <td>{med.dosage}</td>
                <td>{med.frequency}</td>
                <td>{new Date(med.startDate).toLocaleDateString()}</td>
                <td>{med.reason}</td>
              </tr>
              )
            }
          })}
          </tbody>
          </table>
          </>
          : <></>}
      </section> */}
        <footer />
      </div>
      {goals && goals.map((goal, idx) => (
        <>
          <ReviewPlanGoal agency={agencyName} client={client} goal={goal} key={idx} planType={plan.type} reportDate={formatDate.reportDate} startDate={formatDate(plan.startDate)} endDate={formatDate(plan.endDate)} />
          <footer />
        </>
      ))}
      <br />
      <div className="page">
        <section className="agencyTitle">
          <Typography>{agencyName}</Typography>
        </section>
        <section className="topHead">
          <Typography>{plan.type} Report- Individual Service Plan</Typography>
        </section>
        <span className="planDetails">
          <Typography>{client.fullName}</Typography>
          <Typography>Report Date: {formatDate(plan.reportDate)} </Typography>
          <Typography>Period: {formatDate(plan.startDate)} - {formatDate(plan.endDate)} </Typography>
        </span>
        <section className="subHead">
          <Typography>Submission/ Approval</Typography>
        </section>
        <br />
        <Typography className="category">Submitted: </Typography>
        {plan?.submitted &&
          <>
            <label>By: {submitter}</label>
            <label>On: {subDate}</label>
            {plan?.submittedSig?.length &&
              <img src={subSig} style={{ wdith: '300px', height: '300px' }} />
            }
          </>
        }

        <br />
        <Typography className="category">Approved: </Typography>
        {plan?.approved &&
          <>
            <label>By: {approver}</label>
            <label>On: {appDate}</label>
            {plan?.approvedSig?.length &&
              <img src={appSig} style={{ wdith: '300px', height: '300px' }} />
            }
          </>
        }
        <br />
        <Typography>Sent: </Typography>
        {plan?.sent &&
          <>
            <label>By: {sender}</label>
            <label>On: {sendDate}</label>
            <label>To: {plan.sentTo}</label>
            <label>At: {plan.sentAddr}</label>
            {plan?.sentSig?.length &&
              <img src={sendSig} style={{ wdith: '300px', height: '300px' }} />
            }
          </>
        }

      </div>
    </>
  );
};

ViewPlan.propTypes = {
  client: PropTypes.any,
  plan: PropTypes.any
};

export default ViewPlan;