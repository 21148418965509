import React, { useState, useEffect } from 'react';
import {
  Button, TextField, MenuItem, Dialog, DialogTitle,
  DialogActions, DialogContent, DialogContentText,
  Typography,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useAddClientReportMutation, useGetMostRecentReportQuery } from '../../../redux/apiSlice';
import { toast } from 'react-toastify';
import { useAuth } from '../../../utilities/useAuth';



const options = [
  { label: 'Report Type', value: '' },
  { label: 'Annual', value: 'Annual' },
  { label: 'Semi Annual', value: 'Semi Annual' },
]

const AddServicePlan = () => {
  const { id } = useAuth();
  const isUser = id;

  const [addNewPlan] = useAddClientReportMutation();

  const [open, setOpen] = useState(false);
  const [copyGoals, setCopyGoals] = useState(false);
  const [fetchGoals, setFetchGoals] = useState(false);
  const [goals, setGoals] = useState([]);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const { clientId } = useParams();

  // console.log("goals: ", goals)

  const { data: recentReport } = useGetMostRecentReportQuery({clientId}, {
    skip: !fetchGoals,
  });

  const { control, handleSubmit, setValue, formState: { errors } } = useForm({
    defaultValues: {
      reportDate: '',
      startDate: '',
      endDate: '',
      type: '',
      goals: []
    }
  });

  // console.log("values: ", getValues())

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setCopyGoals(isChecked);
    setFetchGoals(isChecked);
  };


  useEffect(() => {
    if (recentReport && recentReport.goals) {
      const filteredGoals = recentReport.goals.map(goal => ({
        title: goal.title,
        dataCollection: goal.dataCollection,
        staffRole: goal.staffRole,
        frequency: goal.frequency,
        location: goal.location,
        projectedHours: goal.projectedHours,
        materials: goal.materials,
        currentStatus: " ",
        quote: " ",
        measurement: " ",
        plan: goal.plan
      }));

      setGoals(filteredGoals);

      // Populate form fields with filtered goals
      filteredGoals.forEach((goal, index) => {
        setValue(`goals[${index}].title`, goal.title);
        setValue(`goals[${index}].dataCollection`, goal.dataCollection);
        setValue(`goals[${index}].staffRole`, goal.staffRole);
        setValue(`goals[${index}].frequency`, goal.frequency);
        setValue(`goals[${index}].location`, goal.location);
        setValue(`goals[${index}].projectedHours`, goal.projectedHours);
        setValue(`goals[${index}].materials`, goal.materials);
        setValue(`goals[${index}].currentStatus`, goal.currentStatus);
        setValue(`goals[${index}].quote`, goal.quote);
        setValue(`goals[${index}].measurement`, goal.measurement);
        setValue(`goals[${index}].plan`, goal.plan);
      });
    }
  }, [recentReport, setValue]);


  const generateSingleOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  




  const onSubmit = async (data) => {

    console.log('data', data)

    try {
      const payload = await addNewPlan({ clientId, data, isUser }).unwrap();
      console.log('fulfilled', payload)
    } catch (err) {
      console.error('Failed to save service plan: ', err)
    }

    toast.success("Client Report Added", {
      position: toast.POSITION.TOP_RIGHT
    });
    handleClose();
  }

  const onError = () => {
    toast.error("Please check the requirements for the form", {
      position: toast.POSITION.TOP_CENTER
    });
    console.log('error');
  }


  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Add New Report Shell
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Service Plan</DialogTitle>
        <DialogContent>

          <DialogContentText>
            Provide the following information to start the Service Plan.
          </DialogContentText>


          <form id="addServicePlan"
            onSubmit={(e) =>
              handleSubmit(onSubmit, onError)(e).catch((e) => {
                console.log("e", e);
              })}>
            <section>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <TextField
                    select
                    error={!!errors.type}
                    label="Report Type"
                    sx={{ minWidth: 500 }}
                    onChange={field.onChange}
                    value={field.value}
                    variant='outlined'
                  >
                    {generateSingleOptions()}
                  </TextField>
                )}

                rules={{ required: true }}
              />
              <br></br>
              {errors.type && "Select Contract Type"}
            </section>
            <section>

              <Controller
                control={control}
                name="reportDate"


                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker

                      label="Client Report Date"
                      error={!!errors.dateOfReport}
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                )}
              />
              <br></br>
              {errors.dateOfReport && "Select Report Date"}
            </section>
            <br></br>

            <section>

              <Controller
                control={control}
                name="startDate"


                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker

                      label="Report Start Date"
                      error={!!errors.startDate}
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                )}
              />
              <br></br>
              {errors.startDate && "Select Start Date"}
            </section>
            <br></br>
            <section>

              <Controller
                control={control}
                name="endDate"
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker

                      label="Report End Date"
                      error={!!errors.endDate}
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                )}
              />
              <br></br>
              {errors.endDate && "Select End Date"}
            </section>
            <br></br>
            <FormControlLabel
              control={<Checkbox checked={copyGoals} onChange={handleCheckboxChange} />}
              label="Copy Goals From Last Plan?"
            />

            {goals.length > 0 && (
              <div>
                <Typography variant="h6">Goals</Typography>
                {goals.map((goal, index) => (
                  <Typography key={index} variant="body1">
                    {goal.title}
                  </Typography>
                ))}
              </div>
            )}


          </form>
          <br></br>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='warning'>Cancel</Button>
          <Button color="primary" variant="contained" form="addServicePlan" type="submit">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}


export default AddServicePlan;
