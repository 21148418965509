import { createSlice } from '@reduxjs/toolkit'

export const clientSlice = createSlice({
    name: 'client',
    initialState: {
        currentPlan: '',
        fullName: '',
        _id: '',
        planList: [],
        consentsSigned: ''
    },
    reducers: {
        setCurrentClient(state, action) {
            state.currentPlan = action.payload.currentPlan;
            state.fullName = action.payload.fullName;
            state._id = action.payload._id;
        },
        setCurrentPlan(state, action) {
            state.currentPlan = action.payload
        },
        setNewPlanId(state, action) {
            state.newPlanId = action.payload
        },
        setLastPlanId(state, action) {
            state.lastPlanId = action.payload
        },
        setStepperGoals(state, action) {
            state.stepGoals = [...action.payload]
        },
        setPlansForServicePlanTab(state, action) {
            state.planList = action.payload
        },
        setConsentsSigned(state, action) {
            state.consentsSigned = action.payload
        }
    }

})

export const { setCurrentClient, setCurrentPlan, setNewPlanId, setLastPlanId, setConsentsSigned, setStepperGoals, setPlansForServicePlanTab } = clientSlice.actions

export default clientSlice.reducer

