import React, {useState} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Toolbar, List, Divider, Button, Stack, Box, ListItem, ListItemIcon, ListItemButton, ListItemText, Typography, IconButton } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import MailIcon from '@mui/icons-material/Mail';
import EventIcon from '@mui/icons-material/Event';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Link, useLocation } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import { useSendLogoutMutation } from './redux/apiSlice';
import { useAuth } from './utilities/useAuth';
// import { selectUserId } from './redux/slices/userSlice';
// import Clock from './views/userviews/clockviews/Clock';
import { useSelector } from 'react-redux';
import MainMenu from './MainMenu';


const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== open })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


function isAuthRoute(pathname) {
  return pathname === "/login" || pathname === "/register" || pathname === "/forgotpassword" || pathname == "/resetpassword/:resetToken";
}

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = useState(false)
  const agencyName = useSelector(state => state.settings?.name)
  // const navigate = useNavigate()

  const { admin, supAdmin, status, id } = useAuth();
  // console.log("admin & supAdmin: ", admin, supAdmin, "status: ", status)
  // console.log("drawer: ", status, id)
  const adminList = [
    {
      text: "Teams",
      icon: <PeopleIcon />,
      to: "/Teams" 
    },
    {
      text: "Reports",
      icon: <AssessmentIcon />,
      to: "/Reports"
    },
    {
      text: "Settings",
      icon: <SettingsIcon />,
      to: "/Settings" 
    },
    {
      text: "Audit Log",
      icon: <AssignmentTurnedInIcon />,
      to: "/Auditlog"
    },
  ]

  let adminMenu = null
  if (admin || supAdmin){
    adminMenu = (
        <>
        {adminList.map((item) => {
          const {text, icon} = item;
          return (
            <ListItem key={text} sx={{ display: 'block' }}>
              <ListItemButton
                component={Link}
                to={item.to}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                {icon &&
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {icon}
                </ListItemIcon>
              }<ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          )})
        }
        </>
    )
  }



  const staffId = id

  let usersMenu = null
  if (status === 'Staff'){
    usersMenu = (
      <>
        <ListItem sx={{ display: 'block' }}>
              <ListItemButton
                component={Link}
                to={{
                  pathname:`/UserProfile/${staffId}`,
                  state: { userId: staffId }
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                ><PersonIcon /></ListItemIcon>
                <ListItemText primary={"User Profile"} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
        </ListItem>
      </>
    )
  } else if (status === 'Supervisor'){
    usersMenu = (
      <>
      <ListItem sx={{ display: 'block' }}>
              <ListItemButton
                component={Link}
                to={"/SupervisorUsers"}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                ><PersonIcon /></ListItemIcon>
                <ListItemText primary={"Users"} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
        </ListItem>
      </>
    )
  } else if (admin || supAdmin) { //(status === 'Admin' || status === 'Super Admin') {
    usersMenu = (
      <>
        <ListItem sx={{ display: 'block' }}>
              <ListItemButton
                component={Link}
                to={"/UsersTab"}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                ><PersonIcon /></ListItemIcon>
                <ListItemText primary={"Users"} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
        </ListItem>
      </>
    )
  }

  // const [logOut, {isSuccess, isError, error}] = useSendLogoutMutation()
		

	// const logoutHandler = async () => {
	// 	await logOut()
  //   navigate("/login");
	// 	if (isSuccess){
  //     navigate("/login");
	// 	} else if (isError) {
	// 		toast.error(error.data?.message)
	// 	}
	// }

  const location = useLocation();

  if (isAuthRoute(location.pathname)) {
    return null;
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const defaultList = [
    {
      text: "Dashboard",
      icon: <DashboardIcon />,
      to: "/Dashboard" 
    },
    {
      text: "Clients",
      icon: <AccountCircleIcon />,
      to: "/Clients"
    },
    {
      text: "Schedule",
      icon: <EventIcon />,
      to: "/Schedule" 
    },
    {
      text: "Timecard",
      icon: <AccessTimeIcon />,
      to: "/Timecard"
    },
    // {
    //   text: "Messages",
    //   icon: <MailIcon />,
    //   to: "/Messages"
    // }
  ];

  const AlwaysWhiteButton = styled(Button)(({ theme }) => ({
    color: 'white',
    '&:visited': {
      color: 'white',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'black',
    },
    '&:active': {
      color: 'white',
    },
  }));

  

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        open={open}
      >
        <Toolbar sx={{justifyContent: 'space-between'}}>
        <Stack 
              direction='row' 
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2} 
              justifyContent={'space-between'}
              alignItems={'center'}
            >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <AlwaysWhiteButton variant='text' component={Link} to='/dashboard' color='white' sx={{
              fontFamily: 'Georgia', fontSize: '22px'}}>OctoChart</AlwaysWhiteButton>
          </Stack>
          <Typography variant='h6' noWrap>{agencyName}</Typography>
            {/* <Stack 
              direction='row' 
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2} 
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Clock  />
              <Button color='secondary' variant='contained' onClick={logoutHandler}>Logout</Button>
            </Stack> */}
            <MainMenu />
        </Toolbar>
        
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        
        <Divider />
        
        <React.Fragment>
        <List>
        {defaultList.map((item) => {
          const {text, icon } = item;
          return(
            <ListItem key={text} sx={{ display: 'block' }}>
              <ListItemButton
                component={Link}
                to={item.to}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                {icon &&
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {icon}
                </ListItemIcon>
              }<ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          )
        })}
        </List>
        </React.Fragment>
        
        <Divider />
        <List>
          {usersMenu}
          {adminMenu}
          
        </List>        
      </Drawer>
      </Box>
  );
}
