import React, { useEffect, useRef, useState } from 'react';
import {
  Button, TextField, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Divider, Grid,
  Typography, Select, MenuItem, InputLabel, TableCell, Table, TableRow, TableBody, useMediaQuery, Slide
} from '@mui/material';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useEditVisitMutation, useGetLogsForVisitQuery } from '../../redux/apiSlice';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import GoalInput from './GoalInput';
import { useDispatch, useSelector } from 'react-redux';
import { addGoal, setOpenState, updateGoal, updateVisitState } from '../../redux/slices/visitSlice';
import { useAuth } from '../../utilities/useAuth';
import SignVisit from './SignVisit';
import AddBldPrsrFromVisit from '../clientviews/logviews/AddBldPrsrFromVisit';
import AddEntryFromVisit from '../clientviews/logviews/AddEntryFromVisit';
import { useDebounceFunc } from '../../utilities/useDebounceFunc';
import useFocusTrap from '../../utilities/useFocusTrap';
import AddIcon from '@mui/icons-material/Add'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const statusOptions = [
  { label: 'Scheduled', value: 'Scheduled' },
  { label: 'On-Site', value: 'On-Site' },
  { label: 'Completed', value: 'Completed' },
  { label: 'Canceled', value: 'Canceled' },
]

const EditVisit = ({ visitId, clientName, clientId }) => {
  const { id } = useAuth();
  const isUser = id;

  const formId = "editVisit"

  const hasVisit = useSelector(state => state.visit?.visit)
  // console.log("hasVisit: ", hasVisit)

  const [hasLogs, setHasLogs] = useState([]);
  const [doneLogs, setDoneLogs] = useState([]);
  const [logDate, setLogDate] = useState(hasVisit.visitStart);
  const [skip, setSkip] = useState(true);

  useEffect(() => {
    if (clientId) {
      setSkip(false)
    }
  }, [clientId])

  const [editVisit] = useEditVisitMutation();
  const { data, isSuccess } = useGetLogsForVisitQuery(clientId, { skip })
  // console.log("clientId: ", clientId)
  // console.log("isSuccess & data", isSuccess, data, data.length)
  // console.log("hasLogs: ", hasLogs)

  useEffect(() => {
    if (isSuccess && data) {
      setHasLogs(data)
    } else {
      setHasLogs([])
    }
  }, [isSuccess, data])

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [newStatus, setNewStatus] = useState(hasVisit.status);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    reset({goals: []})
    reset();
    dispatch(setOpenState(false));
    setOpen(false);
  }

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));


  const { register, handleSubmit, control, setValue, reset, watch, getValues, formState: { errors, dirtyFields } } = useForm({
    defaultValues: {
      client: hasVisit.client,
      visitStart: hasVisit.visitStart,
      visitEnd: hasVisit.visitEnd,
      location: hasVisit.location,
      goals: hasVisit?.goals ? [...hasVisit.goals] : [], //making sure they are mutable
      status: hasVisit.status,
      cancelNote: hasVisit.cancelNote,
      clientSig: hasVisit.clientSig,
      sigToken: hasVisit.sigToken
    },
    shouldUnregister: true
  });

  // console.log("values: ", getValues())
  const handleLocationUpdate = (e) => {
    dispatch(updateVisitState({ location: e.target.value }));
  };

  const handleStartUpdate = (newStart) => {
    dispatch(updateVisitState({ visitStart: newStart }));
  };

  const handleEndUpdate = (newEnd) => {
    dispatch(updateVisitState({ visitEnd: newEnd }));
  };

  const handleStatusUpdate = (newStatus) => {
    dispatch(updateVisitState({ visitStart: newStatus }));
  };


  const handleUpdateGoal = (index, updatedGoal) => {
    dispatch(updateGoal({ index, goal: updatedGoal }));
  };


  const { fields, append } = useFieldArray({
    control,
    name: 'goals',
    shouldUnregister: true
  });

  // console.log("fields: ", fields)

  const handleAddGoal = () => {
    append({ title: "", note: "", marked: true, isMisc: true }); 
    dispatch(addGoal({ title: "", note: "", marked: true}))
  };
 

  const onSubmit = async (data) => {
    // console.log("pre data: ", data)

    data.client = hasVisit.client
    const _data = data.goals?.filter(goal => goal.marked != false)
    data.goals = _data
    // console.log("post data: ", data)


    try {
      const payload =  
      await editVisit({ visitId, data, isUser }).unwrap();
      console.log('fulfilled', payload)
    } catch (err) {
      console.error('Failed to save visit: ', err)
    }

    toast.success("Visit Updated", {
      position: toast.POSITION.TOP_RIGHT
    });
    handleClose();
  }

  const onError = (errors) => {
    toast.error("Error Updating Visit", {
      position: toast.POSITION.TOP_CENTER
    });
    console.log('error in visit form: ', errors);
  }

  const generateOptions = () => {
    return statusOptions.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  const watchStatus = watch('status');

  useEffect(() => {
    if (dirtyFields.status) {
      // console.log(watchStatus)
      if (watchStatus === 'Completed') {
        setNewStatus('Completed')
      } else if (watchStatus === 'Canceled') {
        setNewStatus('Canceled')
      }
    }
  }, [dirtyFields, watchStatus]);


  

  const handleLogsToVisit = (logData) => {
    setDoneLogs(prev => [...prev, logData])
  }

  const handleDateChange = useDebounceFunc((date) => {
    if (date && !isNaN(date)) {
      setLogDate(date.toISOString());
    }
  }, 300);

  const dialogRef = useRef(null);

  // console.log("open and dialogRef: ", open, dialogRef)

  useFocusTrap(open, dialogRef, handleClose);


  const handleDialogEntered = () => {
    if (dialogRef.current) {
      const focusableElements = dialogRef.current.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );

      if (focusableElements.length > 0) {
        const firstElement = focusableElements[0];
        firstElement.focus();
  
        // Scroll the focused element to the center top of the viewport
        firstElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    }
  };

  const renderLogRows = () => {
    return doneLogs?.map((l, idx) => {
      const commonFields = (
        <React.Fragment key={idx}>
          <TableCell>Type: {l.type}</TableCell>
          <TableCell>{new Date(l.time).toLocaleTimeString()}</TableCell>
        </React.Fragment>
      );

      if (l.type === 'Blood Pressure') {
        return (
          <React.Fragment key={idx}>
            <TableRow>
              {commonFields}
              <TableCell>Pressure: {l.fields.systolic}/{l.fields.diastolic}</TableCell>
              <TableCell>Pulse: {l.fields.pulse}</TableCell>
              <TableCell>Note: {l.fields.note}</TableCell>
            </TableRow>
          </React.Fragment>
        )
      }
      else {
        const dynamicFields = l.data
          ? Object.keys(l.data)
            .filter((key) => !key.toLowerCase().includes('date'))
            .map((key) => (
              l.data[key] !== undefined ? (
                <TableCell key={key}>{`${key}: ${l.data[key]}`}</TableCell>
              ) : null
            ))
          : [];


        return (
          <React.Fragment key={idx}>
            <TableRow>
              {commonFields}
              {dynamicFields}
            </TableRow>
          </React.Fragment>
        );
      }
    })
  }

  return (
    <div>
      <Button color='primary' variant='contained' onClick={handleClickOpen}>
        Edit Visit
      </Button>
      <Dialog
        open={open} 
        onClose={handleClose}
        disableAutoFocus={true}
        TransitionComponent={Transition}
        TransitionProps={{ onEntered: handleDialogEntered }}
        maxWidth={isMobile ? 'sm' : 'lg'} // Adjust maxWidth based on screen size
        // sx={{
        //   '& .MuiDialog-paper': {
        //     width: isMobile ? '100%' : '90%', // 100% for mobile, 80% for larger screens
        //     maxHeight: isMobile ? '90vh' : '85vh', // Ensure the dialog is scrollable if needed
        //   },
        // }}
        >
        <DialogTitle>Edit Visit</DialogTitle>
        <DialogContent sx={{ padding: isMobile ? 2 : 4 }}>

          <DialogContentText>
            {clientName ? (`Visit For: ${clientName}`) : ('')}
          </DialogContentText>


          <form id={formId}
            onSubmit={(e) =>
              handleSubmit(onSubmit, onError)(e).catch((e) => {
                console.log("e", e);
              })}>

            <br></br>
            {isMobile ? (
              <>
                <section>
                  <Controller
                    control={control}
                    name="visitStart"


                    render={({ field: {onChange, value} }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker

                          label="Visit Start"
                          error={!!errors.visitStart}
                          value={new Date(value).setSeconds(0, 0)} // Ensure value is always normalized
                          onChange={(newValue) => {
                            const normalizedValue = new Date(newValue);
                            normalizedValue.setSeconds(0, 0);
                            onChange(normalizedValue);
                            handleDateChange(normalizedValue); //debounced udpdate for logs
                            handleStartUpdate(normalizedValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <br></br>
                  {errors.visitStart && "Select Time In"}
                </section>
                <br></br>
                <section>
                  <Controller
                    control={control}
                    name="visitEnd"


                    render={({ field: {onChange, value} }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker

                          label="Visit End"
                          error={!!errors.visitEnd}
                          value={new Date(value).setSeconds(0, 0)}
                          onChange={(newValue) => {
                            const normalizedValue = new Date(newValue);
                            normalizedValue.setSeconds(0, 0);
                            onChange(normalizedValue);
                            handleEndUpdate(normalizedValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <br></br>
                  {errors.visitEnd && "Select Time Out"}
                </section>
                <br></br>
                <section>
                  <TextField
                    {...register('location', { required: true })}
                    error={!!errors.location}
                    label="Location"
                    name='location'
                    id='location'
                    required
                    onChange={(e) => handleLocationUpdate(e)}
                  />
                  <br></br>
                  {errors.location && "List a location for the visit"}
                </section>
                <br />
                <section>
                  <InputLabel id="status-label">Status</InputLabel>
                  <Controller
                    name="status"
                    control={control}
                    labelId="status-label"
                    render={({ field: {onChange, value} }) => (
                      <Select
                        value={value}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          onChange(newValue);
                          handleStatusUpdate(newValue)
                        }}
                        label="Status"
                      >
                        {generateOptions()}
                      </Select>
                    )}
                  />
                  <br />
                  {newStatus === 'Canceled' &&
                    <TextField
                      name='cancelNote'
                      {...register('cancelNote')}
                      label='Reason for Cancellation'
                    />
                  }
                </section>
                <Button startIcon={<AddIcon />} onClick={handleAddGoal} variant="contained" color="secondary">
                  Add Misc Goal
                </Button>
                <br />
                {fields?.length >= 1 ? (fields.map((goal, index) => (
                  <GoalInput
                    key={goal.id}
                    goal={goal}
                    index={index}
                    errors={errors}
                    setValue={setValue}
                    updateState={handleUpdateGoal}
                    control={control}
                  />
                ))
                ) : (
                  <Typography>Client has no service plan with goals to set here.</Typography>
                )
                }
              </>
            ) : (
              <Grid 
                container 
                spacing={0}
                alignItems="stretch"
                sx={{
                  flexWrap: 'nowrap',
                  maxWidth: '800px',
                  margin: 0,
                  padding: 0,
                  gap: 0,
                  '& > *': {
                    margin: 0,
                    padding: 0,
                  },
                }}>
                <Grid item xs={12} md={4}>
                  <section>
                    <Controller
                      control={control}
                      name="visitStart"


                      render={({ field: {onChange, value} }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateTimePicker

                            label="Visit Start"
                            error={!!errors.visitStart}
                            value={new Date(value).setSeconds(0, 0)} // Ensure value is always normalized
                            onChange={(newValue) => {
                              const normalizedValue = new Date(newValue);
                              normalizedValue.setSeconds(0, 0);
                              onChange(normalizedValue);
                              handleDateChange(normalizedValue); //debounced udpdate for logs
                              handleStartUpdate(normalizedValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    <br></br>
                    {errors.visitStart && "Select Time In"}
                  </section>
                  <br></br>
                  <section>
                    <Controller
                      control={control}
                      name="visitEnd"


                      render={({ field: {onChange, value} }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateTimePicker

                            label="Visit End"
                            error={!!errors.visitEnd}
                            value={new Date(value).setSeconds(0, 0)}
                            onChange={(newValue) => {
                              const normalizedValue = new Date(newValue);
                              normalizedValue.setSeconds(0, 0);
                              onChange(normalizedValue);
                              handleEndUpdate(normalizedValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    <br></br>
                    {errors.visitEnd && "Select Time Out"}
                  </section>
                  <br></br>
                  <section>
                    <TextField
                      {...register('location', { required: true })}
                      error={!!errors.location}
                      label="Location"
                      name='location'
                      id='location'
                      required
                      onChange={(e) => handleLocationUpdate(e)}
                    />
                    <br></br>
                    {errors.location && "List a location for the visit"}
                  </section>
                  <br />
                  <section>
                    <InputLabel id="status-label">Status</InputLabel>
                    <Controller
                      name="status"
                      control={control}
                      labelId="status-label"
                      render={({ field: {onChange, value} }) => (
                        <Select
                          value={value}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            onChange(newValue);
                            handleStatusUpdate(newValue);
                          }}
                          label="Status"
                        >
                          {generateOptions()}
                        </Select>
                      )}
                    />
                    <br />
                    {newStatus === 'Canceled' &&
                      <TextField
                        name='cancelNote'
                        {...register('cancelNote')}
                        label='Reason for Cancellation'
                      />
                    }
                  </section>
                </Grid>
                <Grid item xs={12} md={1} sx={{padding: 2, margin: 2}}>
                  <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2, height: '100%', borderColor: 'grey.400' }}/>
                </Grid>
                <Grid item xs={12} md={7}>
                <Button startIcon={<AddIcon />} onClick={handleAddGoal} variant="contained" color="secondary">
                  Add Misc Goal
                </Button>
                <br />
                  {fields?.length >= 1 ? (fields.map((goal, index) => (
                    <GoalInput
                      key={goal.id}
                      goal={goal}
                      index={index}
                      errors={errors}
                      setValue={setValue}
                      updateState={handleUpdateGoal}
                      control={control}
                    />
                  ))
                  ) : (
                    <Typography>Client has no service plan with goals to set here.</Typography>
                  )
                  }
                </Grid>
              </Grid>
            )}
            <br />
            {hasLogs?.length > 0 && hasLogs.map((log, idx) => {
              if (log.type === 'Blood Pressure') {
                return <AddBldPrsrFromVisit key={idx} log={log} clientId={clientId} isUser={isUser} hasDate={logDate} addLogSet={handleLogsToVisit} />
              } else {
                return <AddEntryFromVisit key={idx} log={log} clientId={clientId} isUser={isUser} hasDate={logDate} addLogSet={handleLogsToVisit} />
              }
            })}
            {doneLogs.length > 0 &&
              <div>
                <Typography>Logs added during this visit:</Typography>
                <Table>
                  <TableBody>
                    {renderLogRows()}
                  </TableBody>
                </Table>
              </div>
            }
            <br />
            {hasVisit.clientSig && hasVisit.status === 'Completed' ? <img src={hasVisit.clientSig} style={{ wdith: '200px', height: '200px' }} /> : ""}
          </form>
          <Divider sx={{ m: 1, p: 1 }} />
        </DialogContent>
        <DialogActions>
          <Button color='warning' variant='contained' onClick={handleClose}>Cancel</Button>
          <Button color="primary" variant="contained" form="editVisit" type="submit">
            Save
          </Button>
          {newStatus === 'Completed' && hasVisit.sigToken == null ? (
            <>
              <br />
              <SignVisit control={control} visit={getValues()} visitId={visitId} isUser={isUser} name={clientName} />
            </>) : ('')
          }
        </DialogActions>
      </Dialog>
    </div>
  )
}

EditVisit.propTypes = {
  visitId: PropTypes.any,
  clientName: PropTypes.any,
  clientId: PropTypes.any
}

export default EditVisit;
