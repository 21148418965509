import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogActions, DialogContent, IconButton, Button, Tooltip, Box, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ReactToPrint from 'react-to-print';
import PropTypes from 'prop-types';
import { useAuth } from '../../utilities/useAuth';
import { useApproveTimecardMutation } from '../../redux/apiSlice';
import '../../utilities/printStyleSheet.css'
import ApproveTimecard from './ApproveTimecard';
import { useGetUserName } from '../../utilities/useGetUserName';
import html2canvas from 'html2canvas';
import AddTimecardNotes from './AddTimecardNotes';

const ViewTimecard = ({file, timecardId, beenApproved}) => {
    const {admin, supAdmin, id} = useAuth();

    const [approve] = useApproveTimecardMutation()
    //set option for supervisors to approve? add role to useAuth

    const adminsName = useGetUserName(id)


    const printRef = useRef();

    const [open, setOpen] = useState(false)
    // const [base64Image, setBase64Image] = useState(file)
    const [sig, setSig] = useState('')
    const [approver, setApprover] = useState()
    const [approveDate, setApproveDate] = useState()
    const [trigger, setTrigger] = useState(false)
    const [approveNote, setApproveNote] = useState('')

    useEffect(() => {
        if(adminsName) {
            setApprover(adminsName)
            setApproveDate(new Date().toISOString())
        }
    }, [adminsName])

    useEffect(async () => {
        if (trigger && sig && approver && approveDate) {
            const newCanvas = await html2canvas(printRef.current)
            const newFileImage = await newCanvas.toDataURL('image/jpeg')

            const data = {
                approver: id,
                approveDate: approveDate,
                approveSig: sig,
                file: newFileImage,
                approveNotes: approveNote
            }

            approve({timecardId, data})
        }
    }, [trigger, sig, approver, approveDate, timecardId, id ])

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setSig('');
        setApprover('');
        setApproveDate('');
        setTrigger(false);
        setApproveNote('');
        setOpen(false);
      };

      const handleSaveSignature = (sigData) => {
        setSig(sigData)
        setTrigger(true)
        // html2canvas(printRef.current).then(canvas => {
        //     setBase64Image(canvas.toDataURL('image/jpeg'));
        //     setTrigger(true)
        // })
        
        
      } 

    //   const handleSaveSignature = (signatureData) => {
    //     // Function to update the base64 image with the signature
    //     const canvas = document.createElement('canvas');
    //     const ctx = canvas.getContext('2d');

    //     setSig(signatureData)
    
    //     const img = new Image();
    //     img.src = base64Image;
    //     img.onload = () => {
    //       canvas.width = img.width;
    //       canvas.height = img.height;
    //       ctx.drawImage(img, 0, 0);
    
    //       const signatureImg = new Image();
    //       signatureImg.src = signatureData;
    //       signatureImg.onload = () => {
    //         // Adjust the position (50, 50) as needed
    //         ctx.drawImage(signatureImg, 50, 950);
    
    //         // Get the new base64 image
    //         const newBase64Image = canvas.toDataURL('image/jpeg');
    //         setBase64Image(newBase64Image);
    //         setTrigger(true);
    //       };
    //     };
    //   };

  return (
    <div>
        <IconButton color='secondary' onClick={handleClickOpen}>
            <Tooltip title="View Timecard">
                <SearchIcon />
            </Tooltip>
        </IconButton>
        <Dialog fullWidth maxWidth={'xl'} open={open} onClose={handleClose}>
            <DialogContent>
                <div id='print' ref={printRef} style={{ display: 'inline-block'}}>
                    <img src={file} alt='Timecard' style={{ width: '100%', height: 'auto'}} /> <br />   
                    {sig && (
                        <Box style={{ marginTop: '10px', left: '30px' }} >
                            <Typography variant='h5'>Approved: </Typography>
                            <img src={sig} alt="signature" /> <br />
                            <Typography variant='h6'>{approver} </Typography>
                            <Typography variant='h6'>{new Date(approveDate).toLocaleString()}</Typography> 
                        </Box>
                    )}
                    <Typography variant='h5'>Notes for approval:</Typography> <br />
                    <Typography variant='h6'>{approveNote}</Typography>

                </div>
            </DialogContent>
            <DialogActions>
                {admin || supAdmin && !approveNote && !beenApproved && <AddTimecardNotes handleSave={setApproveNote} />}
                {admin || supAdmin && !sig && !beenApproved && <ApproveTimecard onSave={handleSaveSignature} />}
                <ReactToPrint trigger={() => <Button variant='contained' color='primary'>Print</Button>} content={()=> printRef.current} />
                <Button variant='contained' color='warning' onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
        
    </div>
  )
}

ViewTimecard.propTypes = {
    file: PropTypes.any,
    timecardId: PropTypes.any,
    beenApproved: PropTypes.any
}

export default ViewTimecard