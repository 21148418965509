import React, { useEffect, useState, useMemo } from 'react';
import { Tabs, Tab, Box, Typography, Select, MenuItem, Button, IconButton } from '@mui/material';
import HealthLogMenu from './HealthLogMenu';
import PropTypes from 'prop-types';
import { useAddHealthLogTypeMutation, useGetHealthLogsQuery, useGetYearsForHealthLogsQuery } from '../../../redux/apiSlice';
import { useAuth } from '../../../utilities/useAuth';
import HealthLogView from './HealthLogView';
import { toast } from 'react-toastify';
import { ArrowBack, ArrowForward } from '@mui/icons-material';


const monthOptions = [
  { label: 'Select Month', value: '' },
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 }
]

const HealthTabs = ({ clientId }) => {

  const today = new Date()
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();

  
  const [selectedTab, setSelectedTab] = useState(0);
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);

  const { data: list, refetch, isSuccess, isError } = useGetHealthLogsQuery({ clientId, month, year })
  const { data: yearSet } = useGetYearsForHealthLogsQuery(clientId)

  // const [healthData, setHealthData] = useState(list || []);
  console.log("list: ", list)


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (isSuccess) {
      // setHealthData(list);
      // console.log("healthData length: ", healthData.length)
      setSelectedTab(list.length - 1);
    }
  }, [list, isSuccess])

  const { id } = useAuth();
  const isUser = id

  const [addLogType] = useAddHealthLogTypeMutation()


  const handleAddNewLogType = async (newLogType) => {
    const newHealthLog = {
      type: newLogType.logType,
      fields: newLogType.fields,
      target: newLogType.target,
      upperAlert: newLogType.upperAlert,
      lowerAlert: newLogType.lowerAlert,
      goal: newLogType.goal
    };
    const data = newHealthLog


    try {
      await addLogType({ clientId, isUser, data })
      // setHealthData([...healthData, newHealthLog]);

    } catch (err) {
      console.log('Failed to add log type', err)
      toast.error("Error adding log type", {
        position: toast.POSITION.TOP_CENTER
      });
    }

    toast.success("Log Type Added", {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  const existingTypes = list?.map(x => { return x.type });

  const handlePrevMonth = () => {
    if (month === 1) {
      setMonth(12);
      setYear((prevYear) => prevYear - 1);
    } else {
      setMonth((prevMonth) => prevMonth - 1);
    }
    refetch();
  };

  const handleNextMonth = () => {
    if (month === 12) {
      setMonth(1);
      setYear((prevYear) => prevYear + 1);
    } else {
      setMonth((prevMonth) => prevMonth + 1);
    }
    refetch();
  };

  const processedYearSet = useMemo(() => {
    const years = yearSet?.length ? yearSet : [];
    return Array.from(new Set([...years, currentYear]))
      .sort((a, b) => a - b)
      .map((year) => ({ label: `${year}`, value: year }));
  }, [yearSet, currentYear]);

  const yearOptions = useMemo(() => [
    { label: 'Select Year', value: '' },
    ...processedYearSet,
  ], [processedYearSet]);
  const getMonthLabel = (value) => {
    const month = monthOptions.find((option) => option.value === value);
    return month ? month.label : 'Invalid Month'; // Return label or a default message if not found
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value)
  };

  const handleYearChange = (event) => {
    setYear(event.target.value)
  };

  const handleFetchLogs = () => {
    refetch({ clientId, month, year });
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {existingTypes && <HealthLogMenu exists={existingTypes} onAdd={handleAddNewLogType} />}
        <Tabs value={selectedTab} onChange={handleTabChange}>
          {list && list.map((log, index) => (
            <Tab key={index} label={log.type} />
          ))}
        </Tabs>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
      <IconButton onClick={handlePrevMonth}>
          <ArrowBack />
        </IconButton>
        <Typography>
          Viewing: {getMonthLabel(month)} {year}
        </Typography>
        <IconButton onClick={handleNextMonth}>
          <ArrowForward />
        </IconButton>
        <Select
          // labelId="month-select-label"
          label="Month"
          name='month'
          value={month}
          onChange={handleMonthChange}
        >
          {monthOptions.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
        <Select
          label="Year"
          name='year'
          value={year}
          onChange={handleYearChange}
        >
           {yearOptions.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
        <Button onClick={handleFetchLogs}>Run</Button>

      </Box>
      {isError ? (
        <Typography variant="h6" color="error" sx={{ textAlign: 'center', mt: 3 }}>
          An error occurred while fetching logs. Please try again later.
        </Typography>
      ) : list && list.length === 0 ? (
        <Typography variant="h6" sx={{ textAlign: 'center', mt: 3 }}>
          No logs found for {getMonthLabel(month)} {year}.
        </Typography>
      ) : (
        isSuccess &&
        list.map((log, index) => (
          <TabPanel key={index} value={selectedTab} index={index}>
            <HealthLogView log={log} clientId={clientId} isUser={isUser} />
          </TabPanel>
        ))
      )}
    </Box>
  );
};

HealthTabs.propTypes = {
  clientId: PropTypes.any
}

const TabPanel = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any,
  index: PropTypes.any
}

export default HealthTabs;
