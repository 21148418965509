import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import AssignUser from '../../userviews/AssignUser';
import { ClientContext } from '../ClientContext';
import ClientUsers from './ClientUsers';
import EditPersInfo from './EditPersInfo';
import Income from './Income';
import ClientSupports from './ClientSupports';

const PersInfo = () => {

  const { client } = useContext(ClientContext);
  const clientId = client._id
    
    
return (
    <>
     <Card sx={{m:1, p: 1, display: 'flex', flexDirection: 'column' }}>
        <CardHeader
            title="Client Details"
            action={<EditPersInfo client={client}/>}
        />
            <CardContent>
    <div className="card-body">
        <Grid container spacing={2}>
            {/* Row 1: Birth Date */}
            <Grid item xs={12}>
                <Typography variant="body1">
                    <strong>Birth Date:</strong> {new Date(client?.personalInfo?.dateOfBirth).toLocaleDateString()}
                </Typography>
            </Grid>

            {/* Row 2: Phone and Email */}
            <Grid item xs={12}>
                <Typography variant="body1">
                    <strong>Phone:</strong> {client?.personalInfo?.phone} <br />
                    <strong>Email:</strong> {client?.personalInfo?.email}
                </Typography>
            </Grid>

            {/* Row 3: Address */}
            <Grid item xs={12}>
                <Typography variant="body1">
                    <strong>Address:</strong> {client?.personalInfo?.address?.lineOne}
                </Typography>
                {client?.personalInfo?.address?.lineTwo && (
                    <Typography variant="body1">{client?.personalInfo?.address?.lineTwo}</Typography>
                )}
                <Typography variant="body1">
                    {client?.personalInfo?.address?.city}, {client?.personalInfo?.address?.state}{' '}
                    {client?.personalInfo?.address?.zip}
                </Typography>
            </Grid>

            {/* Row 4: Living Situation and Emergency Contact */}
            <Grid item xs={12}>
                <Typography variant="body1">
                    <strong>Living Situation:</strong> {client?.personalInfo?.livingSituation}
                </Typography>
                <Typography variant="body1">
                    <strong>Emergency Contact:</strong> {client?.personalInfo?.emergencyContact}
                </Typography>
            </Grid>

            {/* Row 5: Regional Center Info */}
            <Grid item xs={12}>
                <Typography variant="body1">
                    <strong>Regional Center Info:</strong> <br />
                    <strong>RC: </strong>{client?.personalInfo?.regionalCenter} <br />
                    <strong>UCI#: </strong> {client?.personalInfo?.rcId}
                </Typography>
                <Typography variant="body1">
                    <strong>Service Coordinator:</strong> {client?.personalInfo?.serviceCoordinator} <br />
                    <strong>Phone:</strong> {client?.personalInfo?.serviceCoordinatorPhone}  {' '}
                    <strong>Email:</strong> {client?.personalInfo?.serviceCoordinatorEmail}
                </Typography>
            </Grid>
        </Grid>
    </div>
</CardContent>

    </Card>
    <div>
    <Card sx={{m:1, p: 1, display: 'flex', flexDirection: 'column' }}>
        <Income /><br />
    </Card>
    <Card sx={{m:1, p: 1, display: 'flex', flexDirection: 'column' }}>
        <ClientSupports />
    </Card>
    <Card sx={{m:1, p: 1, display: 'flex', flexDirection: 'column' }}>
        <CardHeader
            title="Assigned Users"
        />
        <CardContent>
            <AssignUser clientId={clientId} /><br />
            <ClientUsers clientId={clientId} />
        </CardContent>
    </Card>
        
        
    </div>
    
    </>  
);

};

export default PersInfo;