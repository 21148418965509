import { useSelector } from "react-redux";
import { selectCurrentToken } from "../redux/slices/authSlice";
import jwt_decode from 'jwt-decode';
import { useEffect, useState } from "react";
import { useGetUserQuery } from "../redux/apiSlice";

const useAuth = () => {
    const token = useSelector(selectCurrentToken)
    const hasViewOn = useSelector(state => state.user.view.viewOn)
    const userId = useSelector(state => state.user.view.viewAs)
    // console.log(userId)

    const [skip, setSkip] = useState(true)
    useEffect(() => {
        if(hasViewOn) {
            setSkip(false)
        }
    }, [hasViewOn])

    const {data, isSuccess} = useGetUserQuery(userId, {skip})

    let admin = false
    let supAdmin = false
    let supervisor = false
    let status = "Staff"

    if (hasViewOn) {
        // console.log("hasViewOn: ", hasViewOn)
        if(isSuccess){
            // console.log("isSuccess: ", isSuccess, data)
            const username = data.username
            const role = data.role
            const id = data._id
            const fullName = data.fullName
            
            if (role === "Super Admin") {
                supAdmin = true;
                status = "Super Admin";
            } else if (role === "Admin") {
                admin = true;
                status = "Admin";
            } else if (role === "Supervisor") {
                supervisor = true;
                status = "Supervisor"
            } else if (role === "Staff") {
                status = "Staff"
            }

            // console.log("stats: ", username, fullName, role, status, id)

            return { username, fullName, role, id, status, admin, supAdmin, supervisor }
        } else {
            return { username: '', role: ''}
        }
    } else if (!hasViewOn){

        if (token) {
            const decoded = jwt_decode(token)
            
            const { username, role, id, fullName} = decoded.UserInfo

            // console.log("use auth id: ", id)
            // console.log("useAuth: ", decoded.UserInfo)

            if (role === "Super Admin") {
                supAdmin = true;
                status = "Super Admin";
            } else if (role === "Admin") {
                admin = true;
                status = "Admin";
            } else if (role === "Supervisor") {
                supervisor = true;
                status = "Supervisor"
            } else if (role === "Staff") {
                status = "Staff"
            }

            return { username, fullName, role, id, status, admin, supAdmin, supervisor }
        } else {
            return { username: '', role: ''}
        }
    }
}

export {useAuth};