import React, {useState, useEffect} from 'react';
import SelectAsyncUser from '../../utilities/SelectAsyncUser';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { changeSchedule } from '../../redux/slices/userSlice';

const ChangeUserSchedule = ({currentUserId}) => {

    const dispatch = useDispatch()
    const [trigger, setTrigger] = useState(false)
    const [hasId, setHasId] = useState(null)


    const handleUserChange = (_id) => {
        // console.log("in change, _id: ", _id)
        // console.log("in change, current: ", currentUserId)
        if (_id !== currentUserId) {
            setHasId(_id)
            setTrigger(true)
        }
	}

    useEffect(() => {
        if(trigger) {
            dispatch(changeSchedule(hasId))
            setTrigger(false)
        }
    }, [hasId, trigger])

    return (
        <Box sx={{ border: 1 }}>
            <Typography>Select User: </Typography>
                <SelectAsyncUser 
                     
                     placeholder='Enter User Name' 
                     //   handleChange = {(_id  => field.onChange(_id))}
                     handleChange = {(_id => handleUserChange(_id))}
                     theme={theme => ({
                       ...theme,
                       borderRadius: 0,
                       colors: {
                         ...theme.colors,
                          primary25: 'blue',
                          primary: 'black'
                     }
                      })}
                  />  
        </Box>
    )
}

ChangeUserSchedule.propTypes = {
    currentUserId: PropTypes.any
}

export default ChangeUserSchedule;