import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Divider, Grid } from '@mui/material';
import { useAuth } from '../../utilities/useAuth';
import { useGetTimecardsByUserQuery } from '../../redux/apiSlice';
import { useSelector } from 'react-redux';
import SingleUserTimeHistory from './SingleUserTimeHistory';
import TimecardSearch from './TimecardSearch';
import AllTimecardList from './AllTimecardList';
import TimecardSearchList from './TimecardSearchList';

const TimeHistory = () => {
    const { admin, supAdmin, id } = useAuth();
    const userId = id

    const [getAll, setGetAll] = useState(false)
    const [getSearch, setGetSearch] = useState(false)

    const options = useSelector(state => state.admin?.timecardSearch?.options)
    const trigger = useSelector(state => state.admin?.timecardSearch?.trigger)
    const searchAll = useSelector(state => state.admin?.timecardSearch?.searchAll)


    const { data, isLoading, isSuccess } = useGetTimecardsByUserQuery(userId)


    // let searchCriteria
    // let searchContent

    useEffect(() => {
        if (searchAll) {
            setGetAll(true)
            setGetSearch(false)
        }
    }, [searchAll])

    useEffect(() => {
        if (trigger) {
            setGetSearch(true)
            setGetAll(false)
        }
    }, [trigger])

    // let defaultContent

    // if (isLoading) {
    //     defaultContent = <CircularProgress />
    // } else if (isSuccess) {
    //     defaultContent = <SingleUserTimeHistory data={data} />
    // }

    // if (admin || supAdmin) {
    //     searchCriteria = 
    //     <>
    //         <Box sx={{ position: 'relative', padding: 2, zIndex: 1 }}>
    //             <TimecardSearch />
    //         </Box>
    //     </>
    // }


    // if (getAll) {
    //     searchContent = <AllTimecardList />
    // }

    // if (getSearch) {
    //     console.log("Options: ", options)
    //     const searchOptions = {
    //         startDate: options.dateRange[0],
    //         endDate: options.dateRange[1],
    //         byRange: options.byRange,
    //         bySubmit: options.bySubmit,
    //         userId: options.userIds
    //     }

    //     console.log("searchOptions: ", searchOptions)

    //     searchContent = <TimecardSearchList searchOptions={searchOptions} />
    // }



    return (
        <Grid container spacing={4}>
            {/* Search Criteria Section */}
            {(admin || supAdmin) && (
                <Grid item xs={12}>
                    <Box sx={{ minHeight: '100px', padding: 2 }}>
                        <TimecardSearch />
                    </Box>
                </Grid>
            )}

            <Grid item xs={12}>
                <Divider />
            </Grid>

            {/* All Timecard List Section */}
            {getAll && (
                <Grid item xs={12}>
                    <AllTimecardList />
                </Grid>
            )}

            {/* Search Timecard List Section */}
            {getSearch && (
                <Grid item xs={12}>
                    <TimecardSearchList
                        searchOptions={{
                            startDate: options?.dateRange[0],
                            endDate: options?.dateRange[1],
                            byRange: options?.byRange,
                            bySubmit: options?.bySubmit,
                            userId: options?.userIds,
                        }}
                    />
                </Grid>
            )}

            <Grid item xs={12}>
                <Divider />
            </Grid>

            {/* Default Content Section */}
            <Grid item xs={12}>
                {isLoading ? <CircularProgress /> : isSuccess && <SingleUserTimeHistory data={data} />}
            </Grid>
        </Grid>

    )
}

export default TimeHistory