import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SelectAsyncUser from './utilities/SelectAsyncUser';
import PropTypes from 'prop-types';

const ViewAs = ({handleMenuClose}) => {
    // const dispatch = useDispatch();
    const [open, setOpen] = useState(false)
    const [viewState, setViewState] = useState(null)
    const [userId, setUserId] = useState(null)
    
    const handleClickOpen = () => {
        setOpen(true);
      };
    
     const handleClose = () => {
        setOpen(false);
        handleMenuClose();
      };

    const handleUserChange = (_id) => {
        setUserId(_id)
    }

    useEffect(() => {
        if(userId) {
            setViewState({viewOn: true, viewAs: userId})
        }
    }, [userId])

    const openNewTabWithState = () => {
        const newState = viewState;
        const queryString = new URLSearchParams(newState).toString();
        
        const currentOrigin = window.location.origin; // e.g., http://localhost:3000
        const currentPath = location.pathname; // e.g., /dashboard
        console.log("origin: ", currentOrigin, "path: ", currentPath)

    // Create a new URL object using the origin and pathname
        const url = new URL(currentOrigin + currentPath);
        // console.log("url: ", url)
        const newTabUrl = `${url}?${queryString}`;
        // console.log("newTab: ", newTabUrl)
        // Object.keys(newState).forEach((key) => {
        // url.searchParams.set(key, newState[key]);
        // });
    
        window.open(newTabUrl, '_blank'); 
        handleMenuClose();
      };
    
    return(
        <>
            <Button startIcon={<VisibilityIcon />} onClick={handleClickOpen}>
                View As
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>View Octochart as User</DialogTitle>
                <DialogContent>
                <Typography>Select User: </Typography>
                <SelectAsyncUser 
                     
                     placeholder='Enter User Name' 
                     //   handleChange = {(_id  => field.onChange(_id))}
                     handleChange = {(_id => handleUserChange(_id))}
                     theme={theme => ({
                       ...theme,
                       borderRadius: 0,
                       colors: {
                         ...theme.colors,
                          primary25: 'blue',
                          primary: 'black'
                     }
                      })}
                  />  
                </DialogContent>
                <DialogActions>
                    <Button onClick={openNewTabWithState} color='success'>Open View</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

ViewAs.propTypes = {
    handleMenuClose: PropTypes.any
}

export default ViewAs;