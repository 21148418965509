import React, {useState, useEffect} from 'react';
import {Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, Stack, Divider} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useEditSettingsMutation } from '../redux/apiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { setAgencyDetails } from '../redux/slices/settingsSlice';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';


const EditSettings = ({data}) => {
    // console.log("settings: ", data)
    const [open, setOpen] = useState(false);
    const [ddctToggle, setDdctToggle] = useState(false);
    const [fedRateToggle, setFedRateToggle] = useState(false);
    const [changeToggle, setChangeToggle] = useState(false);
    const [calendarYear, setCalendarYear] = useState('outlined');
    const [fiscalYear, setFiscalYear] = useState('outlined');

    const agencyId = useSelector(state => state.settings.id)
    const dispatch = useDispatch()

    const [editSettings] = useEditSettingsMutation()

    const handleClickOpen = () => {
        setOpen(true);
      };
    
    const handleClose = () => {
        setOpen(false);
      };

      useEffect(() => {
        if (data) {
          setCalendarYear(data.yearType === 'calendarYear' ? 'contained' : 'outlined');
          setFiscalYear(data.yearType === 'fiscalYear' ? 'contained' : 'outlined');
          setDdctToggle(data.useStdDdct || false);
          setFedRateToggle(data.useFedRate || false);
        }
      }, [data]);



    //   console.log("data: ", data)

    const {register, handleSubmit, setValue} = useForm({
        defaultValues: {
            name: data?.name,
            regionalCenter: data?.regionalCenter,
            useStdDdct: data?.useStdDdct,
            useFedRate: data?.useFedRate,
            fedRate: data?.fedRate,
            yearType: data?.yearType
        }
    })

    const onSubmit = async (data) => {
        // console.log('data: ', data)

        try{
            dispatch(setAgencyDetails(data))
            
            // const payload = 
            await editSettings({ agencyId, data}).unwrap();
            // console.log('fulfilled', payload)
        } catch (err) {
            console.error('Failed to update settings: ', err)
        }
        toast.success("Settings Updated", {
          position: toast.POSITION.TOP_RIGHT
        });
        handleClose();
    }

    const onError = () => {
        alert("Please check the requirements for the form");
        console.log('error on update')
    }


    const handleYearType = (type) => {
        setCalendarYear(type === 'calendarYear' ? 'contained' : 'outlined');
        setFiscalYear(type === 'fiscalYear' ? 'contained' : 'outlined');
        setValue('yearType', type);
      };

    
  return (
    <div>
        <Button color='primary' variant='contained' onClick={handleClickOpen}>Edit Settings</Button>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit Agency Settings</DialogTitle>
            <DialogContent>
  <form
    id="editSettings"
    onSubmit={(e) =>
      handleSubmit(onSubmit, onError)(e).catch((e) => {
        console.log("e", e);
      })
    }
  >
    {/* Agency Name Section */}
    <Typography variant="subtitle1">
      <strong>Agency Name:</strong> {data?.name}
    </Typography>
    <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1 }}>
      <Typography variant="body1">Change Name?</Typography>
      <Checkbox
        checked={changeToggle}
        value={changeToggle}
        onClick={() => setChangeToggle(!changeToggle)}
      />
    </Stack>
    {changeToggle && (
      <TextField
        fullWidth
        margin="normal"
        label="New Agency Name"
        {...register('name')}
      />
    )}
    <Divider />
    {/* Regional Center Section */}
    <Typography variant="subtitle1" sx={{ mt: 2 }}>
      <strong>Regional Center:</strong>
    </Typography>
    <TextField
      fullWidth
      margin="normal"
      label="Default Regional Center"
      {...register('regionalCenter')}
    />
    <Divider />
    {/* Mileage Section */}
    <Typography variant="subtitle1" sx={{ mt: 2 }}>
      <strong>Mileage:</strong>
    </Typography>
    <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1 }}>
      <Checkbox
        checked={ddctToggle}
        value={ddctToggle}
        onClick={() => setDdctToggle(!ddctToggle)}
        {...register('useStdDdct')}
      />
      <Typography>Use Standard Deduction on Mileage?</Typography>
    </Stack>
    <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1 }}>
      <Checkbox
        checked={fedRateToggle}
        value={fedRateToggle}
        onClick={() => setFedRateToggle(!fedRateToggle)}
        {...register('useFedRate')}
      />
      <Typography>Use Federal Rate for Mileage?</Typography>
    </Stack>
    {fedRateToggle && (
      <TextField
        fullWidth
        margin="normal"
        type="number"
        label="Federal Rate"
        InputProps={{ inputProps: { step: 'any' } }}
        {...register('fedRate')}
      />
    )}
    <Divider />
    {/* Contracts Section */}
    <Typography variant="subtitle1" sx={{ mt: 2 }}>
      <strong>Contracts:</strong>
    </Typography>
    <Typography variant="body1" sx={{ mt: 1 }}>
      Year Type for Contracts Tracking:
    </Typography>
    <Typography variant="body2" sx={{ mb: 2 }}>
      Calendar Year = Jan 1 - Dec 31, Fiscal Year = Jul 1 - Jun 30
    </Typography>
    <Stack direction="row" spacing={2}>
      <Button
        color="secondary"
        variant={calendarYear}
        onClick={() => handleYearType('calendarYear')}
      >
        Calendar Year
      </Button>
      <Button
        color="secondary"
        variant={fiscalYear}
        onClick={() => handleYearType('fiscalYear')}
      >
        Fiscal Year
      </Button>
    </Stack>
  </form>
</DialogContent>

            <DialogActions>
            <Button color='warning' variant='contained' onClick={handleClose}>Cancel/ Close</Button>
                <Button color='primary' variant='contained' type='submit' form='editSettings'>Submit</Button>
            </DialogActions>
        </Dialog>
    </div>
  )
}

EditSettings.propTypes = {
    data: PropTypes.any
}

export default EditSettings