import React, { useState } from "react";
import { Switch, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";

function GoalInput({ goal, control, index, setValue, errors, updateState }) {


  const [toggle, setToggle] = useState(goal?.marked || false);
  const isMiscGoal = goal?.isMisc || false;

  const handleToggleChange = () => {
    setToggle(!toggle);
    setValue(`goals.${index}.marked`, !toggle);
    updateState(index, { marked: !toggle });
  };

  return (
    <React.Fragment key={index}>
      <Controller
        control={control}
        name={`goals.${index}.marked`}
        defaultValue={goal?.marked}
        render={({ field: { value } }) => (
          <Switch
            checked={toggle}
            onChange={handleToggleChange}  
            value={value}
          />
        )}
      />
      {isMiscGoal ? (
        <Controller
        control={control}
        name={`goals.${index}.title`}
        // defaultValue={goal?.title || ""}
        render={({ field: {onChange, value} }) => (
          <TextField
            value={value}
            onChange={(e) => {
              onChange(e.target.value)
              setValue(`goals.${index}.title`, e.target.value)
              updateState(index, { title: e.target.value });
            }}
            label="Goal Title"
            fullWidth
            error={!!errors?.goals?.[index]?.title}
            helperText={errors?.goals?.[index]?.title ? "Title is required" : ""}
          />
        )}
      />
      ) : (
        <>
        <label>{goal?.title}</label>
        <Controller
          control={control}
          name={`goals.${index}.title`}
          defaultValue={goal?.title || ""}
          render={({ field }) => (
            <input
              {...field}
              type="hidden"
              disabled
              value={goal?.title} 
            />
          )}
        />
        </>
      )}
      <br />
      {toggle && (
        <Controller
          control={control}
          name={`goals.${index}.note`}
          defaultValue={goal?.note}
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value}
              onChange={(e) => {
                onChange(e.target.value);
                updateState(index, { note: e.target.value });
              }}
              label="Progress Note"
              multiline
              fullWidth
              error={!!errors?.goals?.[index]?.note} 
            />
          )}
        />
      )}
    </React.Fragment>
  // const [toggle, setToggle] = useState(goal.marked);

  // useEffect(() => {
  //   setToggle(goal.marked);
  // }, [goal]);

  // const handleToggleChange = () => {
  //   const markedState = !toggle;
  //   setToggle(markedState);
  //   setValue(`goals.${index}.marked`, markedState); 
  //   updateState(index, { marked: markedState });
  // };

  // const handleNoteChange = (e) => {
  //   setValue(`goals.${index}.note`, e.target.value); 
  //   updateState(index, { note: e.target.value });
  // };

  // return (
  //   <React.Fragment key={index}>
  //     <Switch
  //       checked={toggle}
  //       onChange={handleToggleChange}
  //       value={goal?.marked}
  //     />
  //     <label>{goal?.title}</label>
  //     <br />
  //     {toggle && (
  //       <TextField
  //         value={goal?.note}
  //         error={!!errors.note}
  //         onChange={handleNoteChange}
  //         label="Progress Note"
  //         multiline
  //         fullWidth
  //       />
  //     )}
  //   </React.Fragment>
  );
}

GoalInput.propTypes = {
  goal: PropTypes.any,
  index: PropTypes.any,
  setValue: PropTypes.func,
  control: PropTypes.any,
  errors: PropTypes.any,
  updateState: PropTypes.any
};

export default GoalInput;