import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { ClientContext } from '../ClientContext';
import EditClientSupports from './EditClientSupports';

const ClientSupports = () => {

  const { client } = useContext(ClientContext);
  const clientId = client._id

  const hasIHSS = client?.supports?.ihss?.hasIhss ? {bool: true, val: "Yes"} : {bool: false, val: "No"}
  const hasConserve = client?.supports?.conserve?.conserved ? {bool: true, val: "Yes"} : {bool: false, val: "No"}
    
    
return (
    <>
     <Card sx={{m:1, p: 1, display: 'flex', flexDirection: 'column' }}>
        <CardHeader
            title="Client Supports"
            action={<EditClientSupports clientId={clientId} supports={client.supports}/>}
        />
            <CardContent>
            <div className = "card-body">
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>IHSS</Typography>
                <div className='row'>
                    <Typography>Does the client have IHSS?: {hasIHSS?.val}</Typography>
                </div>
                {hasIHSS.bool && 
                    <>
                        <div className = "row">
                            <Typography>Provider: {client?.supports?.ihss?.provider}  Hours: {client?.supports?.ihss?.hours}</Typography>
                        </div>
                        
                        <div className = "row">
                            <Typography> Services: {client?.supports?.ihss?.services}</Typography>                        
                        </div>

                        <div className='row'>
                            <Typography>County Worker: {client?.supports?.ihss?.countyRep}</Typography>
                        </div>
                        <div className='row'>
                            <Typography>Phone: {client?.supports?.ihss?.countyRepPhone}  Email: {client?.supports?.ihss?.countyRepEmail}</Typography>
                        </div>
                    </>
                }
                <br />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Conservatorship</Typography>
                <div className="row">
                    <Typography>Is the client conserved: {hasConserve?.val}</Typography>
                </div>
                    {hasConserve?.bool &&
                        <>
                            <div className='row'>
                            <Typography>Conservator: {client?.supports?.conserve?.conservator}</Typography>
                            <br />
                            <Typography>Conserved Areas: {client?.supports?.conserve?.details}</Typography>
                            </div>
                     </>}
               
            </div>
        </CardContent>
    </Card>
    </>  
);

};

export default ClientSupports;