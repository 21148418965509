import React, { useState, useEffect } from "react";
import {
  Button,
  Select,
  MenuItem,
  LinearProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
  Paper,
  FormControl,
  InputLabel,
  Box,
} from "@mui/material";
import { toast } from "react-toastify";
import {
  useGetUsersListQuery,
  useGetSingleUserAttendanceMutation,
  useGetAllClientAttendanceMutation,
} from "../../redux/apiSlice";
import { getDaysInMonth } from "date-fns";

const monthOptions = [
  { label: "Select Month", value: "" },
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

export default function FullAttendance() {
    const today = new Date()
    const initialMonth = today.getMonth() + 1;
    const initialYear = today.getFullYear();

  const [yearList, setYearList] = useState([]);
  const [selectedUser, setSelectedUser] = useState("all");
  const [month, setMonth] = useState(initialMonth);
  const [year, setYear] = useState(initialYear);
  const [daysInMonth, setDaysInMonth] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);

  const { data: users } = useGetUsersListQuery();
  const [getUserAttendance, { isLoading: isUserLoading }] =
    useGetSingleUserAttendanceMutation();
  const [getAllAttendance, { isLoading: isAllLoading }] =
    useGetAllClientAttendanceMutation();

    console.log("user: ", selectedUser, "single loading: ", isUserLoading, "all load: ", isAllLoading)
  // Populate years dropdown
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearsArray = [];
    for (let y = currentYear; y >= 2017; y--) {
      yearsArray.push({ label: y.toString(), value: y });
    }
    setYearList(yearsArray);
  }, []);

  // Update days in the selected month
  useEffect(() => {
    if (month && year) {
      const days = getDaysInMonth(new Date(year, month - 1));
      setDaysInMonth(days);
    }
  }, [month, year]);

  // Fetch attendance data when "Run" is clicked
  const fetchAttendance = async () => {
    if (!month || !year) {
      toast.error("Please select a month and year", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    setLoading(true);
    try {
      if (selectedUser === "all") {
        console.log("all hit")
        const response = await getAllAttendance({ month, year }).unwrap();
        setTableData(processAllAttendanceData(response));
      } else {
        console.log("single hit")
        const response = await getUserAttendance({
          user: selectedUser,
          month,
          year,
        }).unwrap();
        console.log("response: ", response)
        setTableData(processUserAttendanceData(response, daysInMonth));
      }
    } catch (error) {
      toast.error("Failed to fetch attendance data");
      console.error("Error fetching attendance:", error);
    } finally {
      setLoading(false);
    }
  };

  // Helper to process all staff data
  const processAllAttendanceData = (data) => {
    const result = {};
    data.forEach(({ client, date, totalHours }) => {
      if (!result[client]) result[client] = Array(daysInMonth).fill(0);
      result[client][date - 1] = totalHours; // `date` is 1-based
    });
    return Object.entries(result).map(([client, stats]) => ({ client, stats }));
  };

  // Helper to process user-specific data
  const processUserAttendanceData = (data, daysInMonth) => {
    const result = {};
    data.forEach(({ client, date, totalHours }) => {
      if (!result[client]) result[client] = Array(daysInMonth).fill(0);
    //   const day = new Date(date).getDate();
      result[client][date - 1] += totalHours;
    });
    return Object.entries(result).map(([client, stats]) => ({
      client,
      stats,
    }));
  };

  const stickyStyles = {
    position: "sticky",
    top: 0,
    zIndex: 2,
    backgroundColor: "background.paper",
  };

  return (
    <>
      <Box display="flex" gap={2} p={2}>
        <FormControl>
          <InputLabel>User</InputLabel>
          <Select value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
            <MenuItem key="all" value="all">
              All Staff
            </MenuItem>
            {users &&
              users.map((user) => (
                <MenuItem key={user._id} value={user._id}>
                  {user.fullName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel>Month</InputLabel>
          <Select value={month} onChange={(e) => setMonth(e.target.value)}>
            {monthOptions.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel>Year</InputLabel>
          <Select value={year} onChange={(e) => setYear(e.target.value)}>
            {yearList.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" onClick={fetchAttendance}>
          Run
        </Button>
      </Box>
      {loading || isUserLoading || isAllLoading ? (
        <LinearProgress />
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 500, overflow: "auto" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={stickyStyles}>Client</TableCell>
                {[...Array(daysInMonth)].map((_, i) => (
                  <TableCell key={i} align="center" sx={stickyStyles}>
                    {i + 1}
                  </TableCell>
                ))}
                <TableCell align="center" sx={stickyStyles}>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData &&
                tableData.map(({ client, stats }, i) => (
                  <TableRow key={i} sx={{ backgroundColor: i % 2 === 0 ? "white" : "grey.100" }}>
                    <TableCell 
                        sx={{
                            position: "sticky",
                            left: 0,
                            zIndex: 1,
                            backgroundColor: "background.paper",
                          }}
                    >
                        {client}
                    </TableCell>
                    {stats.map((hours, idx) => (
                      <TableCell key={idx} align="center">
                        {hours.toFixed(2)}
                      </TableCell>
                    ))}
                    <TableCell align="center">
                      {stats.reduce((sum, h) => sum + h, 0).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
