import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import {
  useGetAllMedicationsQuery,
  useDeleteMedicationMutation,
} from "../../../redux/apiSlice";
import {
  CircularProgress,
  Box,
  Card,
  CardContent,
  CardHeader,
  Button,
} from "@mui/material";
import AddMed from './AddMed';
import EditMed from './EditMed';
import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import DeleteAlert from "../../../DeleteAlert";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const MedsList = () => {
  const { clientId } = useParams();

  const [showInactive, setShowInactive] = useState(false)

  const { data, isLoading, isSuccess } = useGetAllMedicationsQuery(clientId);
  // console.log("meds: ", data)
  

  const rows = (data || []).filter(row => {
    if (!showInactive) {
      return row.active == true
    } else if (showInactive) {
      return row.name.length > 1
    }
  })

  const handleInactiveVis = () => {
    setShowInactive(prev => !prev)
  }

  const [deleteMed] = useDeleteMedicationMutation();

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
    },
    {
      field: "dosage",
      headerName: "Dosage",
      width: 150,
    },
    {
      field: "frequency",
      headerName: "Frequency",
      width: 150,
    },
    {
        field: "active",
        headerName: "Status",
        width: 200,
        renderCell: (params) => {
          // console.log(params.row)
            if(params.row.active) {
                return (
                    <>
                        <CheckBox /><label>  Active </label>
                    </>
                )
            } else if (!params.row.active) {
                return (
                    <>
                        <CheckBoxOutlineBlank /><label>   Inactive </label>
                    </>
                )
            }
        }
    },
    {
        field: "reason",
        headerName: "Taken For",
        width: 400
    },
    {
        field: 'startDate',
        headerName: 'Start Date',
        type: 'date',
        width: 200,
        valueFormatter: (params) => {
          if(params.value !== null){
            const date = new Date(params.value);
            let options = {
              year: "numeric",
              month: "numeric",
              day: "numeric"
            };
            return date.toLocaleDateString("en-US", options);
          } else if (params.value == null){
            return ''
          }
          
        }
        
      },
      {
        field: 'endDate',
        headerName: 'End Date',
        type: 'date',
        width: 200,
        valueFormatter: (params) => {
          if(params.value !== null){
            const date = new Date(params.value);
            let options = {
              year: "numeric",
              month: "numeric",
              day: "numeric"
            };
            return date.toLocaleDateString("en-US", options);
          } else if (params.value == null){
            return ''
          }}
        
      },
    {
      field: "actions",
      headerName: "",
      width: 80,
      renderCell: (params) => {
        let medsId = params.id;
        // console.log("params.row", params.row)
        let medName = params.row.name
        return (
          <>
            <EditMed medsId={medsId} med={params.row} />
            <DeleteAlert name={`Client Medication-${medName}`} id={medsId} hook={deleteMed} softDelete={false} />
          </>
        );
      },
    },
  ];

  let content;

  if (isLoading) {
    content = <CircularProgress />;
  } else if (isSuccess) {
    content = (
      <div>
        {!showInactive ? (
          <Button startIcon={<VisibilityOffIcon />} color='secondary' variant='outlined' onClick={handleInactiveVis}>
            Show Inactive
          </Button>
        ): (
          <Button startIcon={<VisibilityIcon />} color='secondary' variant='contained' onClick={handleInactiveVis}>
           Hide Inactive
          </Button>
        )}
        <Box sx={{ display: "flex" }}>
          {data && (
            <DataGrid
              autoHeight
              density="compact"
              getRowId={(row) => row._id}
              rows={rows}
              columns={columns}
              rowsPerPageOptions={[20, 50, 100]}
              sx={{ height: "100%", width: "100%" }}
              columnVisibilityModel={{
                type: false,
                method: false,
              }}
            />
          )}
        </Box>
      </div>
    );
  }

  return (
    <div>
      <Box 
        component="span"
        sx={{ display: 'block', mx: '2px', my: '10px', px: '2px', py: '10px', height: '100%', width: '100%' }}
      >
        <Card>
            <CardHeader
                title={'Medication'}
                action={
                    <AddMed />
                }
            />
          <CardContent>
            <div>{content}</div>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default MedsList;
