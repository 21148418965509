import React, { useState } from 'react';
import { useUpdateMedLogMutation } from '../../../redux/apiSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';



const EditMedLog = ({ log, isUser, clientId }) => {

    const [open, setOpen] = useState(false);

    const [editLog] = useUpdateMedLogMutation();

    const handleOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        reset();
        setOpen(false)
    };

    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            med: log.med,
            user: log.user._id,
            dateTime: log.dateTime,
            isDeleted: log.isDeleted
        }
    })

    // console.log("med log values: ", getValues())

    const onSubmit = async (data) => {

        console.log("data: ", data)

        try {
            // const payload =
            await editLog({ data, logId: log._id, isUser, clientId })
            // console.log('fulfilled', payload)
        } catch (err) {
            console.error("Failed to update med log: ", err)
        }

        toast.success(`${data.med} updated`, {
            position: toast.POSITION.TOP_RIGHT
        });
        handleClose();
    }





    return (
        <div>
            <IconButton color='secondary' variant='contained' onClick={handleOpen}>
                <Tooltip title='Edit Log'>
                    <EditIcon  />
                </Tooltip>
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Update {log.med} Log</DialogTitle>
                <DialogContent>
                    <form
                        id="editMedLog"
                        onSubmit={(e) => handleSubmit(onSubmit)(e).catch((e) => {
                            console.log("error", e);
                        })}>
                        <Typography>Medication: {log.med}</Typography><br />
                        <Typography>User: {log.user.fullName}</Typography>
                        <Controller
                            control={control}
                            name="dateTime"
                            render={({ field: { onChange, value } }) => (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DateTimePicker
                                        label=" Date/ Time"
                                        value={value}
                                        onChange={onChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                    </form>

                </DialogContent>
                <DialogActions>
                    <Button color='warning' onClick={handleClose}>Cancel</Button>
                    <Button type='submit' form='editMedLog'>Save</Button>
                </DialogActions>

            </Dialog>
        </div>

    )
}

EditMedLog.propTypes = {
    log: PropTypes.any,
    isUser: PropTypes.any,
    clientId: PropTypes.any
}

export default EditMedLog