import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import {
    CircularProgress,
    Box,
    IconButton,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Collapse,
    Typography,
  } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useGetUploadsByTypeQuery, useDeleteUploadMutation } from '../../../redux/apiSlice';
import PropTypes from 'prop-types';
import SignDoc from './SignDoc';
import ViewDoc from './ViewDoc';
import DeleteAlert from '../../../DeleteAlert';
import { useDispatch } from 'react-redux';
import { setConsentsSigned } from '../../../redux/slices/clientSlice';

  const Row = ({ data, key }) => {
    const [open, setOpen] = useState();
    const [lastSig, setLastSig] = useState();

    const dispatch = useDispatch();
  
    const [deleteUpload] = useDeleteUploadMutation();
    // console.log("data: ", data)
  
    const formatDate = (i) => {
      const date = new Date(i);
      let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      return date.toLocaleDateString("en-US", options);
    };

    useEffect(() => {
        if (data?.signs?.length) {
          const formatted = formatDate(data?.signs[data?.signs?.length -1]?.signDate)
            setLastSig(formatted)
            dispatch(setConsentsSigned(formatted))
        } else {
            setLastSig("")
        }
    }, [data])
    
    // const latestSig = (lastSig) => {
    //     if (lastSig?.length) {
    //         return formatDate(lastSig)
    //     } else {
    //         return ""
    //     }
    // }
    
    const title = data?.title
    const name = data?.name
    const uploadId = data?._id
    // console.log("consents row data: ", data)
  
    return (
      <React.Fragment key={key}>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell align="left">{title}</TableCell>
          <TableCell align="left">{formatDate(data?.uploadDate)}</TableCell>
          <TableCell align="left">{lastSig}</TableCell>
          
          <TableCell align="left" display="inline">
            <span style={{justifyContent:'center'}}>
                <ViewDoc doc={data?.file} title={title} name={name} />
                <SignDoc docId={uploadId} title={title} name={name} />
                <DeleteAlert name={`Client Doc-${title}`} id={uploadId} hook={deleteUpload} softDelete={false} />
            </span>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Signature History
                </Typography>
                {data?.signs?.length &&
                    data.signs.map((sig, idx) => (
                        <><img key={idx} src={sig.signature} style={{ width: '200px', height: '200px'}}/><label>{new Date(sig.signDate).toLocaleDateString()}</label></>
                    ))
                }
                
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };
  
  Row.propTypes = {
    data: PropTypes.any,
    planNumber:PropTypes.any,
    key: PropTypes.any
  };
  
  const ConsentDocs = () => {
    const { clientId } = useParams();

    const type = 'consent form'
  
    const { data, isLoading, isSuccess } = useGetUploadsByTypeQuery({clientId, type})    

    let content;
  
    if (isLoading) {
      content = <CircularProgress />;
    } else if (isSuccess) {
      content = (
        <div>
          <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="Client Reports List">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Title</TableCell>
                    <TableCell>Upload Date</TableCell>
                    <TableCell>Last Signed</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data && data?.map((i, idx) => <Row key={idx} data={i}/>)}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      );
    }
  
    return (
      <div>
        <div>{content}</div>
      </div>
    );
  };
export default ConsentDocs