import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useGetTeamMembersQuery, useRemoveUserFromTeamMutation } from '../../redux/apiSlice';
import { Link } from 'react-router-dom';
import { CircularProgress, Box, IconButton, Tooltip } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../utilities/useAuth';

const SupervisorUsers = ({superId}) => {

  const {id} = useAuth()


  console.log("superId: ", superId)

  const supId = superId || id;

const { data, isLoading, isSuccess} = useGetTeamMembersQuery(supId, {skip: !supId})

const [removeUser] = useRemoveUserFromTeamMutation()


const columns = 

   [
  {
    field: '_id', 
    headerName: 'Profile', 
    width: 80,
    renderCell: (params) => (
      
      <Link to={{
        pathname:`/UserProfile/${params.value}`,
        state: { userId: params.value }
      }} ><AccountCircleIcon /></Link>
    )
    
  },
  {field: 'lastName', headerName: 'Last Name', width: 150},
    
  
  {field: 'firstName', headerName: 'First Name', width: 150},
  {field: 'phone', headerName: 'Phone', width: 150},
  {field: 'email', headerName: 'Email', width: 150},
  {field: 'username', headerName: 'Username', width: 150},
  {
    field: "actions",
    headerName: "",
    width: 120,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      const userId = params.row._id
      // console.log("params.value", userId)
        return (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <IconButton onClick={() => removeUser({superId, userId})}>
              <Tooltip title='Remove User'>
                <DeleteIcon color="error" />
              </Tooltip>
            </IconButton>
          </Box>
        )
    }
  }
  
   ];

   let content

if (isLoading) {
  content = <CircularProgress />
} else if (isSuccess) {
  content = 
  <div style={{ display: 'flex' }}>
    <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
  { data &&
    <DataGrid 
      autoHeight
      autoWidth
      getRowId={(row) => row._id}
      rows={data}
      columns={columns}
      rowsPerPageOptions={[20, 50, 100]}
      //autoPageSize
      sx={{ height: '100%', width: '100%' }}
  
 />}
 </Box>
  </div>

}



  return (
    <div>
      <h1>Assigned Users</h1>
    
      <div>
        {content}
      </div>
      
      
    </div>
  )
}

SupervisorUsers.propTypes = {
    superId: PropTypes.any
}

export default SupervisorUsers;