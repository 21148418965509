import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import { useGetUsersByClientIdQuery, useRemoveClientFromUserMutation } from '../../../redux/apiSlice'



const ClientUsers = ({ clientId }) => {


  const { data, isLoading, isSuccess } = useGetUsersByClientIdQuery(clientId)
  const [removeClient] = useRemoveClientFromUserMutation()

  const columns =

    [
      {
        field: '_id',
        headerName: 'User Profile',
        width: 80,
        renderCell: (params) => {
          return (
            <Link style={{ color: 'blue' }} to={`/UserProfile/${params.value}`}><AccountCircleIcon /></Link>
          )
        }
      },
      { field: 'name', headerName: 'Name', width: 150 },
      { field: 'phone', headerName: 'Phone', width: 150 },
      { field: 'email', headerName: 'Email', width: 150 },
      { field: 'role', headerName: 'Role', width: 150 },
      {
        field: 'users.primary', headerName: 'Primary', width: 120, renderCell: (params) => {
          // console.log(params)
          if (params.row.primary === true) {
            return (
              <CheckBoxIcon />
            )
          } else {
            <CheckBoxOutlineBlankIcon />
          }
        }
      },
      {
        field: "actions",
        headerName: "",
        width: 120,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const userId = params.row._id
          return (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <IconButton onClick={() => removeClient({ userId, clientId })}>
                <DeleteIcon color="error" />
              </IconButton>
            </Box>
          )

        }
      }
    ];


  let content

  if (isLoading) {
    content = <CircularProgress />
  } else if (isSuccess) {
    content =
      <div style={{ display: 'flex' }}>
        <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
          {data &&
            <DataGrid
              autoHeight
              autoWidth
              getRowId={(row) => row._id}
              rows={data}
              columns={columns}
              //autoPageSize
              sx={{ height: '100%', width: '100%' }}
              rowsPerPageOptions={[20, 50, 100]}

            />}
        </Box>
      </div>

  }


  return (
    <div>
      {content}
    </div>
  )
}

ClientUsers.propTypes = {
  clientId: PropTypes.any,
}

export default ClientUsers;