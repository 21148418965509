import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './apiSlice';
import { authSlice } from './slices/authSlice';
import { userSlice } from './slices/userSlice';
import { clientSlice } from './slices/clientSlice';
import { timechartSlice } from './slices/timechartSlice';
import { stepperSlice } from './slices/stepperSlice';
import { visitSlice } from './slices/visitSlice';
import { adminSlice } from './slices/adminSlice';
import { formSlice } from './slices/formSlice';
import { settingsSlice } from './slices/settingsSlice';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        user: userSlice.reducer,
        client: clientSlice.reducer,
        timechart: timechartSlice.reducer,
        stepper: stepperSlice.reducer,
        visit: visitSlice.reducer,
        settings: settingsSlice.reducer,
        admin: adminSlice.reducer,
        forms: formSlice.reducer,
        [apiSlice.reducerPath]: apiSlice.reducer
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(apiSlice.middleware)
});

export default store;

export const AppDispatch = store.dispatch;
export const RootState = store.getState;
setupListeners(store.dispatch);