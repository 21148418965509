import React from 'react';
import SupervisorUsers from './SupervisorUsers';
import AssignTeam from './AssignTeam';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader } from '@mui/material';

export const SupervisorTab = ({supervisor}) => {
const superId = supervisor._id

console.log("sup tab id: ", superId)

  return (
    <>
    <Card sx={{ display: "inline-flex", flexDirection: 'column', border: 1, fontSize: 12 }} >
        <CardHeader
            title={supervisor.fullName}
            action={
                <AssignTeam superId={superId} />
            }
            sx={{ backgroundColor: 'primary'}}
        />
        <CardContent>
            <SupervisorUsers superId={superId} />
        </CardContent>
    
    </Card>
    
    </>
  )
}

SupervisorTab.propTypes = {
    supervisor: PropTypes.any
}
