import React from 'react';
import { Paper, Typography } from '@mui/material'
import GoalAttainReport from './GoalAttainReport';
import FullAttendance from './FullAttendance';



export default function Reports() {
	return(
	<div>
	<Typography variant='h3'>Reports</Typography>
	<Paper>
		<Typography variant='h5'>Attendance Report</Typography>
		<FullAttendance />
		<br />
		<Typography variant='h5'>Goal Attainment</Typography>
		<GoalAttainReport />
	</Paper>
	</div>
	)
}