import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import Reenroll from './Reenroll';
import Unenroll from './Unenroll';
import PropTypes from 'prop-types';

const ManageStatus = ({info, clientId}) => {

    const [open, setOpen] = useState(false)
    const [status, setStatus] = useState()
    const [isActive, setIsActive] = useState(true)
    const [currentStart, setCurrentStart] = useState()
    const [enrollId, setEnrollId] = useState()
    // console.log("currentStart: ", currentStart)
    // console.log("isActive: ", isActive)

    const active = info?.enrollment?.enrolled

    const enrollHistory = info?.enrollment?.enrollDates
    // console.log(enrollHistory)
    // console.log("status: ", info?.enrollment)

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };


    useEffect(() => {
        if(active === true) {
            setStatus("Active")
            let array = info?.enrollment?.enrollDates
            let current = array[array.length -1]
            setEnrollId(current._id)
            setCurrentStart(current.begin)
            setIsActive(true)
            
        } else {
            setStatus("Inactive")
            setIsActive(false)
        }
    }, [active])

  return (
    <div>
        {isActive ? (
            <Button color='success' variant='contained' onClick={handleClickOpen}><CheckBox />Active</Button>
        ) : (
            <Button color='warning' variant='contained' onClick={handleClickOpen}><CheckBoxOutlineBlank />Inactive</Button>
        )}
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Manage Client Enrollment</DialogTitle>
            <DialogContent>
            <Typography>Status: {status}</Typography><br />
                { isActive && <Typography>Active since: {new Date(currentStart).toLocaleDateString()}</Typography>
                }
                <Typography>Enrollment History:</Typography>
                {enrollHistory && enrollHistory.map((i, idx) => (
                    <React.Fragment key={idx}>
                        <ol key={idx}>
                            <li>Enrolled: {new Date(i.begin).toLocaleDateString()}</li>
                           {i.end ? (
                            <>
                            <li>Unenrolled: {i.end && new Date(i.end).toLocaleDateString()}</li>
                            <li>Over 90 Days: {i.over90 ? ('Yes'): ('No')}</li>
                            </>
                           ) : (
                            <></>
                           )
                           } <br />
                        </ol>
                    </React.Fragment>
                ))}
                 <br />
                {isActive ? (
                    <Unenroll clientId={clientId} info={info} currentStart={currentStart} enrollId={enrollId} />
                ) : (
                    <Reenroll clientId={clientId} info={info} />
                )}
            </DialogContent>
        </Dialog>
        
       
    </div>
  )
}

ManageStatus.propTypes = {
    info: PropTypes.any,
    clientId: PropTypes.any
}

export default ManageStatus