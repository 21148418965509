import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSelector } from 'react-redux';
import { useEditReportDetailsMutation } from '../../../redux/apiSlice';
import { useAuth } from '../../../utilities/useAuth';


const EditAddtlDetails = ({ planId, details }) => {
    const {id: isUser} = useAuth();

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        reset();
        setOpen(false);
    };

    const consentsSigned = useSelector(state => state.client.consentsSigned)

    const [editDetails] = useEditReportDetailsMutation();


    const { control, reset, handleSubmit } = useForm({
        defaultValues: {
            lastMedical: details?.lastMedical,
            lastDental: details?.lastDental,
            formsSigned: details?.formsSigned
        }
    });

    useEffect(() => {
        if(details) {
            reset({
                lastMedical: details?.lastMedical,
                lastDental: details?.lastDental,
                formsSigned: details?.formsSigned
            })
        }
    }, [details, reset])


    const onSubmit = async (data) => {
        // console.log("payload", data)

        try {
            // const payload = 
            await editDetails({ planId, data, isUser }).unwrap();
            // console.log('fulfilled', payload)
        } catch (err) {
            console.error('Failed to save note: ', err)
            toast.error("Error Adding Note", {
                position: toast.POSITION.TOP_CENTER
            });
        }

        toast.success("Note Added", {
            position: toast.POSITION.TOP_RIGHT
        });
        handleClose();
    };

    return (
        <div>
            <Button startIcon={<EditIcon />} color="success" variant="contained" onClick={handleClickOpen}>
                Edit Additional Details
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Additional Details</DialogTitle>
                <DialogContent>
                    <form
                        id="editDetails"
                        onSubmit={(e) =>
                            handleSubmit(
                                onSubmit,
                            )(e).catch((e) => {
                                console.log("e", e);
                            })
                        }
                    >
                        <Typography>Last Medical Appointment:</Typography><br/>
                        <Controller
                            name='lastMedical'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Last Medical"
                                        value={value}
                                        onChange={(e) => onChange(e)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                        <br />
                        <Typography>Last Dental Appointment:</Typography><br />
                        <Controller
                            name='lastDental'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Last Dental"
                                        value={value}
                                        onChange={(e) => onChange(e)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                        <br />
                        <Typography>Consent Forms Reviewed On:</Typography>
                        <Controller
                            name='formsSigned'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Consetn Forms Signed On"
                                        value={value}
                                        onChange={(e) => onChange(e)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                        <Typography>For Reference- The last recorded signature date in the system is: {consentsSigned}</Typography>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='warning'>Cancel</Button>
                    <Button type='submit' form="editDetails">Submit</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

EditAddtlDetails.propTypes = {
    planId: PropTypes.any,
    details: PropTypes.any
}

export default EditAddtlDetails