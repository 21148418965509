import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useGetUserScheduleQuery } from '../../redux/apiSlice';
import ScheduleVisit from '../visitviews/InScheduleVisit';
import ViewVisit from '../visitviews/ViewVisit';
import { setOpenState, setScheduleOpenState } from '../../redux/slices/visitSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import PrintSchedule from './PrintSchedule';
import './Schedule.css';
import { useGetUserName } from '../../utilities/useGetUserName';

export default function Schedule ({userId}) {

    const calendarRef = useRef()
    const dispatch = useDispatch()
    const userName = useGetUserName(userId)

    const isOpen = useSelector(state => state.visit.openModal)
    const schedulerOpen = useSelector(state => state.visit.openScheduler)

    function getFirstOfMonth() {
        const now = new Date();
        const firstDate = new Date(now.getFullYear(), now.getMonth(), 1); 
        return firstDate.toISOString(); 
      }

    const [currentRange, setCurrentRange] = useState({
        start: getFirstOfMonth(),
        end: new Date().toISOString(),
      });
    

    const [visitId, setVisitId] = useState()
    const [eventDetails, setEventDetails] = useState()
    const [hasDate, setHasDate] = useState()

    

    const { data: visits = [], refetch} = useGetUserScheduleQuery({userId, isStart: currentRange.start, isEnd: currentRange.end}, { skip: !currentRange.start })

    useEffect(() => {
        if (userId) {
            refetch()
        }
    }, [userId, refetch])

    const handleDateChange = (dateInfo) => {
        const { start, end } = dateInfo;
        setCurrentRange({ start: start.toISOString(), end: end.toISOString() });
      };

    //   console.log("current range: ", currentRange.start, currentRange.end)
    
      useEffect(() => {
        // Refetch events when currentRange changes
        if (currentRange.start && currentRange.end) {
            // console.log("range changed")
          refetch();
        }
      }, [currentRange, refetch]);


      const handleDateClick = (arg) => {
        console.log("1: Original dateStr: ", arg.dateStr);
    
        // Normalize arg.dateStr to an ISO string
        let normalizedDate = arg.dateStr.includes('T') 
            ? new Date(arg.dateStr) // Already an ISO string
            : new Date(`${arg.dateStr}T00:00:00`); // Append time for plain date strings
    
        console.log("2: Normalized Date: ", normalizedDate);
    
        // Create a new Date object with the current time added to normalizedDate
        let currentTime = new Date();
        normalizedDate.setHours(currentTime.getHours());
        normalizedDate.setMinutes(currentTime.getMinutes());
        normalizedDate.setSeconds(0);
    
        console.log("3: Adjusted Date with Current Time: ", normalizedDate);
    
        // Convert to ISO string for consistency
        let adjust = normalizedDate.toISOString();
        console.log("4: Adjusted ISO String: ", adjust);
    
        setHasDate(adjust);
    
        let payloadObj = {
            open: true,
            time: adjust
        };
        dispatch(setScheduleOpenState(payloadObj));
    };
    

    // const handleDateClick = (arg) => {
    //     console.log("1: ", arg.dateStr)

    //     // eslint-disable-next-line
    //     let dateNoTime = new Date(arg.dateStr.replace(/-/g, '\/'))
    //     console.log("2: ", dateNoTime)

    //     let dateTime = new Date();
    //     dateNoTime.setHours(dateTime.getHours());
    //     dateNoTime.setMinutes(dateTime.getMinutes());
    //     dateNoTime.setSeconds(0);

    //     console.log("3: ", dateNoTime)
    //     let adjust = new Date(dateNoTime).toISOString()
    //     // console.log("4: ", adjust)
    //     setHasDate(adjust)
    //     let payloadObj = {
    //         open: true,
    //         time: adjust
    //     }
    //     dispatch(setScheduleOpenState(payloadObj))
        
    // }

    const handleEventClick = (arg) => {
    
        const visitId = arg.event.extendedProps.visitId
        // console.log("visitId: ", visitId, "event: ", arg.event.extendedProps)
        setVisitId(visitId)
        setEventDetails(arg.event.extendedProps) 
        dispatch(setOpenState(true))
    }
    // console.log("hasDate: ", hasDate)
        
    const singleEvent = 
            <div>
                <ViewVisit 
                    event={eventDetails} 
                    isOpen={isOpen}
                    visitId={visitId}
                />
            </div>

    const dateSchedule = 
            <div>
                <ScheduleVisit
                    isOpen={schedulerOpen}
                    hasDate={hasDate}
                />
            </div>
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const [initial, setInitial] = useState(false);

    useEffect(() => {
        if (isMobile){
            setInitial("timeGridDay")
        } else if (isTablet) {
            setInitial("timeGridWeek")
        } else {
            setInitial("dayGridMonth")
        }
    }, [isMobile, isTablet])

    // console.log("initial: ", initial)
    // console.log("isMobil, tablet", isMobile, isTablet)

    if (!initial) {
        return null; // Show nothing or a loader until the initial view is set
    }

    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: isMobile ? 'flex-start' : 'center',
            padding: 2,
            borderBottom: 1,
            borderColor: 'divider',
          }}
        >
          <ScheduleVisit />
          <PrintSchedule calendarRef={calendarRef} events={visits} userName={userName} />
        </Box>
        <Box
          ref={calendarRef}
          sx={{
            flexGrow: 1,
            // height: 'calc(100vh - 64px)', // Adjust for header height
            width: '100%',
            padding: isMobile ? 1 : 2,
            overflow: 'hidden', // Prevent scrollbars
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <FullCalendar
            key={initial}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView={initial}
            headerToolbar={{
              left: isMobile ? 'prev,next' : 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            editable={true}
            events={visits}
            datesSet={handleDateChange}
            dateClick={handleDateClick}
            eventClick={handleEventClick}
            height="100%" // Ensures the calendar fills the container
            aspectRatio={isMobile ? 0.8 : isTablet ? 1.2 : 1.35}
            contentHeight="auto"
          />
          {singleEvent}
          {dateSchedule}
        </Box>
      </>
    );
    
      
}

Schedule.propTypes = {
    userId: PropTypes.any
}