import React from 'react';
import { Card, CardContent, Grid, Typography, Stack, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useGetUserName } from '../../../utilities/useGetUserName';
import PropTypes from 'prop-types';

const StaffNoteCard = ({ note, handleDelete }) => {
    const userName = useGetUserName(note.user);

    return (
        <Grid item xs={12}>
            <Card sx={{ minHeight: '50px' }}>
                <CardContent sx={{ maxWidth: '100%', minHeight: '50px' }}>
                    <Grid container alignItems="center" sx={{ minHeight: '50px' }}>
                        <Grid item xs={1} sx={{ minHeight: '50px' }}>
                            <Stack direction="column">
                                <Typography variant="subtitle2" color="textSecondary">
                                    {new Date(note.date).toLocaleDateString()}
                                </Typography>
                                <Typography variant="subtitle2" color="textSecondary">
                                    {userName || "No User Set"}
                                </Typography>
                                <IconButton onClick={() => handleDelete(note._id)}>
                                    <DeleteIcon color="error" />
                                </IconButton>
                            </Stack>
                        </Grid>
                        <Grid item xs={11} sx={{ minHeight: '50px' }}>
                            <Typography variant="body1">{note.note}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

StaffNoteCard.propTypes = {
    note: PropTypes.any,
    handleDelete: PropTypes.any
}

export default StaffNoteCard;
