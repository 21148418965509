import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useSendLogoutMutation } from './redux/apiSlice';
import Clock from './views/userviews/clockviews/Clock';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { Box, IconButton, Menu, MenuItem, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './utilities/useAuth';
import ViewAs from './ViewAs';


const MainMenu = () => {

  const {admin, supAdmin, supervisor} = useAuth();

  let viewSelect = false

  if (admin || supAdmin || supervisor) {
      viewSelect = true
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate()

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


    const [logOut, {isSuccess, isError, error}] = useSendLogoutMutation()
		

	const logoutHandler = async () => {
        handleMenuClose();
		await logOut()
    navigate("/login");
		if (isSuccess){
      navigate("/login");
		} else if (isError) {
			toast.error(error.data?.message)
		}
	}

    return (
        <Box>
            <IconButton aria-label='Menu' onClick={handleMenuClick}>
                <WorkspacesIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem>
                    <Clock handleMenuClose={handleMenuClose}  />
                </MenuItem>
                {viewSelect && 
                    <MenuItem>
                        <ViewAs handleMenuClose={handleMenuClose} />
                    </MenuItem>
                }
                <MenuItem>
                    <Button color='secondary' variant='contained' onClick={logoutHandler}>Logout</Button>
                </MenuItem>
            </Menu>
        </Box>
    )
}

export default MainMenu;