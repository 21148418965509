import React from 'react';
import { useForm } from 'react-hook-form';
import { useYupValidationResolver } from '../../../utilities/useYupValidationResolver';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useEditGoalMutation } from '../../../redux/apiSlice'
import { DialogTitle, Dialog, DialogContent, DialogActions, IconButton, Button, TextField, Grid } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from 'prop-types';
import { useAuth } from '../../../utilities/useAuth';
// import { useDispatch, useSelector } from 'react-redux';
// import { resetGoalData, setGoalData, updateGoalData } from '../../../redux/slices/formSlice';


const validationSchema = yup.object({
  title: yup.string("Title for Goal").required('Required'),
  dataCollection: yup.string("How is data collected?"),
  staffRole: yup.string("The role of the staff"),
  frequency: yup.string("How many times per month will the goal be addressed??").required('Required'),
  location: yup.string("What setting is this goal addressed in?"),
  projectedHours: yup.string("How many hours per month are anticipated for this goal?"),
  materials: yup.string("What materials are needed towards goal completion?"),
  currentStatus: yup.string("What is currently happening towards this goal? Progress? Barriers?").required('Required'),
  quote: yup.string("Client's statement as to benefit of the goal"),
  measurement: yup.string("Statement of how the goal is measured and for how long"),
  plan: yup.string("Steps of staff towards goal completion")
});

//onDialogOpen

const EditGoal = ({planId, goal }) => {
  const {id} = useAuth();
  const isUser = id;

  // const dispatch = useDispatch()
  // const hasGoal = useSelector(state => state.forms.goal)
  
  const [editGoal] = useEditGoalMutation()

  const goalId = goal._id
  const [open, setOpen] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };


  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
      return;
    reset();
    // dispatch(resetGoalData());
    setOpen(false);
  };

  function addLineBreaks(input) {
    // Regular expression to find numbered list items
    const regex = /(\d+\. )/g;

    // Replace matched patterns with a line break before each match
    return input.replace(regex, (match, p1, offset) => {
        return offset === 0 ? match : '\n' + match;
    });
}

const newPlan = addLineBreaks(goal?.plan)


  const resolver = useYupValidationResolver(validationSchema)

  const {register, handleSubmit, formState: {errors}, reset} = useForm({
    resolver,
    defaultValues: {
      title: goal?.title,
      dataCollection: goal?.dataCollection,
      staffRole: goal?.staffRole,
      frequency: goal?.frequency,
      location: goal?.location,
      projectedHours: goal?.projectedHours,
      materials: goal?.materials,
      currentStatus: goal?.currentStatus,
      quote: goal?.quote,
      measurement: goal?.measurement,
      plan: newPlan
    }
  });

  // useEffect(() => {
  //   if(open && hasGoal)
  //   reset(hasGoal);
  // }, [hasGoal, reset, open]);

  
  // const watched = watch();
  // useEffect(() => {
  //   dispatch(updateGoalData(watched));
  // }, [watched, dispatch]);
  
  const onSubmit = async (data) => {
    try {
      await editGoal({ planId, goalId, data, isUser })
      // .unwrap()
      // .then((payload) => console.log('fulfilled', payload))
      // .catch((error) => console.error('rejected', error.status, error.data));
        
      } catch (err) {
        console.error('Failed to save goal: ', err)
        toast.error("Error Adding Goal", {
          position: toast.POSITION.TOP_CENTER
        });
      }

      toast.success("Goal Updated", {
        position: toast.POSITION.TOP_RIGHT
      });
      handleClose();
  }
   
  return (
  <div>
    <IconButton color='secondary' onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
  <DialogTitle>Edit Goal</DialogTitle>
  <DialogContent style={{ height: '600px', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
    <form
      id="editGoal"
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      onSubmit={(e) =>
        handleSubmit(onSubmit)(e).catch((e) => {
          console.log("e", e);
        })
      }
    >
      <br /><br />
      <Grid container spacing={2} style={{ height: '100%', flex: 1, marginTop: '16px' }}>
        {/* Column 1 */}
        <Grid item xs={12} sm={4} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <TextField
            name="title"
            label="Title/ Name of Goal"
            fullWidth
            {...register('title')}
          />
          {errors.title && <p style={{ color: 'red' }}>Goal needs a title</p>}

          <TextField
            name="dataCollection"
            label="Data Collection"
            fullWidth
            {...register('dataCollection')}
          />

          <TextField
            name="staffRole"
            label="Staff Role"
            fullWidth
            {...register('staffRole')}
          />

          <TextField
            name="frequency"
            label="Times per Month"
            fullWidth
            {...register('frequency')}
          />
          {errors.frequency && (
            <p style={{ color: 'red' }}>Frequency is required for the system to track progress</p>
          )}

          <TextField
            name="location"
            label="Location"
            fullWidth
            {...register('location')}
          />

          <TextField
            name="projectedHours"
            label="Projected Hours"
            fullWidth
            {...register('projectedHours')}
          />

          <TextField
            name="materials"
            label="Materials Used"
            fullWidth
            {...register('materials')}
          />
        </Grid>

        {/* Column 2 */}
        <Grid item xs={12} sm={4} >
          <TextField
            name="currentStatus"
            label="Current Status"
            multiline
            fullWidth
            rows={10} // rows initially define height
            style={{ flexGrow: 1 }} // ensure it fills the available remaining space 
            {...register('currentStatus')}
          />
          {errors.currentStatus && <p style={{ color: 'red' }}>Status is required</p>}
        </Grid>

        {/* Column 3 */}
        <Grid item xs={12} sm={4} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <TextField
            name="quote"
            label="Client Statement"
            fullWidth
            {...register('quote')}
          />

          <TextField
            name="measurement"
            label="Statement of Measurement"
            multiline
            rows={4}
            fullWidth
            {...register('measurement')}
          />
          {errors.measurement && <p style={{ color: 'red' }}>Measurement is required</p>}

          <TextField
            name="plan"
            label="Plan"
            multiline
            fullWidth
            rows={4} 
            {...register('plan')}
            sx={{
              '& .MuiInputBase-input': {
                  lineHeight: '1.5', // Adjust line height
              },
              flex: 1
          }}
          />
        </Grid>
      </Grid>

      <DialogActions>
        <Button color="warning" onClick={handleClose}>
          Cancel
        </Button>
        <Button form="editGoal" type="submit">
          Submit
        </Button>
      </DialogActions>
    </form>
  </DialogContent>
</Dialog>


    </div>
  );
};

EditGoal.propTypes = {
    planId: PropTypes.any,
    goal: PropTypes.any,
    onDialogOpen: PropTypes.any
}

export default EditGoal;
