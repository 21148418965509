import React, { useState, useEffect } from 'react';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../utilities/useAuth';
import { useEditClientMutation } from '../../../redux/apiSlice';
import PropTypes from 'prop-types';
import { PhoneFormat } from '../../../utilities/PhoneFormat';
import { toast } from 'react-toastify';

const EditClientSupports = ({ clientId, supports, }) => {
    const { id: isUser } = useAuth();

    const [editSupports] = useEditClientMutation()

    const [open, setOpen] = useState(false);
    const [hasIHSS, setHasIHSS] = useState(supports?.ihss?.hasIhss)
    const [hasConserve, setHasConserve] = useState(supports?.conserve?.conserved)
    const [repPhone, setRepPhone] = useState()

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        reset();
        setOpen(false);
    };

    const { register, handleSubmit, reset, watch, setValue } = useForm({
        defaultValues: {
            type: "supports",
            values: {
                ihss: {
                    hasIhss: supports?.ihss?.hasIhss,
                    provider: supports?.ihss?.provider,
                    hours: supports?.ihss?.hours,
                    services: supports?.ihss?.services,
                    countyRep: supports?.ihss?.countyRep,
                    countyRepPhone: supports?.ihss?.countyRepPhone,
                    countyRepEmail: supports?.ihss?.countyRepEmail
                },
                conserve: {
                    conserved: supports?.conserve?.conserved,
                    conservator: supports?.conserve?.conservator,
                    details: supports?.conserve?.details
                }

            }
        }
    })

    // console.log("values: ", getValues())

    const watchPhone = watch('values.ihss.countyRepPhone')

    useEffect(() => {
        if (watchPhone) {
            let format = PhoneFormat(watchPhone)
            setRepPhone(format)
        }
    }, [watchPhone])

    const onSubmit = async (data) => {

        // console.log(data);

        try {
            // const payload =  
            await editSupports({ clientId, data, isUser }).unwrap();
            // console.log('fulfilled', payload)
        } catch (err) {
            console.error('Failed to update personal info: ', err)
            toast.error("Error Updating Personal Info", {
                position: toast.POSITION.TOP_CENTER
            });
        }

        toast.success("Personal Info Updated", {
            position: toast.POSITION.TOP_RIGHT
        });
        handleClose();
    }

    const handleIHSSChange = () => {
        setValue('values.ihss.hasIhss', !hasIHSS);
        setHasIHSS(!hasIHSS);
    }

    const handleConserveChange = () => {
        setValue('values.conserve.conserved', !hasConserve);
        setHasConserve(!hasConserve);

    }



    return (
        <div>
            <IconButton onClick={handleClickOpen}>
                <EditIcon color='secondary' />
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Client Supports</DialogTitle>
                <DialogContent>
                    <br />
                    <form id="editSupports"
                        onSubmit={(e) =>
                            handleSubmit(onSubmit)(e).catch((e) => {
                                console.log("e", e);
                            })}>
                        {/* IHSS Section */}
                        <Typography variant="h6" gutterBottom>
                            IHSS Details
                        </Typography>
                        <Box display="flex" alignItems="center" gap={1} mb={2}>
                            <Typography>Client Receives IHSS?</Typography>
                            <Checkbox
                                checked={hasIHSS}
                                onChange={handleIHSSChange}
                                value={hasIHSS}
                            />
                        </Box>
                        {hasIHSS && (
                            <Box component="div" mb={3}>
                                <Box display="flex" alignItems="center" gap={2} mb={2}>
                                    <TextField
                                        {...register('values.ihss.provider')}
                                        label="Provider"
                                        size="small"
                                        style={{ width: '45%' }}
                                    />
                                    <TextField
                                        {...register('values.ihss.hours')}
                                        label="Hours"
                                        size="small"
                                        type="number"
                                        style={{ width: '45%' }}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                        {...register('values.ihss.services')}
                                        label="Services Provided"
                                        fullWidth
                                        size="small"
                                    />
                                </Box>
                                <Box display="flex" alignItems="center" gap={2} mb={2}>
                                    <TextField
                                        {...register('values.ihss.countyRep')}
                                        label="County Worker"
                                        size="small"
                                        style={{ width: '45%' }}
                                    />
                                    <TextField
                                        {...register('values.ihss.countyRepPhone')}
                                        label="Phone"
                                        size="small"
                                        value={repPhone}
                                        placeholder="(555) 555-5555"
                                        style={{ width: '45%' }}
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        {...register('values.ihss.countyRepEmail')}
                                        label="Email"
                                        fullWidth
                                        size="small"
                                    />
                                </Box>
                            </Box>
                        )}

                        {/* Conservatorship Section */}
                        <Typography variant="h6" gutterBottom>
                            Conservatorship Details
                        </Typography>
                        <Box display="flex" alignItems="center" gap={1} mb={2}>
                            <Typography>Is Client Conserved?</Typography>
                            <Checkbox
                                checked={hasConserve}
                                onChange={handleConserveChange}
                                value={hasConserve}
                            />
                        </Box>
                        {hasConserve && (
                            <Box component="div" mb={3}>
                                <Box mb={2}>
                                    <TextField
                                        {...register('values.conserve.conservator')}
                                        label="Conservator"
                                        fullWidth
                                        size="small"
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        {...register('values.conserve.details')}
                                        label="Areas of Conservatorship"
                                        fullWidth
                                        size="small"
                                    />
                                </Box>
                            </Box>
                        )}


                    </form>

                </DialogContent>
                <DialogActions>
                    <Button color="warning" onClick={handleClose}>Cancel</Button>
                    <Button form="editSupports" type="submit">Submit</Button>
                </DialogActions>
            </Dialog>
        </div>
    );

};

EditClientSupports.propTypes = {
    clientId: PropTypes.any,
    supports: PropTypes.any
}

export default EditClientSupports;