import { createSlice } from '@reduxjs/toolkit'

export const visitSlice = createSlice({
    name: 'visit',
    initialState: {
        visit: {
            client: '',
            location: '',
            visitStart: '',
            visitEnd: '',
            goals: [],
            cancelNote: '',
            status: '',
            clientSig: {}
        },
        openModal: false,
        openScheduler: false,
        schedulerTime: ''
        // trigger: false
    },
    reducers: {
        resetVisit(state) {
            state.visit.client = '',
            state.visit.location = '',
            state.visit.visitStart = '',
            state.visit.visitEnd = '',
            state.visit.goals = [],
            state.visit.status = '',
            state.visit.cancelNote = '',
            state.visit.clientSig = {}
        },
        setOpenState(state, action){
            state.openModal = action.payload
            // state.trigger = true
        },
        setScheduleOpenState(state, action) {
            state.openScheduler = action.payload.open
            state.schedulerTime = action.payload.time
        },
        // setScheduleTimeState(state, action){
        //     state.schedulerTime = action.payload
        // },
        setVisitDetails(state, action) {
            // state.visit.client = action.payload.client
            // // state.user = action.payload.user
            // state.visit.location = action.payload.location
            // state.visit.visitStart = action.payload.visitStart
            // state.visit.visitEnd = action.payload.visitEnd
            // state.visit.status = action.payload.status
            // state.visit.cancelNote = action.payload.cancelNote
            // state.visit.clientSig = action.payload.clientSig

            state.visit = action.payload
        },
        updateVisitState (state, action) {
            const { visitStart, visitEnd, status, cancelNote, location } = action.payload;
  
            if (visitStart !== undefined) state.visit.visitStart = visitStart;
            if (visitEnd !== undefined) state.visit.visitEnd = visitEnd;
            if (location !== undefined) state.visit.location = location;
            if (status !== undefined) state.visit.status = status;
            if (cancelNote !== undefined) state.visit.cancelNote = cancelNote;

            // Object.keys(action.payload).forEach(key => {
            //     if (action.payload[key] !== undefined) {
            //       state.visit[key] = action.payload[key];
            //     }
            //   });
        },
        updateGoal(state, action) {
            const { index, goal } = action.payload;

            if (state.visit.goals[index]) {
                state.visit.goals[index] = { ...state.visit.goals[index], ...goal };
            }
        },
        addGoal(state, action) {
            const newGoal = {
                title: action.payload.title,
                marked: true,
                note: action.payload.note
            }
            state.visit.goals.push(newGoal); 
        },
        setVisitGoals(state, action) {
            state.visit.goals = [...action.payload]
        },
        setMarkedGoals(state, action) {
            state.visit.goals = state.goals.push(action.payload)
        },
        // setVisitforSig(state, action) {
        //     state.visit = action.payload.data

        // }
        
    }
    
})

export const { resetVisit, resetOpenState, setOpenState, setVisitDetails, updateVisitState, updateGoal, addGoal, setVisitGoals, setMarkedGoals, setScheduleOpenState  } = visitSlice.actions

export default visitSlice.reducer

