import React, {useEffect, useRef, useState} from 'react'
import { Dialog, DialogActions, DialogContent, Button } from '@mui/material';
import TimeSummary from './TimeSummary';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { useGetUserName } from '../../utilities/useGetUserName';
import UserSignTimecard from './UserSignTimecard';
import { useSubmitTimecardMutation } from '../../redux/apiSlice';
import { useDispatch, useSelector } from 'react-redux';
import html2canvas from "html2canvas";
import ReactToPrint from 'react-to-print';
import PropTypes from 'prop-types';
import '../../utilities/printStyleSheet.css'
import AddTimecardNotes from './AddTimecardNotes';
import { clearTimecardState } from '../../redux/slices/userSlice';

const SummaryContainer = ({data, userId, dateRange, totals}) => {
    // console.log("container data: ", data)
    const printRef = useRef();

    const dispatch = useDispatch()

    const usersName = useGetUserName(userId)
    const [submitTimecard] = useSubmitTimecardMutation()

    const [open, setOpen] = useState(false);
    const [trigger, setTrigger] = useState(false);
    // const [visTotal, setVisTotal] = useState();
    // const [entTotal, setEntTotal] = useState();
    // const [mileTotal, setMileTotal] = useState();
    const [submitNotes, setSubmitNotes] = useState('');

    const hasSig = useSelector(state => state?.user?.timecard?.submittedSig?.data)
    const sigInfo = useSelector(state => state?.user?.timecard)
    const dateInfo = new Date(sigInfo.submittedDate).toLocaleString()

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setTrigger(false);
        // setVisTotal('');
        // setEntTotal('');
        // setMileTotal('');
        setSubmitNotes('');
        setOpen(false);
      };

      useEffect(async () => {
        if (trigger && hasSig) {
            const element = document.getElementById('print');

            console.log({
                offsetWidth: element.offsetWidth,     // Width including padding and borders
                offsetHeight: element.offsetHeight,   // Height including padding and borders
                scrollWidth: element.scrollWidth,     // Full scrollable width
                scrollHeight: element.scrollHeight,   // Full scrollable height
                clientWidth: element.clientWidth,     // Width of the content excluding borders
                clientHeight: element.clientHeight,   // Height of the content excluding borders
            });

            const originalStyle = element.style.cssText;
            element.style.width = `${element.scrollWidth}px`;
            element.style.height = `${element.scrollHeight}px`;

            try {
                setTimeout(() => setTrigger(true), 500);

                const canvas = await html2canvas(element, {
                    scale: 2,
                    useCORS: true,
                    // width: element.scrollWidth,
                    // height: element.scrollHeight,
                    logging: true,
                });

                const file = canvas.toDataURL('image/jpeg');

                console.log("file: ", file);
    
                const data = {
                    submitter: sigInfo.submittedBy,
                    file: file,
                    startDate: dateRange[0],
                    endDate: dateRange[1],
                    submitSig: sigInfo.submittedSig,
                    submitDate: sigInfo.submittedDate,
                    submitNotes: submitNotes,
                    visits: totals.visitTotals,
                    entries: totals.entryTotals,
                    miles: totals.mileTotals,
                };
    
                console.log("submit data: ", data);
                submitTimecard(data);
                dispatch(clearTimecardState());
                handleClose();

            } catch (error) {
                console.error('Error capturing canvas:', error);
            } finally {
                element.style.cssText = originalStyle;
            }

    
          
        }
    }, [trigger, sigInfo, hasSig]);
    
    
   
    return (
    <div>
        <Button startIcon={<FileOpenIcon />} color="secondary" onClick={handleClickOpen}>
            View Summary/ Submit
        </Button>
        <Dialog fullWidth maxWidth={'xl'} open={open} onClose={handleClose}>
            <DialogContent>
                <div id='print' ref={printRef}>
                    <TimeSummary hasSig={hasSig} sigDate={dateInfo} setNotes={submitNotes} data={data} user={usersName} dateRange={dateRange} totals={totals} />
                    {/* {hasSig && 
                        <div>
                            <img src={hasSig} /><br />
                            <h5>{usersName}</h5><br />
                            <h5>{new Date(sigInfo.submittedDate).toLocaleString()}</h5>
                            <br />
                        </div> } */}
                </div>
                <AddTimecardNotes handleSave={setSubmitNotes} />
                {!hasSig && <UserSignTimecard userId={userId} /> }
            </DialogContent>
            <DialogActions>
                {hasSig && <Button variant='contained' color='success' onClick={() => setTrigger(true)}>Submit</Button>}
                <ReactToPrint trigger={() => <Button variant='contained' color='primary'>Print</Button>} content={()=> printRef.current} />
                <Button variant='contained' color='warning' onClick={handleClose}>Close</Button>
            </DialogActions>
            
            
        </Dialog>
    </div>
  )
}

SummaryContainer.propTypes = {
    data: PropTypes.any,
    userId: PropTypes.any,
    dateRange: PropTypes.any,
    totals: PropTypes.any
}

export default SummaryContainer