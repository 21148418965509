import React, {useRef, useEffect} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../../redux/slices/authSlice';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, TextField, Paper, Box, Checkbox, OutlinedInput} from '@mui/material';
//import { ThemeProvider, createTheme } from '@mui/system';
import { useLoginMutation } from '../../redux/apiSlice';
import { toast } from 'react-toastify';
import usePersist from '../../utilities/usePersist';


const validationSchema = yup.object({
  
  username: yup
    .string('Enter Username')
    .required('Required'),
  password: yup
    .string('Enter Password')
    .required('Required'),
  
  
});

const Login = () => {
    const userRef = useRef()
    const [persist, setPersist] = usePersist()

    let navigate = useNavigate();
    const dispatch = useDispatch();
    

    const handleToggle = () => setPersist(prev => !prev)

    const [login, {data, isSuccess, isError, error}] = useLoginMutation();
   
    useEffect(() => {
      if (userRef.current) {
        userRef.current.focus();
      }
    }, [])

   
    useEffect(async () => {
      if (isSuccess && data.success === true) {
        toast.success("Login Successful", {
          position: toast.POSITION.TOP_RIGHT
        });
        // console.log("data", data)
        
        await dispatch(setCredentials(data.token));
        
        navigate("/dashboard")
        
      } else if (isSuccess && data.success === false) {
        // console.log("data", data)m
        toast.error(`${data.message}`, {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }, [isSuccess])

  
    useEffect(() => {
      if (isError) {
        toast.error(error.data?.message)
      }
    }, [isError])
       
      return (
        
        <>
        <Box
            sx={{
                mx: 'auto',
                width: 500,
                pt: 10,
                mt: 10,
                display: 'flex',
                flexWrap: 'wrap',
                textAlign: 'center',
                
            }}
        >
        <Paper elevation={24}>
      <div>
        <h1>Login</h1>
      
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        validationSchema={validationSchema}
      
        onSubmit={async (values) => {
          try {
          if (values) {
            //  const {accessToken} = 
             await login({values}).unwrap()
            
          } 
            
          } catch (err) {
            if (!err.status) {
              toast.error("No Server Response");
            } else if (err.status === 400) {
              toast.error("Missing Username or Password")
            } else if (err.status === 401) {
              toast.error("Unauthorized")
            }
          }
          } 
        }>
          
     
        {formik => (
          <form onSubmit={formik.handleSubmit}>
                        
            <TextField
              fullWidth
              id="username"
              name="username"
              label="Username"
              placeholder=""
              inputRef={userRef}
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helpertext={formik.touched.username && formik.errors.username}
              tabIndex={1}
            />
            <OutlinedInput
              type='password'
              variant='outlined'
              fullWidth
              id="password"
              name="password"
              label="Password"
              placeholder="Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helpertext={formik.touched.password && formik.errors.password}
              tabIndex={2}
            />
            <label>
              <Checkbox
                id='persist'
                onChange={handleToggle}
                checked={persist}
              />
              Trust This Device?
            </label>
            
            <Button color="primary" variant="contained" fullWidth type="submit" tabIndex={3}>
              Login
            </Button>
            <br></br>
            <Link to="/forgotpassword" className="login-screen__forgotpassword" tabIndex={4}>Forgot Password?</Link>
            
            
          </form>
        )}
          </Formik>
          
        </div>
        </Paper>
        </Box>
        </>
      );
    }

  
   
export default Login;
