import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditServicePlan from "./EditServicePlan";
import ReviewPlan from "./ReviewPlan";
import {
  useGetAllClientReportsQuery,
  useDeleteClientReportMutation,
} from "../../../redux/apiSlice";
import {
  CircularProgress,
  Box,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Collapse,
  Typography,
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UpdateStatus from "./statusUpdate/UpdateStatus";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPlan, setPlansForServicePlanTab } from "../../../redux/slices/clientSlice";
import { useAuth } from "../../../utilities/useAuth";
import DeletePlan from './DeletePlan';

const Row = ({ data, planNumber }) => {
  const { id, role } = useAuth();
  const isUser = id;

  const planId = data._id;
  const [open, setOpen] = useState();
  const [trigger, setTrigger] = useState(false);

  const dispatch = useDispatch();
  const lastPlanId = useSelector(state => state.client.lastPlanId)

  const [deleteServicePlan] = useDeleteClientReportMutation();

  const formatDate = (i) => {
    const date = new Date(i);
    let options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    if (trigger) {
      if (lastPlanId?.length) {
        dispatch(setCurrentPlan(lastPlanId));
      } else {
        dispatch(setCurrentPlan(""))
      }
    }
  }, [trigger])

// console.log("submittedSig: ", data.submittedSig)
  // const submitter = useGetUserName(data.submittedBy);
  // const approver = useGetUserName(data.approvedBy);
  // const sender = useGetUserName(data.sentBy);

  const submitter = data?.submittedBy?.fullName || ''
  const approver = data?.approvedBy?.fullName || ''
  const sender = data?.sentBy?.fullName || ''

  // console.log("submitter: ", submitter, "approve: ", approver, "send: ", sender)

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {planNumber}
        </TableCell>
        <TableCell align="left">{formatDate(data?.reportDate)}</TableCell>
        <TableCell align="left">{data?.type}</TableCell>
        <TableCell align="left">{formatDate(data?.startDate)}</TableCell>
        <TableCell align="left">{formatDate(data?.endDate)}</TableCell>
        <TableCell align="left">
          {data?.submitted ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
        </TableCell>
        <TableCell align="left">
          {data?.approved ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
        </TableCell>
        <TableCell align="left">
          {data?.sent ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
        </TableCell>
        <TableCell align="left" display="inline">
          <span style={{ justifyContent: 'center' }}>
            <UpdateStatus planId={planId} data={data} />
            <EditServicePlan planId={planId} singleReport={data} />
            <ReviewPlan planId={planId} />
            {role !== 'Staff' &&
              <DeletePlan name={`Client ${data?.type}-${data?.reportDate}`} planId={planId} isUser={isUser} hook={deleteServicePlan} softDelete={true} setTrigger={setTrigger} />
            }
          </span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Report Status
              </Typography>
              <Table size="small" aria-label="notes">
                <TableHead>
                  <TableRow>
                    <TableCell>Submitted</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Approved</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Sent</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {data.submitted ? (
                        <>
                          <Typography>By: {submitter}</Typography>
                          <Typography>On: {formatDate(data.submittedDate)}</Typography>
                        </>
                      ) : (
                        <Typography>Not Submitted</Typography>
                      )}
                    </TableCell>
                    <TableCell>{data.submittedSig &&
                      <img
                        src={data.submittedSig}
                        style={{ width: '200px', height: '200px' }}
                        // onError={(e) => {
                        //   console.error("Invalid base64 string", data.submittedSig);
                        // }} 
                        />}</TableCell>
                    <TableCell component="th" scope="row">
                      {data.approved ? (
                        <>
                          <Typography>By: {approver}</Typography>
                          <Typography>On: {formatDate(data.approvedDate)}</Typography>
                        </>
                      ) : (
                        <Typography>Not Approved</Typography>
                      )}
                    </TableCell>
                    <TableCell>{data.approvedSig && <img src={data.approvedSig} style={{ width: '200px', height: '200px' }} />}</TableCell>
                    <TableCell component="th" scope="row">
                      {data.sent ? (
                        <>
                          <Typography>By: {sender}</Typography>
                          <Typography>On: {formatDate(data.sentDate)}</Typography>

                        </>
                      ) : (
                        <Typography>Not Sent</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {data.sent &&
                        <>
                          <Typography>To: {data.sentTo}</Typography><br />
                          <Typography>At: {data.sentAddr}</Typography>
                        </>
                      }
                    </TableCell>
                    <TableCell>{data.sentSig && <img src={data.sentSig} style={{ width: '200px', height: '200px' }} />}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

Row.propTypes = {
  data: PropTypes.any,
  planNumber: PropTypes.any
};

const ClientReportsList = () => {
  const dispatch = useDispatch()
  const { clientId } = useParams();

  const { data, isLoading, isSuccess } = useGetAllClientReportsQuery(clientId);

  let content;

  if (isLoading) {
    content = <CircularProgress />;
  } else if (isSuccess) {

    let reportsList = data.map((plan, idx) => (
      {
        planId: plan._id,
        type: plan.type,
        reportDate: plan.reportDate,
        startDate: plan.startDate,
        endDate: plan.endDate,
        planNumber: (idx + 1)
      }))
    dispatch(setPlansForServicePlanTab(reportsList))

    content = (
      <div>
        <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="Client Reports List">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>#</TableCell>
                  <TableCell>ReportDate</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Submitted</TableCell>
                  <TableCell>Approved</TableCell>
                  <TableCell>Sent</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.map((i, idx) => <Row key={i._id} data={i} planNumber={idx + 1} />)}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
    );
  }

  return (
    <div>
      <div>{content}</div>
    </div>
  );
};

export default ClientReportsList;
