import React, { useState } from 'react';
import { useAddStaffNoteMutation } from '../../../redux/apiSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add'
import { useAuth } from '../../../utilities/useAuth';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';


const AddStaffNote = ({clientId}) => {

    const { id } = useAuth();
    const isUser = id;

    const [addNote] = useAddStaffNoteMutation()

    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
      };

      const { register, control, handleSubmit, formState: {errors}} = useForm({
        defaultValues: {
          date: new Date().toISOString(),
          note: '',
          user: isUser,
          secure: false,
          isDeleted: false
        }
    
      })

      const onSubmit = async (data) => {

        // console.log(data);
        
        try {
        // const payload =  
        await addNote({ data, isUser, clientId })
        // .unwrap();
        //   console.log('fulfilled', payload)
        } catch (err) {
          console.error('Failed to save staff note: ', err)
          toast.error("Error Adding Staff Note", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
    
        toast.success("Staff Note Added", {
          position: toast.POSITION.TOP_RIGHT
        });
        handleClose();
        
      };
      

    return (
        <div>
            <Button startIcon={<AddIcon />} onClick={handleClickOpen}>
                Add Staff Note
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Staff Note</DialogTitle>
                <DialogContent>
                    <form 
                        id="addStaffNote"
                        onSubmit= {(e) =>
                        handleSubmit(
                            onSubmit
                        )(e).catch((e) => {
                            console.log("e", e);
                        })
                        }
                    >                            
                        <Controller
                            control={control}
                            name="date"
                            
                    
                        render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                            
                            label="Date"
                            error={!!errors.date}
                            value={field.value}
                            onChange={(e)=>field.onChange(e)}
                            renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        )}
                        />
                        <br />
                        <TextField
                            {...register('note')}
                            multiline
                            rows={6}
                        />

                    </form>

                </DialogContent>
                <DialogActions>
                    <Button color="warning" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button form="addStaffNote" type="submit">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    )
}

AddStaffNote.propTypes = {
    clientId: PropTypes.any
}

export default AddStaffNote
