import React from 'react';
import { Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';


const VisitHours = ({ visits }) => {

    
    
    const visitsHours = visits.map(i => i.totalHours).reduce((a,b)=>a+b, 0);
   

    return (
        <>
            <Paper>                    
                    {visits &&
                             <Typography variant="subtitle2">Visit Hours: {visitsHours}</Typography>
                    }
            </Paper>
        </>
    )


}

VisitHours.propTypes = {
    visits: PropTypes.any,
}

export default VisitHours;