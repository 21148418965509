import React, { useRef } from "react";
// import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import {
  useDeleteVisitMutation,
} from "../../redux/apiSlice";
import { Box, Typography, Stack, Button } from "@mui/material";
import DirectEditVisit from "../visitviews/DirectEditVisit";
import { useAuth } from "../../utilities/useAuth";
import DeleteVisit from "../visitviews/DeleteVisit";
import { lighten, styled } from '@mui/material/styles';
import ErrorIcon from '@mui/icons-material/Error';
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import PropTypes from 'prop-types';

const getBackgroundColor = (color) =>
  // mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);
  lighten(color, 0.7);

const getHoverBackgroundColor = (color) =>
  // mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);
  lighten(color, 0.6);

const getSelectedBackgroundColor = (color) =>
  // mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);
  lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color) =>
  // mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);
  lighten(color, 0.4);


const StyledDataGrid = styled(DataGrid)(() => ({
  '& .rows-issues--None': {
    backgroundColor: getBackgroundColor('#EAE7E7'),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor('#EAE7E7')
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor('#EAE7E7'),
      '&:hover': {backgroundColor: getSelectedHoverBackgroundColor('#EAE7E7')}
    }
  },
  '& .rows-issues--Incomplete': {
    backgroundColor: getBackgroundColor('#F55C5A'),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor('#F55C5A')
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor('#F55C5A'),
      '&:hover': {backgroundColor: getSelectedHoverBackgroundColor('#F55C5A')}
    }
  },
  '& .rows-issues--Missing-Sig': {
    backgroundColor: getBackgroundColor('#F5BC36'),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor('#F5BC36')
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor('#F5BC36'),
      '&:hover': {backgroundColor: getSelectedHoverBackgroundColor('#F5BC36')}
    }
  },
}))


const ClientVisits = ({data}) => {
  const {id} = useAuth();
  const isUser = id; 

  // const { clientId } = useParams();

  // const { data, isLoading, isSuccess } = useGetVisitsByClientIdQuery(clientId);

  const hasMissing = data?.reduce((acc, cur) => cur.missingSig === 'Missing Sig' ? ++acc : acc, 0).toString();
  const hasIncomplete = data?.reduce((acc, cur) => cur.incomplete === 'Incomplete' ? ++acc : acc, 0).toString();

  const [deleteVisit] = useDeleteVisitMutation();

  const clientName = useSelector(state => state.client.fullName)
  const printRef = useRef();

  const handlePrint = useReactToPrint({
    documentTitle: `Visits for ${clientName}`,
    content: () => printRef.current
  })


  const columns = [
    {
      field: "user",
      headerName: "User",
      width: 150,
      renderCell: (params) => {
        return <p> {params.value.fullName} </p>;
      },
    },
    {
      field: "visitStart",
      headerName: "Start Time",
      type: "date",
      width: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        let options = {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        };
        return date.toLocaleTimeString("en-US", options);
      },
    },
    {
      field: "visitEnd",
      headerName: "End Time",
      type: "date",
      width: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        let options = {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        };
        return date.toLocaleTimeString("en-US", options);
      },
    },
    { field: "location", headerName: "Location", width: 150 },
    { field: "totalHours", headerName: "Hours", width: 150 },
    {
      field: "actions",
      headerName: "",
      width: 80,
      renderCell: (params) => {
        // console.log("params: ", params.row)
        let visitId = params.row._id;
        let date =new Date(params.row.visitStart).toLocaleTimeString()
        return (
          <>
            <DirectEditVisit visitId={visitId} visit={params.row} clientName={params.row.client.fullName} />
            <DeleteVisit name={`Visit- ${date}`} visitId={visitId} hook={deleteVisit} softDelete={true} isUser={isUser} />
          </>
        );
      },
    },
    {
      field: 'incomplete',
      headerName: 'Incomplete',
      width: 80,
      renderCell: (params) => {
        if (params.value === 'Incomplete') {
          return <CheckBoxIcon />
        } else {
          return <CheckBoxOutlineBlankIcon />
        }
      }
    },
    {
      field: 'missingSig',
      headerName: 'Missing Sign',
      width: 80,
      renderCell: (params) => {
        if (params.value === 'Missing Sig'){
          return <CheckBoxIcon />
        } else {
          return <CheckBoxOutlineBlankIcon />
        }
      }
    }
  ];

 
    

  return (
    <div>
      <h1>Visits</h1>
      <Button variant='contained' color='primary' sx={{float: 'right'}} onClick={handlePrint}>Print</Button>
        <div id='print' ref={printRef}>
          <Stack direction='row'>
              <ErrorIcon sx={{ color: '#F55C5A' }} /><Typography>{hasIncomplete} Incomplete Visits</Typography>
              <ErrorIcon sx={{ color: '#F5BC36' }} /><Typography>{hasMissing} Missing Signatures</Typography>
          </Stack> <br />
        
      
          <div style={{ display: "flex" }}>
        
        <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
          
          {data && (
            <StyledDataGrid
              autoHeight
              autoWidth
              getRowId={(row) => row._id}
              rows={data}
              columns={columns}
              rowsPerPageOptions={[20, 50, 100]}
              getRowClassName={(params) => {
                if (!params.row.incomplete && !params.row.missingSig) {
                  return 'rows-issues--None'
                } else if (params.row.incomplete === 'Incomplete') {
                  return `rows-issues--${params.row.incomplete}`
                } else if (params.row.missingSig === 'Missing Sig') {
                  return `rows-issues--Missing-Sig`
                }
                
                
                
            }}
              //autoPageSize
              sx={{ height: "100%", width: "100%" }}
            />
          )}
        </Box>
      </div>
      </div>
    </div>
  );
};

ClientVisits.propTypes = {
  data: PropTypes.any,
}

export default ClientVisits;
