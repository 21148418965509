import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircle';
import { useForm, Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const AddBldPrsrEntry = ({ addEntry }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        reset();
    };

    const { control, reset, register, handleSubmit } = useForm();

    const onSubmit = (data) => {
        console.log('data: ', data);
        addEntry(data);
        handleClose();
    };

    return (
        <div>
            <Button startIcon={<AddIcon />} onClick={handleClickOpen}>
                Add Entry
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add New Entry</DialogTitle>
                <DialogContent>
                    <form id="addBldPrsrEntry" onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {/* Date Field */}
                            <Controller
                                name="date"
                                defaultValue={new Date().toISOString()}
                                control={control}
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DateTimePicker
                                            {...field}
                                            label="Date"
                                            value={field.value}
                                            onChange={(e) => field.onChange(e)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                )}
                            />

                            {/* Systolic / Diastolic Fields */}
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                {/* Systolic Field */}
                                <Box sx={{ flex: 1 }}>
                                    {/* <Typography variant="h6" align="center">
                                        Systolic
                                    </Typography> */}
                                    <Controller
                                        name="systolic"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                variant="outlined"
                                                label='Systolic'
                                                margin="normal"
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Box>

                                {/* Separator */}
                                <Typography
                                    variant="h4"
                                    align="center"
                                    sx={{
                                        lineHeight: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                    }}
                                >
                                    /
                                </Typography>

                                {/* Diastolic Field */}
                                <Box sx={{ flex: 1 }}>
                                    {/* <Typography variant="h6" align="center">
                                        Diastolic
                                    </Typography> */}
                                    <Controller
                                        name="diastolic"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label='Diastolic'
                                                variant="outlined"
                                                margin="normal"
                                                type="number"
                                                inputProps={{ min: 0 }}
                                              
                                            />
                                        )}
                                    />
                                </Box>
                            </Box>


                            {/* Pulse Field */}
                            <Controller
                                name="pulse"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Pulse"
                                        variant="outlined"
                                        margin="normal"
                                        type="number"
                                        inputProps={{ min: 0 }}
                                    />
                                )}
                            />

                            {/* Note Field */}
                            <TextField
                                name="note"
                                {...register('note')}
                                label="Note"
                                variant="outlined"
                                multiline
                                rows={2}
                                fullWidth
                            />
                        </Box>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="warning">
                        Cancel
                    </Button>
                    <Button type="submit" form="addBldPrsrEntry">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

AddBldPrsrEntry.propTypes = {
    addEntry: PropTypes.func.isRequired,
};

export default AddBldPrsrEntry;
