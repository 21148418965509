import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Tabs, Tab, Typography, Box } from '@mui/material';
import Schedule from './Schedule';
import Snapshot from './Snapshot';
import { useAuth } from '../../utilities/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { changeSchedule } from '../../redux/slices/userSlice';
import ChangeUserSchedule from './ChangeUserSchedule';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'} variant={'body2'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ScheduleTabs() {
  const { id, admin, supAdmin, supervisor } = useAuth()
  // console.log("admin, supAdmin: ", admin, supAdmin)

    let viewSelect = false

    if (admin || supAdmin || supervisor) {
        viewSelect = true
    }
    // console.log("viewSel: ", viewSelect)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(changeSchedule(id))
    }, [])
    
    const userId = useSelector(state => state.user.schedule.user)

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{display: 'inline-flex', alignItems: 'space-between', borderBottom: 1, borderColor: 'divider' }}>
        <Box>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Schedule" {...a11yProps(0)} />
          <Tab label="Snapshot" {...a11yProps(1)} />
          
        </Tabs>
        </Box>
        <Box sx={{ minWidth: 200}}></Box>
        <Box sx={{ marginLeft: 'auto', minWidth: 300 }}>
        {viewSelect && <ChangeUserSchedule currentUserId={userId} />}
        </Box>
      </Box>
      <TabPanel value={value} index={0}>
        <Schedule userId={userId}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Snapshot userId={userId} />
      </TabPanel>
      
    </Box>
  );
}
