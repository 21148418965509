import React from 'react';
import { useDeleteStaffNoteMutation, useGetAllStaffNotesQuery } from '../../../redux/apiSlice';
import { useParams } from 'react-router-dom';
import {Box, Grid, Typography} from '@mui/material';
import AddStaffNote from './AddStaffNote';
import { useAuth } from '../../../utilities/useAuth';
import StaffNoteCard from './StaffNoteCard';


const StaffNotes = () => {
    const { clientId } = useParams();
    const { data } = useGetAllStaffNotesQuery(clientId);
    const {id: isUser} = useAuth();

    const [deleteNote] = useDeleteStaffNoteMutation()

    const handleDelete = (staffNoteId) => {
        console.log("isUser: ", isUser, "staffNoteId: ", staffNoteId)
        deleteNote({isUser, staffNoteId})
    };

    return (
        <Box p={2}>
            <Grid container spacing={2} sx={{ maxWidth: '100%', overflow: 'auto', minHeight: '100px' }}>
                <Grid item xs={12} sx={{ minHeight: '50px'}}>
                    <AddStaffNote clientId={clientId} />
                </Grid>
                {data && Array.isArray(data) ? (
                    data.map((note) => (
                        <StaffNoteCard key={note._id} note={note} handleDelete={handleDelete} />
                    ))
                ) : (
                    <Typography>No Staff Notes</Typography>
                )}
            </Grid>
        </Box>
    );
};

export default StaffNotes;
