import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Tooltip
} from '@mui/material';
import { format, startOfWeek, addDays, subWeeks, addWeeks, isSameDay } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useAddMedLogMutation, useDeleteMedLogMutation } from '../../../redux/apiSlice';
import { useAuth } from '../../../utilities/useAuth';
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import EditMedLog from './EditMedLog';

const MedTable = ({ medications, medLogs, clientId }) => {
  const { id: isUser, admin, supAdmin, supervisor } = useAuth();

  const [isAdmin, setIsAdmin] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [expandedDay, setExpandedDay] = useState(null);


  useEffect(() => {
    if (admin || supAdmin || supervisor) {
      setIsAdmin(true)
    }
  }, [admin, supAdmin, supervisor])

  const [addMedLog] = useAddMedLogMutation();
  const [deleteLog] = useDeleteMedLogMutation();

  const logType = 'meds';

  const startDate = startOfWeek(currentDate, { weekStartsOn: 0 });
  const daysOfWeek = Array.from({ length: 7 }, (_, i) => addDays(startDate, i));

  const handleAddLog = async (medName, day) => {
    const dateTime = new Date(day);
    const now = new Date();
    dateTime.setHours(now.getHours(), now.getMinutes(), now.getSeconds());
    console.log("on add dateTime: ", dateTime)

    const newLog = {
      med: medName,
      dateTime: dateTime,
      user: isUser,
      isDeleted: false
    };

    try {
      await addMedLog({ logType, clientId, isUser, data: newLog })

      toast.success(`${medName} Log Added`, {
        position: toast.POSITION.TOP_RIGHT
      });
    } catch (error) {
      console.log("med log add error: ", error)
      toast.error(`Error adding log for ${medName}`, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  const handleDeleteLog = async (logId, medName) => {
    console.log("logId: ", logId)
    try {
      await deleteLog({ clientId, logType, isUser, logId });
      toast.success(`Deleted ${medName} Log`, {
        position: toast.POSITION.TOP_RIGHT
      });
    } catch (error) {
      console.log("med log delete error: ", error)
      toast.error(`Error deleting log for ${medName}`, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }


  const goToPreviousWeek = () => setCurrentDate(subWeeks(currentDate, 1));
  const goToNextWeek = () => setCurrentDate(addWeeks(currentDate, 1));
  const handleDateChange = (newDate) => setCurrentDate(newDate);


  const toggleDayExpansion = (day) => {
    const dayKey = day.toISOString();
    console.log("Toggling expansion for:", dayKey, "Current expandedDay:", expandedDay);
    setExpandedDay((prev) => (prev === dayKey ? null : dayKey));
  };


  // const getLogsForDay = (day) => {
  //   return medLogs.filter((log) => isSameDay(new Date(log.dateTime), new Date(day)));
  // };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
        <IconButton onClick={goToPreviousWeek}>⬅️</IconButton>
        <span style={{ margin: '0 16px' }}>
          {`Week of ${format(startDate, 'MMM d, yyyy')}`}
        </span>
        <IconButton onClick={goToNextWeek}>➡️</IconButton>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={currentDate}
            onChange={handleDateChange}
            renderInput={(params) => (
              <TextField {...params} style={{ marginLeft: '16px', width: '200px' }} />
            )}
          />
        </LocalizationProvider>
      </div>

      <TableContainer sx={{ maxHeight: '70vh' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Medication</TableCell>
              <TableCell>Dose</TableCell>
              <TableCell>Times per Day</TableCell>
              {daysOfWeek.map((day) => (
                <React.Fragment key={day}>
                  <TableCell align="center">
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <span>{format(day, 'EEE, MMM d')}</span>
                      <IconButton size="small" onClick={() => toggleDayExpansion(day)}>
                        {expandedDay === day.toISOString() ? '🔽' : '🔼'}
                      </IconButton>
                    </div>
                  </TableCell>
                  {expandedDay === day.toISOString() && (
                    <TableCell align="center" style={{ backgroundColor: '#f9f9f9' }}>
                      Details
                    </TableCell>
                  )}
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {medications.map((med) => (
              <TableRow key={med._id}>
                <TableCell>{med.name}</TableCell>
                <TableCell>{med.dosage}</TableCell>
                <TableCell>{med.frequency}</TableCell>
                {daysOfWeek.map((day) => {
                  const logsForDay = medLogs.filter(
                    (log) => log.med === med.name && isSameDay(new Date(log.dateTime), day)
                  );

                  return (
                    <React.Fragment key={day}>
                      <TableCell align="center">
                        {logsForDay.length > 0 && (
                          <Tooltip
                            title={
                              <div>
                                {logsForDay.map((log, index) => (
                                  <div key={index}>
                                    <strong>Time:</strong> {new Date(log.dateTime).toLocaleTimeString()} <br />
                                    <strong>User:</strong> {log.user?.fullName || 'Unknown'}
                                  </div>
                                ))}
                              </div>
                            }
                            arrow
                          >
                            <div style={{ display: 'flex', justifyContent: 'center', gap: '4px' }}>
                              {logsForDay.map((log, index) => (
                                <span key={index}>✔️</span>
                              ))}
                            </div>
                          </Tooltip>
                        )}
                        <IconButton
                          color="primary"
                          onClick={() => handleAddLog(med.name, day)}
                          style={{ marginLeft: logsForDay.length > 0 ? 8 : 0 }}
                        >
                          <AddIcon />
                        </IconButton>
                      </TableCell>
                      {expandedDay === day.toISOString() && (
                        <TableCell align="center">
                          <ul>
                            {logsForDay.map((log, idx) => (
                              <li key={idx}>
                                {`Time: ${new Date(log.dateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}, User: ${log.user?.fullName}`}
                                <span style={{ display: 'flex', gap: '8px', justifyContent: 'center', alignItems: 'center' }}>
                                  {isAdmin &&
                                    <EditMedLog clientId={clientId} isUser={isUser} log={log} />
                                  }
                                  {(isAdmin || log.user._id === isUser) && (
                                    <IconButton
                                      color="error"
                                      onClick={() => handleDeleteLog(log._id, log.med)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </TableCell>
                      )}
                    </React.Fragment>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>

        </Table>
      </TableContainer>
    </div>
  );
};

MedTable.propTypes = {
  medications: PropTypes.any,
  // initialMedLogs: PropTypes.any 
  medLogs: PropTypes.any,
  clientId: PropTypes.any
}

export default MedTable;
